import * as Types from '../mutationTypes';

import HestiaApi from '@/api';

// initial state
const state = {
    commissions : {}
};

// getters
const getters = {
    commissions : state => state.commissions
};

// actions
const actions = {
    async getCommissions({ commit }, { tenantId }) {

        const response = await HestiaApi.Mandate.commission.get(tenantId).json();
        commit(Types.RECEIVE_COMMISSIONS, response);
    },
    async editCommission({ commit }, { tenantId, commissionId, commission }) {

        const response = await HestiaApi.Mandate.commission.edit(tenantId, commissionId, commission).json();
        commit(Types.EDIT_COMMISSION, response);
    }
};

// mutations
const mutations = {
    [Types.RECEIVE_COMMISSIONS](state, commissions) {

        state.commissions = commissions;
    },

    [Types.EDIT_COMMISSION](state, commission) {

        const index = state.commissions.results.findIndex((com) => com.id === commission.id);
        state.commissions.results.splice(index, 1, commission);

    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
