import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

// initial state
const state = {
    lots : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    }
};

// getters
const getters = {
    lots           : state => state.lots
};

// actions
const actions = {

    // PROGRAM
    async getLots({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Commercialisation.lot.list(tenantId, {searchParams : query}).json();
        commit(Types.RECEIVE_LOTS, response);
    }
};

// mutations
const mutations = {
    [Types.RECEIVE_LOTS](state, lots) {

        state.lots = lots;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
