export default {
    methods : {
        priceChange({resource, price, fees}) {

            resource.price = {
                value : price
            };

            resource.fees = {
                value : fees
            };
            return {resource};
        },
        initializeMinMax(resource) {

            if (!resource.price) {

                resource.price = {
                    min : 0,
                    max : 0
                };
            }

            if (!resource.fees) {

                resource.fees = {
                    min : 0,
                    max : 0
                };
            }

            return resource;
        },
        priceChangeMin({resource, price, fees, minPrice}) {

            resource = this.initializeMinMax(resource);

            resource.price.min = price;
            resource.fees.min  = fees;

            if (minPrice) {
                minPrice           = price;
                return {resource, minPrice};
            }

            return {resource};
        },
        priceChangeMax({resource, price, fees}) {

            resource = this.initializeMinMax(resource);

            resource.price.max = price;
            resource.fees.max  = fees;

            return {resource};
        },
        formatDecimalNumber(number) {

            if (Number.isInteger(number)) {

                return number;
            }

            return number.toFixed(2);
        }
    }
}
