import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';


const state = {
    currentCart        : {},
    totalCreditsInCart : 0
}

const getters = {
    currentCart        : state => state.currentCart,
    totalCreditsInCart : state => state.totalCreditsInCart
}

const actions = {
    async getCurrentCart({commit}, {tenantId}) {

        try {

            const response = await HestiaApi.Marketplace.shoppingCart.get(tenantId).json()
            commit(Types.SET_CART, response)
        } catch (error) {

            commit(Types.SET_CART, {})
        }

    },

    async addProductToCart({commit}, {tenantId, product}) {

        const response = await HestiaApi.Marketplace.shoppingCart.addProduct(tenantId, product).json();
        commit(Types.SET_CART,response)
    },

    async updateCart({commit}, {tenantId, cart}) {

        const response = await HestiaApi.Marketplace.shoppingCart.update(tenantId, cart).json();
        commit(Types.SET_CART,response)
    }
}

const mutations = {
    [Types.SET_CART](state,cart) {

        state.currentCart = cart

        let total = 0;

        if (cart && cart.products) {

            cart.products.forEach((product)=>{

                const price = product.details.product.price
                if (price) {
                    total += price
                }

            })
        }

        state.totalCreditsInCart = total
    }
}

export default {state , getters, actions, mutations}
