<template>
  <div
    v-if="currentVersion !== lastVersion"
    class="version-alert-wrapper info"
  >
    <div class="app-wrapper">
      <span>{{ $t('versionAlert.alert', {version : lastVersion}) }}</span>
      <a
        id="link"
        @click="reload"
      >{{ $t('versionAlert.click') }}</a>
    </div>
  </div>
</template>

<script>
import Ky     from 'ky';
import Config from '@/config';

export default {
    name : 'VersionAlert',
    data() {

        return {
            lastVersion    : '',
            currentVersion : '',
            timerId        : undefined
        }
    },
    async created() {

        await this.getLastVersion();
        this.currentVersion = Config.version

        this.timerId = setInterval(this.getLastVersion, 300000)
    },
    beforeDestroy() {

        if (this.timerId) {
            clearInterval(this.timerId)
        }
    },
    methods : {
        async getLastVersion() {

            const data = await Ky.get('/version.json').json();
            this.lastVersion = data.version;
        },
        async reload() {

            window.location.reload(true)
        }
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.info {
    background-color: $hestia-blue
}

.version-alert-wrapper {
    color: white;
    text-align: center;
    font-size: 15px;
    padding: 10px
}


#link {
    font-weight: bold;
    color: $hestia-darkest-blue;
}

#link:hover {
    text-decoration: underline;
}
</style>
