import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

const state = {
    walletConfiguration : {},
    wallet              : {},
    wallet_transactions : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    paymentIntent       : {},
    packs               : []
};

const getters = {
    walletConfiguration : state => state.walletConfiguration,
    wallet              : state => state.wallet,
    wallet_transactions : state => state.wallet_transactions,
    paymentIntent       : state => state.paymentIntent,
    packs               : state => state.packs
};

const actions = {
    async getWalletTVA({ commit }, { tenantId }) {

        const response = await HestiaApi.Wallet.configuration.getTVA(tenantId).json();
        commit(Types.SET_CONFIGURATION, response);
    },
    async getConfiguration({ commit }, { tenantId }) {

        const response = await HestiaApi.Wallet.configuration.get(tenantId).json();
        commit(Types.SET_CONFIGURATION, response);
    },
    async updateConfiguration({ commit }, { tenantId, configuration }) {

        const response = await HestiaApi.Wallet.configuration.update(tenantId, configuration).json();
        commit(Types.SET_CONFIGURATION, response);
    },
    async getWallet({ commit }, { tenantId, userId }) {

        const response = await HestiaApi.Wallet.wallet.balance(tenantId, userId).json();
        commit(Types.SET_WALLET, response);
    },
    async getWalletTransactions({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Wallet.wallet.list(tenantId, this.state.auth.user.id,
            { searchParams : query }
        ).json();
        commit(Types.SET_TRANSACTIONS, response);
    },
    async pay({ commit }, { tenantId, userId, purchases }) {

        const response = await HestiaApi.Wallet.payment.pay(tenantId, { userId, purchases }).json();
        commit(Types.SET_PAYMENT_INTENT, response);
    },
    async updatePaymentIntent({ commit }, { paymentIntent }) {

        commit(Types.SET_PAYMENT_INTENT, paymentIntent);
    },
    async getSpecificPaymentIntent({}, { tenantId, paymentIntentId }) {

        return await HestiaApi.Wallet.payment.get(tenantId, paymentIntentId).json();
    },
    async getPacks({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Wallet.pack.list(tenantId, { searchParams : query }).json();
        commit(Types.SET_PACKS, response.results);
    },
    async addPack({ commit }, { tenantId, pack }) {

        const response = await HestiaApi.Wallet.pack.create(tenantId, pack).json();
        commit(Types.CREATE_PACK, response);
    },
    async updatePack({ commit }, { tenantId, packId, pack }) {

        const response = await HestiaApi.Wallet.pack.update(tenantId, packId, pack).json();
        commit(Types.EDIT_PACK, response);
    },
    async getSpecificPack({}, { tenantId, packId }) {

        return await HestiaApi.Wallet.pack.get(tenantId, packId).json();
    },
    async deletePack({ commit }, { tenantId, packId }) {

        await HestiaApi.Wallet.pack.delete(tenantId, packId).json();
        commit(Types.DELETE_PACK, packId);
    },
    async reorderPacks({commit}, {tenantId, packsReordered}) {

        const response = await HestiaApi.Wallet.pack.reorder(tenantId, packsReordered).json();
        commit(Types.SET_PACKS, response);
    },
    async getInvoice({}, { tenantId, paymentIntentId }) {

        const paymentIntent = await HestiaApi.Wallet.payment.get(tenantId, paymentIntentId).json();

        return await HestiaApi.Document.document.url(tenantId, paymentIntent.documentId).json();
    }
};

const mutations = {
    [Types.SET_CONFIGURATION](state, configuration) {

        state.walletConfiguration = configuration;
    },
    [Types.SET_WALLET](state, wallet) {

        state.wallet = wallet;
    },
    [Types.SET_TRANSACTIONS](state, transactions) {

        state.wallet_transactions = transactions;
    },
    [Types.SET_PAYMENT_INTENT](state, paymentIntent) {

        state.paymentIntent = paymentIntent;
    },
    [Types.SET_PACKS](state, packs) {

        state.packs = packs;
    },
    [Types.CREATE_PACK](state, pack) {

        state.packs.push(pack);
    },
    [Types.EDIT_PACK](state, pack) {

        const index = state.packs.findIndex((p) => p.id === pack.id);
        state.packs.splice(index, 1, pack);
    },
    [Types.DELETE_PACK](state, packId) {

        const index = state.packs.findIndex((p) => p.id === packId);
        state.packs.splice(index, 1);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
