import { ruleHaiMin, ruleMandateCommission } from '@/utils/formRules';

import Model       from '../utils/model';
import ObjectUtils from '@/utils/object';
import i18n        from '@/lang'; // Internationalization

export default class Avenant extends Model {

    static get model() {

        return {
            'price' : {
                'min'   : 0,
                'max'   : 0,
                'value' : 0
            },
            'fees' : {
                'min'   : 0,
                'max'   : 0,
                'value' : 0
            },
            'showPercentage' : false
        };
    }

    static get actionByStatus() {

        return {
            'draft'          : { 'add' : true, 'edit' : true, 'view' : true, 'delete' : true },
            'ready'          : { 'add' : true, 'edit' : true, 'view' : true, 'delete' : true },
            'eavenant_ready' : { 'add' : true, 'edit' : true, 'view' : false, 'delete' : false },
            'sent'           : { 'add' : true, 'edit' : true, 'view' : true, 'delete' : true },
            'signed'         : { 'add' : true, 'edit' : true, 'view' : true, 'delete' : true },
            'completed'      : { 'add' : true, 'edit' : false, 'view' : true, 'delete' : false },
            'renewed'        : { 'add' : true, 'edit' : false, 'view' : true, 'delete' : false },
            'void'           : { 'add' : true, 'edit' : false, 'view' : true, 'delete' : false }
        };
    }

    static get definition() {

        return {
            'price.value' : {
                label : 'mandate.price.value',
                hide  : [
                    {
                        message  : 'price value is hidden because type for is not property',
                        validate : (resource, user, context) => {

                            return context.raw.type.for !== 'property';
                        }
                    }
                ],
                disable : [
                    {
                        message  : 'description is disabled because status',
                        validate : (resource) => {

                            return resource.status !== undefined && resource.status !== 'draft' && resource.status !== 'ready';
                        }
                    }, {
                        message  : 'price value is disabled because feesPayableBy is not buyer',
                        validate : (resource, user, context) => {

                            if (context && context.raw && context.raw.type) {

                                return context.raw.type.feesPayableBy === 'seller';
                            }

                            return false;
                        }
                    }
                ],
                format : {
                    out(resource, user, raw, context) {

                        if (context && context.raw.type && context.raw.type.for === 'client') {

                            resource.price = ObjectUtils.omit(resource.price, 'value');
                        }

                        return resource;
                    }
                },
                event : {
                    change : (priceValue, resource, oldValue, user, context) => {

                        if (resource && resource.price && resource.fees
                            && context.raw.type.feesPayableBy === 'buyer') {

                            resource.hai = {
                                value : resource.price.value + resource.fees.value
                            };

                        }

                        return resource;
                    }
                },
                rules : [
                    { required : true, message : i18n.t('rules.required', { id : i18n.t('mandate.price.value') }), trigger : 'blur' },
                    {
                        type    : 'number',
                        min     : 1,
                        message : i18n.t('rules.number.min', { id : i18n.t('mandate.price.value'), min : 0 }),
                        trigger : 'blur'
                    }
                ]
            },
            'price.min' : {
                label : 'mandate.price.min',
                hide  : [
                    {
                        message  : 'price min is hidden because type for is not client',
                        validate : (resource, user, context) => {

                            return context.raw.type.for !== 'client';
                        }
                    }
                ],
                disable : [
                    {
                        message  : 'description is disabled because status',
                        validate : (resource) => {

                            return resource.status !== undefined && resource.status !== 'draft' && resource.status !== 'ready';
                        }
                    }, {
                        message  : 'price min is disabled because feesPayableBy is seller',
                        validate : (resource, user, context) => {

                            if (context && context.raw && context.raw.type) {

                                return context.raw.type.feesPayableBy === 'seller';
                            }

                            return false;
                        }
                    }
                ],
                event : {
                    change : (priceValue, resource, oldValue, user, context) => {

                        if (resource && resource.price && resource.fees
                            && context.raw.type.feesPayableBy === 'buyer') {

                            resource.hai = {
                                ...resource.hai,
                                min : resource.price.min + resource.fees.min
                            };
                        }

                        return resource;
                    }
                },
                format : {
                    out(resource, user, raw, context) {

                        if (context && context.raw.type && context.raw.type.for === 'property') {

                            resource.price = ObjectUtils.omit(resource.price, 'min');
                        }

                        return resource;
                    }
                },
                rules : [
                    { required : true, message : i18n.t('rules.required', { id : i18n.t('mandate.price.min') }), trigger : 'blur' },
                    {
                        type    : 'number',
                        min     : 1,
                        message : i18n.t('rules.number.min', { id : i18n.t('mandate.price.min'), min : 0 }),
                        trigger : 'blur'
                    },
                    {
                        id : 'check_price_min_max',
                        validator(rule, value, callback, source, { form : { value : resource } }) {

                            if (resource.price.min > resource.price.max) {

                                return callback(new Error(i18n.t('rules.max_min', {
                                    minField : i18n.t('mandate.price.min'),
                                    maxField : i18n.t('mandate.price.max')
                                })));
                            }

                            return callback();
                        },
                        trigger : 'blur'
                    }
                ]
            },
            'price.max' : {
                label : 'mandate.price.max',
                hide  : [
                    {
                        message  : 'price max is hidden because type for is not client',
                        validate : (resource, user, context) => {

                            return context.raw.type.for !== 'client';
                        }
                    }
                ],
                disable : [
                    {
                        message  : 'description is disabled because status',
                        validate : (resource) => {

                            return resource.status !== undefined && resource.status !== 'draft' && resource.status !== 'ready';
                        }
                    }, {
                        message  : 'price max is disabled because feesPayableBy is not buyer',
                        validate : (resource, user, context) => {

                            if (context && context.raw && context.raw.type) {

                                return context.raw.type.feesPayableBy === 'seller';
                            }

                            return false;
                        }
                    }
                ],
                event : {
                    change : (priceValue, resource, oldValue, user, context) => {

                        if (resource && resource.price && resource.fees
                            && context.raw.type.feesPayableBy === 'buyer') {

                            resource.hai = {
                                ...resource.hai,
                                max : resource.price.max + resource.fees.max
                            };
                        }

                        return resource;
                    }
                },
                format : {
                    out(resource, user, raw, context) {

                        if (context && context.raw.type && context.raw.type.for === 'property') {

                            resource.price = ObjectUtils.omit(resource.price, 'max');
                        }

                        return resource;
                    }
                },
                rules : [
                    { required : true, message : i18n.t('rules.required', { id : i18n.t('mandate.price.max') }), trigger : 'blur' },
                    {
                        type    : 'number',
                        min     : 1,
                        message : i18n.t('rules.number.min', { id : i18n.t('mandate.price.max'), min : 0 }),
                        trigger : 'blur'
                    }
                ]
            },
            'fees.value' : {
                label   : 'mandate.fees.value',
                disable : [
                    {
                        message  : 'description is disabled because status',
                        validate : (resource) => {

                            return resource.status !== undefined && resource.status !== 'draft' && resource.status !== 'ready';
                        }
                    }
                ],
                hide : [
                    {
                        message  : 'fees value is hidden because type for is not property',
                        validate : (resource, user, context) => {

                            return context.raw.type.for !== 'property';
                        }
                    }
                ],

                event : {
                    change : (feeValue, resource, oldValue, user, context) => {

                        if (resource && resource.fees) {

                            if (resource.price && context.raw.type.feesPayableBy === 'buyer') {

                                resource.hai = {
                                    value : resource.price.value + resource.fees.value
                                };
                            }

                            if (resource.hai && context.raw.type.feesPayableBy === 'seller') {

                                resource.price = {
                                    value : resource.hai.value - resource.fees.value
                                };
                            }
                        }

                        return resource;
                    }
                },
                rules : [
                    { required : true, message : i18n.t('rules.required', { id : i18n.t('mandate.fees.value') }), trigger : 'blur' },
                    {
                        type    : 'number',
                        min     : 1,
                        message : i18n.t('rules.number.min', { id : i18n.t('mandate.fees.value'), min : 0 }),
                        trigger : 'blur'
                    },
                    {
                        id        : 'check_fees',
                        validator : (rule, value, callback, source, option) => ruleMandateCommission(rule, value, callback, source, option, 'value'),
                        trigger   : 'blur'
                    }
                ]
            },
            'fees.min' : {
                label   : 'mandate.fees.min',
                disable : [
                    {
                        message  : 'description is disabled because status',
                        validate : (resource) => {

                            return resource.status !== undefined && resource.status !== 'draft' && resource.status !== 'ready';
                        }
                    }
                ],
                hide : [
                    {
                        message  : 'fees min is hidden because type for is not client',
                        validate : (resource, user, context) => {

                            return context.raw.type.for !== 'client';
                        }
                    }
                ],
                event : {
                    change : (feeValue, resource, oldValue, user, context) => {

                        if (resource && resource.fees) {

                            if (resource.price && context.raw.type.feesPayableBy === 'buyer') {

                                resource.hai = {
                                    ...resource.hai,
                                    min : resource.price.min + resource.fees.min
                                };
                            }

                            if (resource.hai && context.raw.type.feesPayableBy === 'seller') {

                                resource.price = {
                                    ...resource.price,
                                    min : resource.hai.min - resource.fees.min
                                };
                            }
                        }

                        return resource;
                    }
                },
                rules : [
                    { required : true, message : i18n.t('rules.required', { id : i18n.t('mandate.fees.min') }), trigger : 'blur' },
                    {
                        type    : 'number',
                        min     : 1,
                        message : i18n.t('rules.number.min', { id : i18n.t('mandate.fees.min'), min : 0 }),
                        trigger : 'blur'
                    },
                    {
                        id        : 'check_fees',
                        validator : (rule, value, callback, source, option) => ruleMandateCommission(rule, value, callback, source, option, 'min'),
                        trigger   : 'blur'
                    },
                    {
                        id : 'check_fees_min_max',
                        validator(rule, value, callback, source, { form : { value : resource } }) {

                            if (resource.fees.min > resource.fees.max) {

                                return callback(new Error(i18n.t('rules.max_min', {
                                    minField : i18n.t('mandate.fees.min'),
                                    maxField : i18n.t('mandate.fees.max')
                                })));
                            }

                            return callback();
                        },
                        trigger : 'blur'
                    }
                ]
            },
            'fees.max' : {
                label   : 'mandate.fees.max',
                disable : [
                    {
                        message  : 'description is disabled because status',
                        validate : (resource) => {

                            return resource.status !== undefined && resource.status !== 'draft' && resource.status !== 'ready';
                        }
                    }
                ],
                hide : [
                    {
                        message  : 'fees max is hidden because type for is not client',
                        validate : (resource, user, context) => {

                            return context.raw.type.for !== 'client';
                        }
                    }
                ],

                event : {
                    change : (feeValue, resource, oldValue, user, context) => {

                        if (resource && resource.fees) {

                            if (resource.price && context.raw.type.feesPayableBy === 'buyer') {

                                resource.hai = {
                                    ...resource.hai,
                                    max : resource.price.max + resource.fees.max
                                };
                            }

                            if (resource.hai && context.raw.type.feesPayableBy === 'seller') {

                                resource.price = {
                                    ...resource.price,
                                    max : resource.hai.max - resource.fees.max
                                };
                            }
                        }

                        return resource;
                    }
                },
                rules : [
                    { required : true, message : i18n.t('rules.required', { id : i18n.t('mandate.price.max') }), trigger : 'blur' },
                    {
                        type    : 'number',
                        min     : 1,
                        message : i18n.t('rules.number.min', { id : i18n.t('mandate.price.max'), min : 0 }),
                        trigger : 'blur'
                    },
                    {
                        id        : 'check_fees',
                        validator : (rule, value, callback, source, option) => ruleMandateCommission(rule, value, callback, source, option, 'max'),
                        trigger   : 'blur'
                    }
                ]
            },

            'hai.value' : {
                label : 'mandate.hai.value',
                hide  : [
                    {
                        message  : 'hai value is hidden because type for is not property',
                        validate : (resource, user, context) => {

                            return context.raw.type.for !== 'property';
                        }
                    }
                ],
                disable : [
                    {
                        message  : 'hai value is disabled because status',
                        validate : (resource) => {

                            return resource.status !== undefined && resource.status !== 'draft' && resource.status !== 'ready';
                        }
                    }, {
                        message  : 'hai value is disabled because feesPayableBy is not seller',
                        validate : (resource, user, context) => {

                            if (context && context.raw && context.raw.type) {

                                return context.raw.type.feesPayableBy === 'buyer';
                            }

                            return false;
                        }
                    }
                ],
                format : {
                    in(resource, user, context) {

                        if (resource && resource.price && resource.fees
                            && context && context.raw && context.raw.type && context.raw.type.for === 'property') {

                            resource.hai = {
                                value : resource.price.value + resource.fees.value
                            };
                        }

                        return resource;
                    }
                },
                event : {
                    change : (haiValue, resource, user, context) => {

                        if (resource && resource.fees && resource.hai
                            && context && context.raw && context.raw.type && context.raw.type.feesPayableBy === 'seller') {

                            resource.price = {
                                value : resource.hai.value - resource.fees.value
                            };
                        }

                        return resource;
                    }
                },
                rules : [
                    { required : true, message : i18n.t('rules.required', { id : i18n.t('mandate.hai.value') }), trigger : 'blur' },
                    {
                        type    : 'number',
                        min     : 1,
                        message : i18n.t('rules.number.min', { id : i18n.t('mandate.hai.value'), min : 0 }),
                        trigger : 'blur'
                    },
                    {
                        id        : 'check_hai_min',
                        validator : (rule, value, callback, source, option) => ruleHaiMin(rule, value, callback, source, option, 'value'),
                        trigger   : 'blur'
                    }
                ]
            },
            'hai.min' : {
                label : 'mandate.hai.min',
                hide  : [
                    {
                        message  : 'hai min is hidden because type for is not client',
                        validate : (resource, user, context) => {

                            return context.raw.type.for !== 'client';
                        }
                    }
                ],
                disable : [
                    {
                        message  : 'hai min is disabled because status',
                        validate : (resource) => {

                            return resource.status !== undefined && resource.status !== 'draft' && resource.status !== 'ready';
                        }
                    }, {
                        message  : 'hai min is disabled because feesPayableBy is not buyer',
                        validate : (resource, user, context) => {

                            if (context && context.raw && context.raw.type) {

                                return context.raw.type.feesPayableBy === 'buyer';
                            }

                            return false;
                        }
                    }
                ],
                format : {
                    in(resource, user, context) {

                        if (resource && resource.price && resource.fees
                            && context && context.raw && context.raw.type && context.raw.type.for === 'client') {

                            resource.hai = {
                                ...resource.hai,
                                min : resource.price.min + resource.fees.min
                            };
                        }

                        return resource;
                    }
                },
                event : {
                    change : (haiValue, resource, user, context) => {

                        if (resource && resource.fees && resource.hai
                            && context && context.raw && context.raw.type && context.raw.type.feesPayableBy === 'seller') {

                            resource.price = {
                                ...resource.price,
                                min : resource.hai.min - resource.fees.min
                            };
                        }

                        return resource;
                    }
                },
                rules : [
                    { required : true, message : i18n.t('rules.required', { id : i18n.t('mandate.hai.min') }), trigger : 'blur' },
                    { type : 'number', min : 1, message : i18n.t('rules.number.min', { id : i18n.t('mandate.hai.min'), min : 0 }), trigger : 'blur' },
                    {
                        id        : 'check_hai_min',
                        validator : (rule, value, callback, source, option) => ruleHaiMin(rule, value, callback, source, option, 'min'),
                        trigger   : 'blur'
                    }, {
                        id : 'check_hai_min_max',
                        validator(rule, value, callback, source, { form : { value : resource } }) {

                            if (resource.hai.min > resource.hai.max) {

                                return callback(new Error(i18n.t('rules.max_min', {
                                    minField : i18n.t('mandate.hai.min'),
                                    maxField : i18n.t('mandate.hai.max')
                                })));
                            }

                            return callback();
                        },
                        trigger : 'blur'
                    }
                ]
            },
            'hai.max' : {
                label : 'mandate.hai.max',
                hide  : [
                    {
                        message  : 'hai max is hidden because type for is not client',
                        validate : (resource, user, context) => {

                            return context.raw.type.for !== 'client';
                        }
                    }
                ],
                disable : [
                    {
                        message  : 'hai max is disabled because status',
                        validate : (resource) => {

                            return resource.status !== undefined && resource.status !== 'draft' && resource.status !== 'ready';
                        }
                    }, {
                        message  : 'hai max is disabled because feesPayableBy is not buyer',
                        validate : (resource, user, context) => {

                            if (context && context.raw && context.raw.type) {

                                return context.raw.type.feesPayableBy === 'buyer';
                            }

                            return false;
                        }
                    }
                ],
                format : {
                    in(resource, user, context) {

                        if (resource && resource.price && resource.fees
                            && context && context.raw && context.raw.type && context.raw.type.for === 'client') {

                            resource.hai = {
                                ...resource.hai,
                                max : resource.price.max + resource.fees.max
                            };
                        }

                        return resource;
                    }
                },
                event : {
                    change : (haiValue, resource, user, context) => {

                        if (resource && resource.fees && resource.hai
                            && context && context.raw && context.raw.type && context.raw.type.feesPayableBy === 'seller') {

                            resource.price = {
                                ...resource.price,
                                max : resource.hai.max - resource.fees.max
                            };
                        }

                        return resource;
                    }
                },
                rules : [
                    { required : true, message : i18n.t('rules.required', { id : i18n.t('mandate.hai.max') }), trigger : 'blur' },
                    { type : 'number', min : 1, message : i18n.t('rules.number.min', { id : i18n.t('mandate.hai.max'), min : 0 }), trigger : 'blur' },
                    {
                        id        : 'check_hai_min',
                        validator : (rule, value, callback, source, option) => ruleHaiMin(rule, value, callback, source, option, 'max'),
                        trigger   : 'blur'
                    }
                ]
            },
            'sale' : {
                label   : 'avenant.sale',
                disable : [
                    {
                        message  : 'sale is disabled because status',
                        validate : (resource) => {

                            return resource.status !== undefined && resource.status !== 'draft' && resource.status !== 'ready';
                        }
                    }
                ],
                format : {
                    out(resource) {

                        if (resource && resource.sale === null) {

                            resource.sale = false;
                        }

                        return resource;
                    }
                }
            },
            'number' : {
                format : {
                    out(resource, user, raw, context) {

                        if (context && context.avenants) {

                            resource.number = (Math.max.apply(Math, context.avenants.map(a => a.number)) + 1);

                            if (isNaN(resource.number) || !isFinite(resource.number)) {

                                resource.number = 1;
                            }
                        }

                        return resource;
                    }
                }
            },
            'grace.active' : {
                label   : 'mandate.grace.active',
                roles   : ['super_admin', 'mandate'],
                disable : [
                    {
                        message  : 'grace.active is disabled because status',
                        validate : (resource) => {

                            return resource.status !== undefined && resource.status !== 'draft' && resource.status !== 'ready';
                        }
                    }
                ]
            },
            'grace.expires' : {
                label   : 'mandate.grace.expires',
                roles   : ['super_admin', 'mandate'],
                disable : [
                    {
                        message  : 'grace.expires is disabled because status',
                        validate : (resource) => {

                            return resource.status !== undefined && resource.status !== 'draft' && resource.status !== 'ready';
                        }
                    }
                ],
                hide : [
                    {
                        message  : 'grace expire is disabled because grace in not active',
                        validate : (resource) => {

                            return !resource.grace.active;
                        }
                    }
                ],
                rules : [
                    { required : true, message : i18n.t('rules.required', { id : i18n.t('mandate.grace.expires') }), trigger : 'blur' }
                ]
            },
            'status' : {
                label  : 'avenant.status',
                format : {
                    out(resource) {

                        if (resource.status === 'completed') {

                            delete resource.price;
                            delete resource.fees;
                        }

                        return resource;
                    }
                }
            },
            'completedAt' : {
                label : 'avenant.completedAtFormat',
                hide  : [
                    {
                        message  : 'completedAt is hidden because status is not completed',
                        validate : (resource) => {

                            return resource.status !== 'completed';
                        }
                    }
                ],
                rules : [{ required : true, message : i18n.t('rules.required', { id : i18n.t('avenant.completedAtFormat') }), trigger : 'blur' }]
            }
        };
    }
}
