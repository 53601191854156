import formatters     from '@/utils/Element/formatters';
import i18n           from '@/lang';
import { mapGetters } from 'vuex';

const PhoneNumber = require( 'awesome-phonenumber' );
const nationalities = require('i18n-nationality');
nationalities.registerLocale(require('i18n-nationality/langs/en.json'));
nationalities.registerLocale(require('i18n-nationality/langs/fr.json'));

export default {
    computed : {
        ...mapGetters(['language'])
    },
    methods : {

        formatNumber(value) {

            if (value !== null && value !== 0 && value !== undefined) {

                return Intl.NumberFormat(this.language, { minimumFractionDigits : 0 }).format(value);
            }

            return 0
        },

        formatPourcentage(value) {

            return `${this.formatNumber(value)} %`
        },

        formatPrice(value) {

            return formatters.price(null, null, value, this.language)
        },

        formatDate(value) {

            return this.$moment(value).format('DD/MM/YYYY');
        },

        dateTime(row, column, cellValue) {

            return this.$moment(cellValue).format('DD/MM/YYYY HH:mm');
        },

        formatSpace(value, isMax) {

            if (isMax) {
                return `${this.formatNumber(value)}+ m²`
            }

            return `${this.formatNumber(value)} m²`
        },

        formatNationality(code) {

            if (code !== null && code !== undefined) {

                return nationalities.getName(code, this.language)
            }

            return ''
        },
        formatDuration(duration) {

            const momentDuration = this.$moment.duration(duration, 'seconds')

            const m = Math.floor( ( momentDuration / 1000 / 60 ) % 60 );
            const h = Math.floor( ( momentDuration / ( 1000 * 60 * 60 ) ) % 24 );
            const d = Math.floor( momentDuration / ( 1000 * 60 * 60 * 24 ) );

            let formatedDuration = ''

            if (d !== 0) {

                formatedDuration += i18n.tc('main.duration.days', d, { d : d })
            }

            if (h !== 0) {
                formatedDuration += (formatedDuration !== '')
                    ? (m !== 0)
                        ? ', '
                        : ` ${i18n.t('main.and')} `
                    : ''

                formatedDuration += i18n.tc('main.duration.hours', h, { h : h })
            }

            if (m !== 0) {

                formatedDuration += (formatedDuration !== '') ? ` ${i18n.t('main.and')} ` : ''

                formatedDuration += i18n.tc('main.duration.minutes', m, { m : m })
            }

            return formatedDuration;
        },
        formatDistance(distance) {

            return `${this.formatNumber(Math.round(distance / 1000))} km`
        },
        // PHONE
        formatPhone(number, type = 'international') {

            if (number === null || number === '' || number === undefined ) {

                return null
            }

            const pn = new PhoneNumber(number);

            return pn.getNumber( type );
        }
    }
}
