'use strict';

import Config    from '@/config';
import store     from '@/store';
import HestiaSDK from '@pixul/hestia-js-sdk';


export default new HestiaSDK({
    prefixUrl   : Config.baseURL,
    timeout     : 1000000,
    headers     : {
        'content-type' : 'application/json'
    },
    hooks : {
        beforeRequest : [
            request => {

                if (store.getters.token) {

                    request.headers.set('Authorization', `Bearer ${store.getters.token}`);
                }
            }
        ],
        afterResponse : [
            (request, options, response) => {

                if (response && response.status && response.status === 401) {
                    store.dispatch('logout');
                }
            }
        ]
    }
})
