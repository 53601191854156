import * as Types  from '../mutationTypes';
import ObjectUtils from '@/utils/object';
import HestiaApi   from '@/api';
import StringUtils from '@/utils/string';

// initial state
const state = {
    threads          : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    thread_labels    : [],
    thread_templates : [],
    currentThread    : {}
};

// getters
const getters = {
    threads          : state => state.threads,
    thread_labels    : state => state.thread_labels,
    thread_templates : state => state.thread_templates,
    currentThread    : state => state.currentThread
};

// actions
const actions = {
    async addThread({}, { tenantId, thread, message }) {

        const createdThread = await HestiaApi.Thread.thread.create(tenantId, thread).json();

        const createdMessage = await HestiaApi.Thread.message.create(tenantId, {
            ...message,
            threadId : createdThread.id
        }).json();

        return {
            ...createdThread,
            messages : [createdMessage],
            read     : [createdThread.owner]
        };
    },
    async getThreads({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Thread.thread.search(tenantId, query).json();

        const threads = {
            results    : response.hits.hits.map((m) => m._source),
            pagination : {
                perPage : query.size,
                page    : ((query.from / query.size) + 1),
                total   : response.hits.total.value
            }
        };

        commit(Types.RECEIVE_THREADS, threads);
    },
    async selectThread({ commit }, { tenantId, threadId }) {

        const response = await HestiaApi.Thread.thread.get(tenantId, threadId).json();
        commit(Types.SELECT_THREAD, response);
    },
    async editThread({ commit }, { tenantId, threadId, thread }) {

        const response = await HestiaApi.Thread.thread.update(tenantId, threadId, thread).json();
        commit(Types.EDIT_THREAD, response);
    },
    async openThread({ commit }, { tenantId, threadId }) {

        await HestiaApi.Thread.thread.open(tenantId, threadId);
        commit(Types.OPEN_THREAD, threadId);
    },
    async closeThread({ commit }, { tenantId, threadId }) {

        await HestiaApi.Thread.thread.close(tenantId, threadId);
        commit(Types.CLOSE_THREAD, threadId);
    },
    async readThread({ commit }, { tenantId, thread, userId }) {

        await HestiaApi.Thread.thread.read(tenantId, thread.id);

        if (!thread.read) {
            thread.read = [];
        }

        thread.read.push(userId);

        commit(Types.EDIT_THREAD, thread);
    },
    async unreadThread({ commit }, { tenantId, thread, userId }) {

        await HestiaApi.Thread.thread.unread(tenantId, thread.id);

        thread.read.splice(thread.read.findIndex((r) => r === userId), 1);

        commit(Types.EDIT_THREAD, thread);
    },
    async readMultipleThreads({ commit }, { tenantId, threadIds, userId }) {

        await HestiaApi.Thread.thread.readMultiple(tenantId, {threadIds});

        commit(Types.EDIT_READ_MULTIPLE_THREAD, { threadIds, userId, read : true });
    },
    async unreadMultipleThreads({ commit }, { tenantId, threadIds, userId }) {

        await HestiaApi.Thread.thread.unreadMultiple(tenantId, {threadIds});

        commit(Types.EDIT_READ_MULTIPLE_THREAD, { threadIds, userId, read : false });
    },
    // LABEL
    async addThreadLabel({ commit }, { tenantId, threadLabel }) {

        const response = await HestiaApi.Thread.label.create(tenantId, threadLabel).json();
        commit(Types.ADD_THREAD_LABEL, response);
        return response;
    },
    async getThreadLabels({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Thread.label.list(tenantId, { searchParams : query }).json();
        commit(Types.RECEIVE_THREAD_LABELS, response);
    },
    async editThreadLabel({ commit }, { tenantId, threadLabelId, threadLabel }) {

        const response = await HestiaApi.Thread.label.update(tenantId, threadLabelId, threadLabel).json();
        commit(Types.EDIT_THREAD_LABEL, response);
    },
    // LABEL
    async addThreadTemplate({ commit }, { tenantId, threadTemplate, pageType }) {

        const response = await HestiaApi.Thread.template.create(tenantId, threadTemplate).json();

        if (pageType === response.type.toLowerCase()) {

            commit(Types.ADD_THREAD_TEMPLATE, response);
        }

        return response;
    },
    async getThreadTemplates({ commit }, { tenantId, query }) {

        const type = 'list' + StringUtils.ucFirst(query.type);

        query = ObjectUtils.omit(query, ['type', 'page', 'perPage']);

        const response = await HestiaApi.Thread.template[type](tenantId, { searchParams : query }).json();
        commit(Types.RECEIVE_THREAD_TEMPLATES, response);
    },
    async editThreadTemplate({ commit }, { tenantId, threadTemplateId, threadTemplate }) {

        const response = await HestiaApi.Thread.template.update(tenantId, threadTemplateId, threadTemplate).json();
        commit(Types.EDIT_THREAD_TEMPLATE, response);
    },
    async deleteThreadTemplate({ commit }, { tenantId, threadTemplate, pageType }) {

        await HestiaApi.Thread.threadTemplate.delete(tenantId, threadTemplate.id);

        if (pageType === threadTemplate.type.toLowerCase()) {

            commit(Types.DELETE_THREAD_TEMPLATE, threadTemplate.id);
        }
    }
};

// mutations
const mutations = {
    [Types.RECEIVE_THREADS](state, threads) {

        state.threads = threads;
    },
    [Types.SELECT_THREAD](state, thread) {

        state.currentThread = thread;
    },
    [Types.EDIT_THREAD](state, thread) {

        if (!thread.messages && state.currentThread.messages) {

            thread.messages = state.currentThread.messages;
        }

        state.currentThread = thread;

        const index = state.threads.results.findIndex((t) => t.id === thread.id);

        if (index !== -1) {
            state.threads.results.splice(index, 1, thread);
        }
    },
    [Types.EDIT_READ_MULTIPLE_THREAD](state, { threadIds, userId, read }) {

        threadIds.forEach(threadId => {

            const index = state.threads.results.findIndex((t) => t.id === threadId);

            if (index !== -1) {

                const thread = state.threads.results[index];

                if (read) {

                    thread.read.push(userId);
                }
                else {

                    thread.read.splice(thread.read.findIndex((r) => r === userId), 1);
                }

                state.threads.results.splice(index, 1, {
                    ...thread
                });
            }
        });

    },
    [Types.OPEN_THREAD](state, threadId) {

        state.currentThread.open = true;

        const index = state.threads.results.findIndex((t) => t.id === threadId);

        if (index !== -1) {

            state.threads.results.splice(index, 1, {
                ...state.threads.results[index],
                open : true
            });
        }
    },
    [Types.CLOSE_THREAD](state, threadId) {

        state.currentThread.open = false;

        const index = state.threads.results.findIndex((t) => t.id === threadId);

        if (index !== -1) {

            state.threads.results.splice(index, 1, {
                ...state.threads.results[index],
                open : false
            });
        }
    },
    [Types.RECEIVE_THREAD_LABELS](state, thread_labels) {

        state.thread_labels = thread_labels;
    },
    [Types.ADD_THREAD_LABEL](state, threadLabel) {

        state.thread_labels.push(threadLabel);
    },
    [Types.EDIT_THREAD_LABEL](state, threadLabel) {

        const index = state.thread_labels.findIndex((lab) => lab.id === threadLabel.id);
        state.thread_labels.splice(index, 1, threadLabel);
    },
    [Types.RECEIVE_THREAD_TEMPLATES](state, thread_templates) {

        state.thread_templates = thread_templates;
    },
    [Types.ADD_THREAD_TEMPLATE](state, threadTemplate) {

        state.thread_templates.push(threadTemplate);
    },
    [Types.EDIT_THREAD_TEMPLATE](state, threadTemplate) {

        const index = state.thread_templates.findIndex((lab) => lab.id === threadTemplate.id);
        state.thread_templates.splice(index, 1, threadTemplate);
    },
    [Types.DELETE_THREAD_TEMPLATE](state, threadTemplateId) {

        const index = state.thread_templates.findIndex((tt) => tt.id === threadTemplateId);
        state.thread_templates.splice(index, 1);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
