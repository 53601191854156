<template>
  <div>
    <TopMenu />
    <Navbar navbar-class="navbar hide-before-medium" />
    <Breadcrumb class="breadcrumb" />
    <div class="app-wrapper">
      <div class="main-container">
        <AppMain class="app-main" />
      </div>
      <question />
    </div>
    <NavFooter />
  </div>
</template>

<script>


export default {
    name       : 'Layout',
    components : {
        TopMenu    : () => import('./components/TopMenu.vue'),
        Navbar     : () => import('./components/Navbar.vue'),
        AppMain    : () => import('./components/AppMain.vue'),
        Breadcrumb : () => import('@/components/Breadcrumb/index.vue'),
        NavFooter  : () => import('./components/NavFooter.vue'),
        Question   : () => import('./components/Question.vue')
    },
    computed : {}

};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar-container {
    background-color: #fff;
    width: 100%;
    margin-bottom: 2px;
}

.breadcrumb {
    margin: 0 15px;
}

@media (max-width: 768px) {
    .hide-before-medium {
        display: none;
    }
}
</style>
