import i18n  from '@/lang'; // Internationalization
import store from '@/store';

export function getRule(type, userType, role, userId, resourceId = null) {

    // Search in resource
    const specificRuleToResource = store.getters.specificRules.find(r => {

        return (
            r.type === type &&
            r.userId === userId &&
            r.userType === userType &&
            r.role === role &&
            r.resourceId === resourceId
        );
    });

    if (specificRuleToResource) {
        return specificRuleToResource.newRole;
    }

    const specificRuleToAgent = store.getters.specificRules.find(r => {

        return (
            r.type === type &&
            r.userId === userId &&
            r.userType === userType &&
            r.role === role &&
            r.resourceId === null
        );
    });

    if (specificRuleToAgent) {
        return specificRuleToAgent.newRole;
    }

    return store.getters.mainRules[userType][type][role];
}

export async function changeRule(newRole, type, userType, role, userId, resourceId = null ) {

    return await store.dispatch('changePortfolioSpecificRule', { newRole, type, userType, role, userId, resourceId })
}

export function getPossibleRoles(role) {

    if (!role) {

        return []
    }

    const rules = {
        owner        : ['owner', 'coOwner', 'collaborator', 'guest'],
        coOwner      : ['coOwner', 'collaborator', 'guest'],
        collaborator : ['coOwner', 'collaborator', 'guest']
    };

    const possibleRoles = rules[role];

    return possibleRoles.map(r => {

        return {
            label : i18n.tc(`acl.${r}`, 0),
            value : r
        };
    });
}

export function getUserResourceRole(userId, resourceId) {

    if (!store.getters.usersRoles ||
         !store.getters.usersRoles[userId] ||
         !store.getters.usersRoles[userId][resourceId]) {
        return null
    }

    return store.getters.usersRoles[userId][resourceId]
}
