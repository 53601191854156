import * as Types from '../mutationTypes';

import HestiaApi       from '@/api';
import store           from '@/store';
import { cacheAction } from 'vuex-cache';

// initial state
const state = {
    companies : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentCompany : {
        clients : []
    },
    currentCompanyCounts : {
        'clients'    : null,
        'properties' : null,
        'mandates'   : null,
        'documents'  : null,
        'issues'     : null
    },
    currentCompanyMandates : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentCompanyIssues : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    companyDocumentTypes : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    }
};

// getters
const getters = {
    companies              : state => state.companies,
    currentCompany         : state => state.currentCompany,
    currentCompanyMandates : state => state.currentCompanyMandates,
    currentCompanyCounts   : state => state.currentCompanyCounts,
    currentCompanyIssues   : state => state.currentCompanyIssues,
    companyDocumentTypes   : state => state.companyDocumentTypes
};

// actions
const actions = {

    'getCompany' : cacheAction(({}, { tenantId, id }) => {

        return HestiaApi.Company.company.get(tenantId, id).json();
    }),
    async addCompany({ commit }, { tenantId, company }) {

        const response = await HestiaApi.Company.company.create(tenantId, company).json();
        commit(Types.ADD_COMPANY, response);
        return response;
    },
    async getCompanies({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Company.company.search(tenantId, query).json();

        const companies = {
            results    : response.hits.hits.map((m) => m._source),
            pagination : {
                perPage : query.size,
                page    : ((query.from / query.size) + 1),
                total   : response.hits.total.value
            }
        };

        commit(Types.RECEIVE_COMPANIES, companies);
    },
    async editCompany({ commit }, { tenantId, companyId, company }) {

        const response = await HestiaApi.Company.company.update(tenantId, companyId, company).json();
        commit(Types.EDIT_COMPANY, response);

        return response;
    },
    async removeCompany({ commit }, { tenantId, companyId }) {

        await Company.company.delete(tenantId, companyId);
        commit(Types.REMOVE_COMPANY, companyId);
    },
    async selectCompany({ commit }, { tenantId, id }) {

        const company = await HestiaApi.Company.company.get(tenantId, id).json();

        company.clients.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));

        commit(Types.SELECT_COMPANY, company);

        // Get mandate before others count, because, we need them to get properties count
        const mandatesData = await HestiaApi.Mandate.mandate.search(tenantId, {
            'query' : {
                'match' : {
                    'companies' : id
                }
            },
            '_source' : ['id'],
            'from'    : 0,
            'size'    : 1000
        }).json();

        const mandates = mandatesData.hits.total.value;

        commit(Types.INIT_COMPANY_COUNTS, {
            'company' : company,
            'counts'  : {
                'mandates' : mandates
            }
        });

        const queries = []

        queries.push(async ()=>{

            try {

                const should = mandatesData.hits.hits.map(m => {

                    return {  'match' : { 'mandates.id' : m._source.id} }
                })
                const statuses = ['sale', 'estimation', 'offer', 'offer_other'];

                const  {  hits : { total : { value :  properties } } }  = mandates === 0 ? {  hits : { total : { value : 0 } } } : await HestiaApi.Property.property.search(tenantId, {
                    query : {
                        bool : {
                            must : [
                                {
                                    bool : {
                                        should : statuses.map((status) => {

                                            return {
                                                match : {
                                                    status
                                                }
                                            }
                                        })
                                    }
                                },
                                {
                                    nested : {
                                        path  : 'mandates',
                                        query : {
                                            bool : {
                                                should
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    from : 0,
                    size : 50
                }).json();

                commit(Types.INIT_COMPANY_COUNTS, {
                    'company' : company,
                    'counts'  : {
                        'properties' : properties
                    }
                });
            }
            catch (e) {

            }

            try {
                const { hits : { total : { value : issues } } } = await HestiaApi.Thread.thread.search(tenantId, {
                    'query' : {
                        'bool' : {
                            'must' : [
                                { 'match' : { 'type' : 'ISSUE' } },
                                {
                                    'bool' : {
                                        'should' : [
                                            {
                                                'nested' : {
                                                    'path'  : 'metadata',
                                                    'query' : { 'bool' : { 'should' : [{ 'match' : { 'metadata.identifier.id' : id } }] } }
                                                }
                                            }, {
                                                'nested' : {
                                                    'path'  : 'additionalMetadata',
                                                    'query' : { 'bool' : { 'should' : [{ 'match' : { 'additionalMetadata.identifier.id' : id } }] } }
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                }).json();

                commit(Types.INIT_COMPANY_COUNTS, {
                    'company' : company,
                    'counts'  : {
                        'issues' : issues
                    }
                });
            }
            catch (e) {
            }

            try {
                const { pagination : { total : clients } } = await HestiaApi.Company.client.list(tenantId, id, {}).json();

                commit(Types.INIT_COMPANY_COUNTS, {
                    'company' : company,
                    'counts'  : {
                        'clients' : clients
                    }
                })
            }
            catch (e) {
            }
        });

        queries.forEach((query)=>{

            query()
        })

    },
    async addUserCompany({ commit }, { tenantId, companyId, company }) {

        const response = await HestiaApi.Company.user.createCompany(tenantId, companyId, company).json();
        commit(Types.ADD_USER_COMPANY, response);
        return response;
    },
    // eslint-disable-next-line no-unused-vars
    async removeUserCompany({ commit }, { tenantId, companyId, userId }) {

        await HestiaApi.Company.user.deleteCompany(tenantId, companyId, userId);
    },
    async editUserCompany({ commit }, { tenantId, companyId, userId, company }) {

        const response = await HestiaApi.Company.user.editCompany(tenantId, companyId, userId, company).json();
        commit(Types.EDIT_USER_COMPANY, response);
    },
    async getCompanyMandates({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Mandate.mandate.search(tenantId, query).json();

        const mandates = {
            results    : response.hits.hits.map((m) => m._source),
            pagination : {
                perPage : query.size,
                page    : ((query.from / query.size) + 1),
                total   : response.hits.total.value
            }
        };

        commit(Types.RECEIVE_COMPANY_MANDATES, mandates);

    },
    async getCompanyIssues({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Thread.thread.search(tenantId, query).json();

        const issues = {
            results    : response.hits.hits.map((m) => m._source),
            pagination : {
                perPage : query.size,
                page    : ((query.from / query.size) + 1),
                total   : response.hits.total.value
            }
        };

        commit(Types.RECEIVE_COMPANY_ISSUES, issues);
    },
    async addCompanyClient({ commit }, { tenantId, id, client, company }) {

        const response = await HestiaApi.Company.client.add(tenantId, id, client).json();
        commit(Types.ADD_COMPANY_CLIENT, { client : response, company });
    },
    async removeCompanyClient({ commit }, { tenantId, id, clientId }) {

        await HestiaApi.Company.client.remove(tenantId, id, clientId);

        commit(Types.REMOVE_COMPANY_CLIENT, { companyId : id, clientId });

    },
    async editCompanyClient({ commit }, { tenantId, id, clientId, client }) {

        const response = await HestiaApi.Company.client.update(tenantId, id, clientId, client).json();
        commit(Types.EDIT_COMPANY_CLIENT, response);
    },
    async editCompanyClientOrder({ commit }, { tenantId, id, clientId, newOrder, oldOrder }) {

        const response = await HestiaApi.Company.client.update(tenantId, id, clientId, { order : newOrder }).json();

        commit(Types.EDIT_COMPANY_CLIENT_ORDER, { client : response, newOrder : newOrder, oldOrder : oldOrder });
    },
    // ACL
    async editCompanyOwner({ commit }, { tenantId, id, owner }) {

        const response = await HestiaApi.Company.company.editOwner(tenantId, id, owner).json();

        commit(Types.EDIT_COMPANY, response);
    },
    async addCompanyCollaborator({ commit }, { tenantId, id, collaborator }) {

        const response = await HestiaApi.Company.company.addCollaborator(tenantId, id, collaborator).json();

        commit(Types.EDIT_COMPANY, response);
    },
    async addCompanyCoowner({ commit }, { tenantId, id, coowner }) {

        const response = await HestiaApi.Company.company.addCoOwner(tenantId, id, coowner).json();

        commit(Types.EDIT_COMPANY, response);
    },
    async addCompanyIssue({ commit }, { issue }) {

        commit(Types.ADD_COMPANY_ISSUE, issue);
    },
    async removeCompanyCollaborator({ commit }, { tenantId, id, collaborator }) {

        await HestiaApi.Company.company.removeCollaborator(tenantId, id, collaborator);

        commit(Types.REMOVE_COMPANY_COLLABORATOR, collaborator);
    },
    async removeCompanyCoowner({ commit }, { tenantId, id, coowner }) {

        await HestiaApi.Company.company.removeCoOwner(tenantId, id, coowner);

        commit(Types.REMOVE_COMPANY_COOWNER, coowner);
    },
    async addCompanyDocument({ commit }, { tenantId, id, document }) {

        const newDocument = await HestiaApi.Company.document.add(tenantId, id, document).json();
        commit(Types.COMPANY_ADD_DOCUMENT, newDocument);
    },
    async editCompanyDocument({ commit }, { tenantId, companyId, id, document }) {

        const response = await HestiaApi.Company.document.update(tenantId, companyId, id, document).json();
        commit(Types.COMPANY_EDIT_DOCUMENT, response);
    },
    async removeCompanyDocument({ commit }, { tenantId, companyId, documentId }) {

        await HestiaApi.Company.document.remove(tenantId, companyId, documentId);
        commit(Types.COMPANY_DELETE_DOCUMENT, documentId);
    },
    // TYPES
    async getCompanyDocumentTypes({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Company.documentType.list(tenantId, { searchParams : query }).json();
        commit(Types.RECEIVE_COMPANY_DOCUMENT_TYPES, response);
    },
    async addCompanyDocumentType({ commit }, { tenantId, documentType }) {

        const response = await HestiaApi.Company.documentType.create(tenantId, documentType).json();
        commit(Types.ADD_COMPANY_DOCUMENT_TYPE, response);
    },
    async editCompanyDocumentType({ commit }, { tenantId, id, documentType }) {

        const response = await HestiaApi.Company.documentType.update(tenantId, id, documentType).json();
        commit(Types.EDIT_COMPANY_DOCUMENT_TYPE, response);
    },
    async deleteCompanyDocumentType({ commit }, { tenantId, id }) {

        const response = await HestiaApi.Company.documentType.delete(tenantId, id).json();
        commit(Types.REMOVE_COMPANY_DOCUMENT_TYPE, response);
    }
};

// mutations
const mutations = {
    [Types.RECEIVE_COMPANIES](state, companies) {

        state.companies = companies;
    },
    [Types.ADD_COMPANY](state, company) {

        state.companies.results.push(company);
    },
    [Types.EDIT_COMPANY](state, company) {

        state.currentCompany = company;

        const index = state.companies.results.findIndex((com) => com.id === company.id);
        state.companies.results.splice(index, 1, company);

    },
    [Types.REMOVE_COMPANY](state, companyId) {

        state.companies.results.splice(companyId, 1);
    },
    [Types.SELECT_COMPANY](state, company) {

        state.currentCompany = company;
    },
    [Types.ADD_USER_COMPANY](state, company) {

        state.currentCompany = company;
    },
    [Types.EDIT_USER_COMPANY](state, company) {

        state.currentCompany = company;
    },
    [Types.SELECT_COMPANY](state, company) {

        state.currentCompany = company;
    },
    [Types.RECEIVE_COMPANY_MANDATES](state, company_mandates) {

        state.currentCompanyMandates = company_mandates;
    },
    [Types.RECEIVE_COMPANY_ISSUES](state, company_issues) {

        state.currentCompanyIssues = company_issues;
    },
    [Types.ADD_COMPANY_ISSUE](state, issue) {

        // Manually Add
        state.currentCompanyIssues.results.unshift(issue);
        state.currentCompanyIssues.pagination.total++;

        if (state.currentCompanyIssues.pagination.total > state.currentCompanyIssues.pagination.perPage) {
            state.currentCompanyIssues.results.pop();
        }

        // Update counts
        state.currentCompanyCounts['issues']++;
    },

    [Types.ADD_COMPANY_CLIENT](state, { client, company }) {

        state.currentCompany.clients.push(client);
        store.getters.currentClientCompanies.push(company);

        // Update counts
        state.currentCompanyCounts.clients++;
        store.getters.currentClientCounts.companies++;
    },
    [Types.REMOVE_COMPANY_CLIENT](state, { companyId, clientId }) {

        const index = state.currentCompany.clients.findIndex((c) => c.clientId === clientId);
        state.currentCompany.clients.splice(index, 1);

        const indexCompany = store.getters.currentClientCompanies.findIndex((c) => c.id === companyId);
        store.getters.currentClientCompanies.splice(indexCompany, 1);

        state.currentCompanyCounts.clients--;
        store.getters.currentClientCounts.companies--;
    },
    [Types.EDIT_COMPANY_CLIENT](state, client) {

        const indexClient = state.currentCompany.clients.findIndex((c) => c.clientId === client.clientId);
        state.currentCompany.clients.splice(indexClient, 1, client);
    },
    [Types.EDIT_COMPANY_CLIENT_ORDER](state, { client, newOrder, oldOrder }) {

        if (newOrder > oldOrder) {

            state.currentCompany.clients = state.currentCompany.clients.reduce((acc, t) => {

                if (t.order > oldOrder && t.order <= newOrder) {
                    t.order = t.order - 1;
                }

                acc.push(t);

                return acc;
            }, []);

        }
        else {

            state.currentCompany.clients = state.currentCompany.clients.reduce((acc, t) => {

                if (t.order < oldOrder && t.order >= newOrder) {
                    t.order = t.order + 1;
                }

                acc.push(t);

                return acc;
            }, []);
        }

        const indexCurrentCompany = state.currentCompany.clients.findIndex((c) => c.clientId === client.clientId);
        state.currentCompany.clients.splice(indexCurrentCompany, 1, client);

    },

    // ACL

    [Types.REMOVE_COMPANY_COLLABORATOR](state, collaborator) {

        const index = state.currentCompany.collaborators.findIndex((c) => c === collaborator.id);
        state.currentCompany.collaborators.splice(index, 1);
    },
    [Types.REMOVE_COMPANY_COOWNER](state, coowner) {

        const index = state.currentCompany.coOwners.findIndex((c) => c.id === coowner.id);
        state.currentCompany.coOwners.splice(index, 1);
    },
    [Types.INIT_COMPANY_COUNTS](state, { company, counts }) {

        state.currentCompanyCounts = {
            'documents'  : 'documents' in company ? company.documents.length : 0,
            'properties' : !isNaN(counts.properties) ? counts.properties : state.currentCompanyCounts.properties,
            'clients'    : !isNaN(counts.clients) ? counts.clients : state.currentCompanyCounts.clients,
            'mandates'   : !isNaN(counts.mandates) ? counts.mandates : state.currentCompanyCounts.mandates,
            'issues'     : !isNaN(counts.issues) ? counts.issues : state.currentCompanyCounts.issues
        };
    },
    [Types.COMPANY_ADD_DOCUMENT](state, document) {

        state.currentCompany.documents.push(document);

        // Update counts
        state.currentCompanyCounts['documents']++;
    },
    [Types.COMPANY_EDIT_DOCUMENT](state, document) {

        const index = state.currentCompany.documents.findIndex((a) => a.id === document.id);
        state.currentCompany.documents.splice(index, 1, document);
    },
    [Types.COMPANY_DELETE_DOCUMENT](state, documentId) {

        const index = state.currentCompany.documents.findIndex((document) => document.id === documentId);
        state.currentCompany.documents.splice(index, 1);

        // Update counts
        state.currentCompanyCounts['documents']--;
    },
    // TYPES
    [Types.RECEIVE_COMPANY_DOCUMENT_TYPES](state, documentTypes) {

        state.companyDocumentTypes = documentTypes;
    },
    [Types.ADD_COMPANY_DOCUMENT_TYPE](state, documentType) {

        state.companyDocumentTypes.results.unshift(documentType);

        if (state.companyDocumentTypes.results.length === state.companyDocumentTypes.pagination.perPage) {
            state.companyDocumentTypes.results.pop();
        }
    },
    [Types.EDIT_COMPANY_DOCUMENT_TYPE](state, documentType) {

        const index = state.companyDocumentTypes.results.findIndex((companyDocumentType) => companyDocumentType.id === documentType[0].id);
        state.companyDocumentTypes.results.splice(index, 1, documentType[0]);
    },
    [Types.REMOVE_COMPANY_DOCUMENT_TYPE](state, id) {

        const index = state.companyDocumentTypes.results.findIndex((companyDocumentType) => companyDocumentType.id === id);
        state.companyDocumentTypes.results.splice(index, 1);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
