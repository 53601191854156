import {cloneDeep,pick} from 'lodash';

export default {
    clone(object) {

        return cloneDeep(object);
    },
    shallowClone(object) {

        return Object.assign({}, object);
    },
    shallowMerge(object1, object2) {

        return { ...object1, ...object2 };
    },
    omit(obj, keys) {

        if (!obj) {
            return obj
        }

        if (!Array.isArray(keys)) {
            keys = [keys];
        }

        if (!keys.length) {
            return obj
        }

        if (obj[keys[keys.length-1]] !== undefined) {

            // eslint-disable-next-line no-unused-vars
            const { [keys.pop()]: omitted, ...rest } = obj;

            return this.omit(rest,keys);
        }


        keys.pop()
        return this.omit(obj,keys)


    },
    pick(obj, keys) {

        return pick(obj,keys)
    }
};
