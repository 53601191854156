import { Message, MessageBox } from '@pixul/element-ui';

import i18n from '@/lang'; // Internationalization

export function i18nMessage({action, model, type = 'success', message = '', messageParams = {n : null}}) {

    // Declare
    const modelFemList = ['property_category', 'request', 'thread', 'client_search']

    Message({
        message : message !== '' ? i18n.tc(message, messageParams.n, messageParams) : i18n.t(`message.${action}`, {
            model : model !== undefined ? i18n.tc(`model.${model}`) : '',
            fem   : modelFemList.includes(model) ? 'e' : ''
        }),
        type     : type,
        duration : 6000
    });
}

export async function customConfirm({message = '', options = { icon : {}}}) {

    const h = this.$createElement;

    await MessageBox.confirm(
        h('div', { class : ['row-container', 'align-items-center'] },[
            h('span', { class : 'message-confirm-content-image'}, [
                h('CustomIcon', { props : { name : options.icon && options.icon.name || 'icons8-message-red' , size : options.icon && options.icon.size || 60 }})
            ]),
            h('span', { class : 'message-confirm-content-text'}, [
                message || i18n.t('confirm.default_message')
            ])
        ]),
        {
            confirmButtonText : options.confirmButtonText || i18n.t('confirm.confirm'),
            cancelButtonText  : options.cancelButtonText  || i18n.t('confirm.cancel'),
            showClose         : options.showClose || false
        }
    );
}

export async function confirmDelete({message = '', model = '', from = '', options = {icon : {}} }) {

    await this.$customConfirm({
        message : message || i18n.t('confirm.delete.message', { model : model, from : from }),
        options : {
            confirmButtonText : i18n.t('confirm.delete.confirm'),
            cancelButtonText  : i18n.t('confirm.delete.cancel'),
            ...options
        }
    })
}

export async function confirmCloseDialog(options = {icon : {}}) {

    await this.$customConfirm({
        message : i18n.t('confirm.close_dialog.message'),
        options : {
            confirmButtonText : i18n.t('confirm.close_dialog.confirm'),
            cancelButtonText  : i18n.t('confirm.close_dialog.cancel'),
            ...options
        }
    })
}
