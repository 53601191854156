import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

// initial state
const state = {
    developers : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentDeveloper         : {},
    currentDeveloperMandates : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    branches : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentBranch            : {},
    currentDeveloperContacts : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentDeveloperLogin    : {}
};

// getters
const getters = {
    developers               : state => state.developers,
    currentDeveloper         : state => state.currentDeveloper,
    currentDeveloperMandates : state => state.currentDeveloperMandates,
    branches                 : state => state.branches,
    currentBranch            : state => state.currentBranch,
    currentDeveloperContacts : state => state.currentDeveloperContacts,
    currentDeveloperLogin    : state => state.currentDeveloperLogin
};

// actions
const actions = {
    // DEVELOPER
    async getDevelopers({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Commercialisation.developer.list(tenantId, {searchParams : query}).json();
        commit(Types.RECEIVE_DEVELOPERS, response);
    },
    async addDeveloper({ commit }, { tenantId, developer }) {

        const response = await HestiaApi.Commercialisation.developer.create(tenantId, developer).json();
        commit(Types.ADD_DEVELOPER, response);
        return response;
    },
    async editDeveloper({ commit }, { id, developer, tenantId }) {

        const response = await HestiaApi.Commercialisation.developer.update(tenantId, id, developer).json();
        commit(Types.EDIT_DEVELOPER, response);
    },
    async getDeveloper({ commit }, { tenantId, id }) {

        const response = await HestiaApi.Commercialisation.developer.get(tenantId, id).json();
        commit(Types.RECEIVE_DEVELOPER, response);
    },
    async getDeveloperLogin({ commit }, { tenantId, id }) {

        const response = await HestiaApi.Commercialisation.developer.getLogin(tenantId, id).json();
        commit(Types.RECEIVE_DEVELOPER_LOGIN, response);
    },
    async addDeveloperLogin({ commit }, { tenantId, id, login }) {

        const response = await HestiaApi.Commercialisation.developer.putLogin(tenantId, id, login).json();
        commit(Types.ADD_DEVELOPER_LOGIN, response);
    },
    // BRANCH
    async getBranches({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Commercialisation.branch.list(tenantId, query).json();
        commit(Types.RECEIVE_BRANCHES, response);
    },
    async addBranch({ commit }, { tenantId, branch }) {

        const response = await HestiaApi.Commercialisation.branch.create(tenantId, branch).json();
        commit(Types.ADD_BRANCH, response);
        return response;
    },
    async editBranch({ commit }, { tenantId, id, branch }) {

        const response = await HestiaApi.Commercialisation.branch.update(tenantId, id, branch).json();
        commit(Types.EDIT_BRANCH, response);
    },
    async getBranch({ commit }, { tenantId, branchId }) {

        const response = await HestiaApi.Commercialisation.branch.get(tenantId, branchId).json();
        commit(Types.RECEIVE_BRANCH, response);
    },
    async removeBranch({ commit }, { tenantId, branchId }) {

        await HestiaApi.Commercialisation.branch.delete(tenantId, branchId);
        commit(Types.REMOVE_BRANCH, branchId);
    },
    async addBranchContact({ commit }, { tenantId, branchId, contact }) {

        const response = await HestiaApi.Commercialisation.branch.addContact(tenantId, branchId, contact).json();
        commit(Types.ADD_BRANCH_CONTACT, response);
    },
    async removeBranchContact({ commit }, { tenantId, branchId, contactId, contact }) {

        await HestiaApi.Commercialisation.branch.deleteContact(tenantId, branchId, contactId, contact);
        commit(Types.BRANCH_DELETE_CONTACT, contactId);
    },
    async getDeveloperMandates({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Commercialisation.mandate.list(tenantId, { searchParams : query }).json();

        commit(Types.RECEIVE_DEVELOPER_MANDATES, response);

    },
    async getDeveloperBranches({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Commercialisation.branch.list(tenantId, {searchParams : query}).json();

        commit(Types.RECEIVE_DEVELOPER_BRANCHES, response);

    },
    async getDeveloperBranchContacts({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Commercialisation.contact.list(tenantId, query).json();

        commit(Types.RECEIVE_DEVELOPER_BRANCH_CONTACTS, response);

    },
    async deleteDeveloperContact({ commit }, { contactId }) {

        commit(Types.DELETE_DEVELOPER_CONTACT, contactId);
    },
    async editDeveloperOwner({ commit }, { tenantId, id, owner, editCurrentDeveloper = true }) {

        const response = await HestiaApi.Commercialisation.developer.editOwner(tenantId, id, owner).json();

        if (editCurrentDeveloper) {

            commit(Types.EDIT_DEVELOPER, response);
        }
    },
    async addDeveloperCollaborator({ commit }, { tenantId, id, collaborator, editCurrentDeveloper = true }) {

        const response = await HestiaApi.Commercialisation.developer.addCollaborator(tenantId, id, collaborator).json();

        if (editCurrentDeveloper) {

            commit(Types.EDIT_DEVELOPER, response);
        }
    },
    async addDeveloperCoowner({ commit }, { tenantId, id, coowner, editCurrentDeveloper = true }) {

        const response = await HestiaApi.Commercialisation.developer.addCoOwner(tenantId, id, coowner).json();

        if (editCurrentDeveloper) {

            commit(Types.EDIT_DEVELOPER, response);
        }
    },
    async removeDeveloperCollaborator({ commit }, { tenantId, id, collaborator, editCurrentDeveloper = true }) {

        await HestiaApi.Commercialisation.developer.removeCollaborator(tenantId, id, collaborator);

        if (editCurrentDeveloper) {

            commit(Types.REMOVE_DEVELOPER_COLLABORATOR, collaborator);
        }
    },
    async removeDeveloperCoowner({ commit }, { tenantId, id, coowner, editCurrentDeveloper = true }) {

        await HestiaApi.Commercialisation.developer.removeCoOwner(tenantId, id, coowner);

        if (editCurrentDeveloper) {

            commit(Types.REMOVE_DEVELOPER_COOWNER, coowner);
        }
    }
};

// mutations
const mutations = {
    [Types.RECEIVE_DEVELOPERS](state, developers) {

        state.developers = developers;
    },
    [Types.RECEIVE_DEVELOPER](state, developer) {

        state.currentDeveloper         = developer;
        state.currentDeveloperContacts = {
            results    : [],
            pagination : {
                perPage : 0,
                page    : 1,
                total   : 0
            }
        };
    },
    [Types.ADD_DEVELOPER](state, developer) {

        state.currentDeveloper = developer;

        state.developers.results.push(developer);
    },
    [Types.EDIT_DEVELOPER](state, developer) {

        state.currentDeveloper = {
            ...developer
        };

        const index = state.developers.results.findIndex((dev) => dev.id === developer.id);

        state.developers.results.splice(index, 1, developer);
    },
    [Types.ADD_DEVELOPER_LOGIN](state, login) {

        state.currentDeveloperLogin = login;
    },
    [Types.RECEIVE_DEVELOPER_LOGIN](state, login) {

        state.currentDeveloperLogin = login;
    },
    [Types.REMOVE_DEVELOPER_COLLABORATOR](state, collaborator) {

        const index = state.currentDeveloper.collaborators.findIndex((c) => c.id === collaborator.id);
        state.currentDeveloper.collaborators.splice(index, 1);
    },
    [Types.REMOVE_DEVELOPER_COOWNER](state, coowner) {

        const index = state.currentDeveloper.coOwners.findIndex((c) => c.id === coowner.id);
        state.currentDeveloper.coOwners.splice(index, 1);
    },
    [Types.RECEIVE_BRANCHES](state, branches) {

        state.branches = branches;
    },
    [Types.RECEIVE_BRANCH](state, branch) {

        state.currentBranch = branch;
    },
    [Types.ADD_BRANCH](state, branch) {

        if (state.currentDeveloper.id === branch.developerId) {
            state.currentDeveloper.branches.push(branch);
        }
        else {
            state.branches.results.push(branch);
        }

    },
    [Types.REMOVE_BRANCH](state, id) {

        const index = state.currentDeveloper.branches.findIndex((b) => b.id === id);
        state.currentDeveloper.branches.splice(index, 1);
    },
    [Types.RECEIVE_CONTACTS](state, contacts) {

        state.contacts = contacts;
    },
    [Types.EDIT_BRANCH](state, branch) {

        state.currentBranch = {
            ...branch
        };
        const index         = state.currentDeveloper.branches.findIndex((b) => b.id === branch.id);
        state.currentDeveloper.branches.splice(index, 1, branch);
    },
    [Types.ADD_BRANCH_CONTACT](state, branch) {

        state.currentBranch = branch;
    },
    [Types.RECEIVE_DEVELOPER_MANDATES](state, developer_mandates_co) {

        state.currentDeveloperMandates = developer_mandates_co;
    },
    [Types.RECEIVE_DEVELOPER_BRANCHES](state, developer_branches) {

        state.branches = developer_branches;
    },
    [Types.RECEIVE_DEVELOPER_BRANCH_CONTACTS](state, developerBranchContact) {

        state.currentDeveloperContacts = developerBranchContact;
    },
    [Types.DELETE_DEVELOPER_CONTACT](state, contactId) {

        const index = state.currentDeveloperContacts.results.findIndex((contact) => contact.id === contactId);
        state.currentDeveloperContacts.results.splice(index, 1);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
