import * as Types from '../mutationTypes';

// initial state
const state = {
    isEditTab           : false,
    tryToLeave          : false,
    tryToLeaveTo        : '',
    viewEditLeaveAction : '',
    viewEditText        : undefined
};

// getters
const getters = {
    isEditTab           : state => state.isEditTab,
    tryToLeave          : state => state.tryToLeave,
    tryToLeaveTo        : state => state.tryToLeaveTo,
    viewEditLeaveAction : state => state.viewEditLeaveAction,
    viewEditText        : state => state.viewEditText
};

// actions
const actions = {

    // EDIT
    editTab({commit}, { edit }) {

        commit(Types.EDIT_TAB, edit);
    },
    tryToLeaveTabEdit({commit}, { to }) {

        commit(Types.SHOW_MESSAGE_LEAVE_TAB, to);
    },
    resetTryToLeave({commit}) {

        commit(Types.RESET_TRY_TO_LEAVE);
    },
    editAction({commit}, { viewEditLeaveAction }) {

        commit(Types.EDIT_ACTION, viewEditLeaveAction);
    },

    editEditText({commit}, { viewEditText }) {

        commit(Types.EDIT_TEXT, viewEditText);
    }
};

// mutations
const mutations = {
    [Types.EDIT_TAB](state, edit) {

        state.isEditTab = edit
    },
    [Types.SHOW_MESSAGE_LEAVE_TAB](state, to) {

        state.tryToLeave = true
        state.tryToLeaveTo = to
    },
    [Types.RESET_TRY_TO_LEAVE](state) {

        state.tryToLeave = false
        state.tryToLeaveTo = ''
        state.viewEditText = undefined
        state.viewEditLeaveAction = null

    },
    [Types.EDIT_ACTION](state, viewEditLeaveAction) {

        state.viewEditLeaveAction = viewEditLeaveAction
        state.tryToLeave = false
    },

    [Types.EDIT_TEXT](state, viewEditText) {

        state.viewEditText = viewEditText
    }


};

export default {
    state,
    getters,
    actions,
    mutations
};
