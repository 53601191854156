import Cookies         from 'js-cookie';
import Vue             from 'vue';
import VueI18n         from 'vue-i18n';
import elementEnLocale from '@pixul/element-ui/lib/locale/lang/en';
import elementFrLocale from '@pixul/element-ui/lib/locale/lang/fr';
import enJson          from './en.json';
import frJson          from './fr.json'

Vue.use(VueI18n);

const messages = {
    en : {
        ...enJson,
        ...elementEnLocale
    },
    fr : {
        ...frJson,
        ...elementFrLocale
    }
};



const i18n = new VueI18n({
    locale : Cookies.get('language') || 'en', // set locale
    messages // set locale messages
});


import moment from 'moment'
moment.locale(i18n.locale);

export default i18n;
