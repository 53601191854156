import { mapGetters } from 'vuex';

export default {
    computed : {
        ...mapGetters(['user', 'tenant', 'language', 'interfaceLanguageList'])
    },
    methods : {

        /**
         * return the translate indicator for a specific language
         */
        getIndicator(data, lang, translatableFields) {

            let nbTranslation = 0;

            if (translatableFields) {

                translatableFields.forEach((field) => {

                    if (lang in data[field] && data[field][lang] !== '') {
                        nbTranslation++;
                    }
                });
            }

            if (nbTranslation !== 0) {

                if (data.defaultLanguage === lang) {
                    return {name : 'check-circle',color : 'color:#70A3C6'};
                }

                return {name : 'check-circle',color : 'color:#E2E6E9'};
            }

            return {name : 'exclamation-circle',color : 'color:#DD1A32'};
        },
        formatDataInSpecificLanguage(data, lang, translatableFields) {

            translatableFields.forEach(field => {

                if (!data[field]) {

                    data[field] = {}
                }
            });

            const result = { ...data };

            translatableFields.forEach((tf) => {

                result[tf] = data[tf][lang];
            });

            return result;
        },
        /**
         * Return the field in the default language
         * @param {Translatable field} data
         */
        getFieldInDefaultLanguage(data, lang = this.language, others = this.interfaceLanguageList) {

            return this.getFieldInSpecificLanguageWithDefault(data, lang, others);
        },
        /**
         * Convert an object with translatable fields into a flat object with real values
         * @param {Object} data
         * @param {String} langValue Ex : 'fr'
         * @param {Array} translatableFields Ex : ['name', 'description']
         */
        getDataInSpecificLanguageWithDefault(data, langValue, translatableFields) {

            const result = { ...data };

            translatableFields.forEach((tf) => {

                result[tf] = this.getFieldInSpecificLanguageWithDefault(data[tf], langValue);
            });

            return result;

        },
        /**
         * Return the field in the specific language or in the default language
         * If it exist in specific language :
         *  Return the value
         * else
         *  We search the value set in default languages
         * @param {Translatable Field} field Ex : {'en' : 'House', 'fr' : 'Maison'}
         * @param {String} langValue Ex : 'fr'
         */
        getFieldInSpecificLanguageWithDefault(field, langValue, others = this.interfaceLanguageList) {

            // Specific language
            if (langValue in field && field[langValue] !== '' && field[langValue] !== undefined) {

                return field[langValue];
            }

            // Fallback values
            const lang = others.find((language) => {

                return field[language] && field[language] !== '' && field[language] !== undefined;
            });

            if (!field[lang]) {

                return;
            }

            return `${ field[lang] } (${ lang })`;
        },

        /**
         * Function that return languages list uses in this data
         * @param {*} data
         * @param {*} translatableFields
         */
        detectLanguagesWithData(data, translatableFields) {

            translatableFields.forEach(field => {

                if (!data[field]) {

                    data[field] = {}
                }
            });

            const languages = [];

            if (translatableFields) {
                translatableFields.forEach((field) => {

                    if (typeof data[field] === 'object') {
                        Object.keys(data[field]).forEach((key) => {

                            if (!(languages.includes(key))) {
                                languages.push(key);
                            }
                        });
                    }
                });
            }

            const sortedLanguages = this.tenant.options.languages.filter((l) => languages.includes(l));

            return sortedLanguages;
        }

    }
};
