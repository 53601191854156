import RBAC               from '@pixul/hestia-rbac';
import { fixedRouterMap } from '@/router';

const state = {
    menu_routes : {
        'default' : [],
        'admin'   : [],
        'simple'  : []
    },
    redirectURL : null
}

const getters  = {
    menu_routes : state => state.menu_routes,
    admin       : state => state.admin,
    redirectURL : state => state.redirectURL
}

const mutations = {
    SET_MENU_ROUTES : (state, { user, layout }) => {

        state.menu_routes[layout] = getAccessibleRoutes(fixedRouterMap, user, layout)
    },
    SET_REDIRECT_URL : (state, url) => {

        state.redirectURL = url
    }
}

const actions = {
    async generateMenu({commit}, { user, layout }) {

        commit('SET_MENU_ROUTES', { user, layout } );
    }
}

/**
 * Recursive function who detect if the route should be displayed in the menu or not dependind on layout and roles
 * @param {Array} routes List of all routes
 * @param {Object} user Loged in user
 * @param {String} layout Ex : default / admin / simple
 */
function getAccessibleRoutes(routes, user, layout) {


    return routes.reduce((acc, r) => {

        const { roles : route_roles, layout : route_layout } = r.meta;

        if (r.meta && r.meta.hidden) {
            return acc
        }

        // Si route pas du meme type on refuse ou s'il y a des roles mais que l'utilisateur n'a pas les droit -> on ignore
        if ((r.meta && (route_layout !== layout) || (route_roles && !RBAC.can(user.roles, route_roles)))) {
            return acc;
        }

        // Si pas d'enfant, on a fini
        if (!r.children) {
            return [...acc, r];
        }

        // On doit calculer recursivement les routes
        return [
            ...acc, {
                ...r,
                children : getAccessibleRoutes(r.children, user, layout)
            }
        ];
    }, []);

}

export default {
    state,
    getters,
    actions,
    mutations
};
