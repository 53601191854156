import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

// initial state
const state = {
    contacts : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentContact         : {},
    currentContactPrograms : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    }
};

// getters
const getters = {
    contacts               : state => state.contacts,
    currentContact         : state => state.currentContact,
    currentContactPrograms : state => state.currentContactPrograms
};

// actions
const actions = {
    // CONTACT
    async getContacts({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Commercialisation.contact.list(tenantId, {searchParams : query}).json();
        commit(Types.RECEIVE_CONTACTS, response);
    },
    async addContact({ commit }, { tenantId, contact }) {

        const response = await HestiaApi.Commercialisation.contact.create(tenantId, contact).json();
        commit(Types.ADD_CONTACT, response);
        return response;
    },
    async editContact({ commit }, { tenantId, id, contact }) {

        const response = await HestiaApi.Commercialisation.contact.update(tenantId, id, contact).json();

        commit(Types.EDIT_CONTACT, response);
    },
    async selectContact({ commit }, { tenantId, contactId }) {

        const response = await HestiaApi.Commercialisation.contact.get(tenantId, contactId).json();
        commit(Types.SELECT_CONTACT, response);
    },
    async removeContact({ commit }, { tenantId, contactId }) {

        await HestiaApi.Commercialisation.contact.delete(tenantId, contactId);
        commit(Types.REMOVE_CONTACT, contactId);
    },
    async deliveryDate({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Commercialisation.program.list(tenantId, query).json();
        commit(Types.RECEIVE_CONTACT_PROGRAMS, response);
    }

};

// mutations
const mutations = {
    [Types.RECEIVE_CONTACTS](state, contacts) {

        state.contacts = contacts;
    },
    [Types.ADD_CONTACT](state, contact) {

        state.contacts.results.push(contact);
    },
    [Types.SELECT_CONTACT](state, contact) {

        state.currentContact = contact;
    },
    [Types.EDIT_CONTACT](state, contact) {

        state.currentContact = contact;
    },
    [Types.REMOVE_CONTACT](state, contactId) {

        state.contacts.results.splice(contactId, 1);
    },
    [Types.RECEIVE_CONTACT_PROGRAMS](state, programs) {

        state.currentContactPrograms = programs;
    }

};

export default {
    state,
    getters,
    actions,
    mutations
};
