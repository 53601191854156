// initial state
import { cacheAction } from 'vuex-cache';
import HestiaApi       from '@/api';

const state = {
    communes                   : []
};

// getters
const getters = {
    communes                   : state => state.communes
};

const actions = {
    'getCommune' : cacheAction(({}, { tenantId, communeId, options}) => {

        return HestiaApi.Geo.commune.get(tenantId, communeId, options).json().then((commune) => {

            return commune;
        });
    })
}

export default {
    state,
    getters,
    actions
};
