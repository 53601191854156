import NProgress from 'nprogress';
import Router    from '@/router';
import Store     from '@/store';

NProgress.configure({ showSpinner : false });

/**
 * Permissions and progress bar
 */
Router.beforeEach(async (to, from, next) => {

    if (!to.meta.skipProgress) {
        NProgress.start();
    }

    if (Store.getters.user === false && ['default', 'admin'].includes(to.meta.layout)) {

        const tenant = Store.getters.tenant.id;
        if (!tenant) {

            next({
                name : 'login-without-tenant'
            });
            return;
        }

        Store.commit('SET_REDIRECT_URL',to)
        next({
            name   : 'login',
            params : {
                tenant : tenant
            }
        });
    }

    next();
});

Router.afterEach((to) => {

    if (to.meta.skipProgress) {
        return;
    }

    NProgress.done();
});
