import { confirmCloseDialog, confirmDelete, customConfirm, i18nMessage } from '@/utils/message';
import { convertDateToLocal, convertDateToUtc }                          from '@/utils/date';


const GlobalFunctionPlugin = {
    install(Vue) {

        Vue.prototype.$convertDateToUtc = convertDateToUtc;

        Vue.prototype.$convertDateToLocal = convertDateToLocal;

        Vue.prototype.$i18nMessage = i18nMessage;

        Vue.prototype.$customConfirm      = customConfirm;
        Vue.prototype.$confirmDelete      = confirmDelete;
        Vue.prototype.$confirmCloseDialog = confirmCloseDialog;

        Vue.capitalize = function (text) {

            return text.charAt(0).toUpperCase() + text.slice(1);
        };

        Vue.prototype.$lightOrDark = function (color, format = 'name') {

            // Variables for red, green, blue values
            let r,
                g,
                b;

            // Check the format of the color, HEX or RGB?
            if (color.match(/^rgb/)) {

                // If HEX --> store the red, green, blue values in separate variables
                color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

                r = color[1];
                g = color[2];
                b = color[3];
            }
            else {

                // If RGB --> Convert it to HEX: http://gist.github.com/983661
                color = +('0x' + color.slice(1).replace(
                    color.length < 5 && /./g, '$&$&'));

                r = color >> 16;
                g = color >> 8 & 255;
                b = color & 255;
            }

            // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
            const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

            // Using the HSP value, determine whether the color is light or dark
            if (hsp > 127.5) {

                return format === 'name' ? 'light' : '000000';
            }

            return format === 'name' ? 'dark' : 'FFFFFF';

        };

        Vue.prototype.$hashCode = function (str) {

            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }

            return hash;
        };

        Vue.prototype.$intToRGB = function (i) {

            const c = (i & 0x00FFFFFF)
                .toString(16)
                .toUpperCase();

            return '00000'.substring(0, 6 - c.length) + c;
        };

        Vue.prototype.$setPageTitle = function (title) {

            document.title = title;
        };
    }
};

export default GlobalFunctionPlugin;
