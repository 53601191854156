// APP
export const TOGGLE_SIDEBAR      = 'TOGGLE_SIDEBAR';
export const TOGGLE_SIDEBAR_MENU = 'TOGGLE_SIDEBAR_MENU';
export const CHANGE_LAYOUT       = 'CHANGE_LAYOUT';
export const SELECT_TAB          = 'SELECT_TAB';
export const UPDATE_LANGUAGE     = 'UPDATE_LANGUAGE';
export const TABLE_COLUMN_ADD    = 'TABLE_COLUMN_ADD';
export const TABLE_COLUMN_REMOVE = 'TABLE_COLUMN_REMOVE';
export const SWITCH_SAFEVIEW     = 'SWITCH_SAFEVIEW';
export const SWITCH_GLOBAL       = 'SWITCH_GLOBAL';

// AUTH
export const RECEIVE_TOKEN     = 'RECEIVE_TOKEN';
export const LOGOUT            = 'LOGOUT';
export const EDIT_CURRENT_USER = 'EDIT_CURRENT_USER';

// TENANT
export const RECEIVE_TENANT_INFOS = 'RECEIVE_TENANT_INFOS';
export const EDIT_TENANT          = 'EDIT_TENANT';
export const RECEIVE_PROVIDERS    = 'RECEIVE_PROVIDERS';
export const EDIT_PROVIDER        = 'EDIT_PROVIDER';
export const ADD_PROVIDER         = 'ADD_PROVIDER';

// VIEW
export const EDIT_TAB               = 'EDIT_TAB';
export const SHOW_MESSAGE_LEAVE_TAB = 'SHOW_MESSAGE_LEAVE_TAB';
export const RESET_TRY_TO_LEAVE     = 'RESET_TRY_TO_LEAVE';
export const EDIT_ACTION            = 'EDIT_ACTION';
export const EDIT_TEXT              = 'EDIT_TEXT';

// PROPERTIES
export const RECEIVE_PROPERTIES            = 'RECEIVE_PROPERTIES';
export const RECEIVE_PROPERTIES_MAP        = 'RECEIVE_PROPERTIES_MAP';
export const RECEIVE_PROPERTY_APPOINTMENTS = 'RECEIVE_PROPERTY_APPOINTMENTS';
export const RECEIVE_PROPERTY_ISSUES       = 'RECEIVE_PROPERTY_ISSUES';
export const RECEIVE_PROPERTY_OVERVIEW     = 'RECEIVE_PROPERTY_OVERVIEW';
export const RECEIVE_PROPERTY_FEEDS        = 'RECEIVE_PROPERTY_FEEDS';
export const BLACKLIST_PROPERTY_FEEDS      = 'BLACKLIST_PROPERTY_FEEDS';
export const RECEIVE_PROPERTY_MATCHES      = 'RECEIVE_PROPERTY_MATCHES';
export const CURRENT_CLIENT_UPDATED_FROM   = 'CURRENT_CLIENT_UPDATED_FROM';
export const RECEIVE_PROPERTY_REQUESTS     = 'RECEIVE_PROPERTY_REQUESTS';
export const ADD_PROPERTY                  = 'ADD_PROPERTY';
export const EDIT_PROPERTY                 = 'EDIT_PROPERTY';
export const REMOVE_PROPERTY               = 'REMOVE_PROPERTY';
export const PROPERTY_ADD_IMAGE            = 'PROPERTY_ADD_IMAGE';
export const ADD_PROPERTY_MANDATE          = 'ADD_PROPERTY_MANDATE';
export const REMOVE_PROPERTY_MANDATE       = 'REMOVE_PROPERTY_MANDATE';
export const SELECT_PROPERTY               = 'SELECT_PROPERTY';
export const EDIT_PROPERTY_IMAGE           = 'EDIT_PROPERTY_IMAGE';
export const REMOVE_PROPERTY_IMAGE         = 'REMOVE_PROPERTY_IMAGE';
export const ADD_PROPERTY_DOCUMENT         = 'ADD_PROPERTY_DOCUMENT';
export const EDIT_PROPERTY_DOCUMENT        = 'EDIT_PROPERTY_DOCUMENT';
export const DELETE_PROPERTY_DOCUMENT      = 'DELETE_PROPERTY_DOCUMENT';
export const ADD_PROPERTY_ISSUE            = 'ADD_PROPERTY_ISSUE';
export const REMOVE_PROPERTY_COLLABORATOR  = 'REMOVE_PROPERTY_COLLABORATOR';
export const REMOVE_PROPERTY_COOWNER       = 'REMOVE_PROPERTY_COOWNER';
export const INIT_PROPERTY_COUNTS          = 'INIT_PROPERTY_COUNTS';
export const UPDATE_PROPERTY_COUNTS        = 'UPDATE_PROPERTY_COUNTS';
export const ADD_PROPERTY_APPOINTMENT      = 'ADD_PROPERTY_APPOINTMENT';
export const ADD_PROPERTY_OFFER            = 'ADD_PROPERTY_OFFER';
export const RECOUNT_PROPERTY_FEED         = 'RECOUNT_PROPERTY_FEED';
export const CHANGE_PROPERTY_HISTORY_FROM  = 'CHANGE_PROPERTY_HISTORY_FROM';
export const SELECT_PROPERTY_MEDIA_TAB     = 'SELECT_PROPERTY_MEDIA_TAB';

// FEATURES
export const RECEIVE_PROPERTY_FEATURES   = 'RECEIVE_PROPERTY_FEATURES';
export const ADD_PROPERTY_FEATURE        = 'ADD_PROPERTY_FEATURE';
export const EDIT_PROPERTY_FEATURE       = 'EDIT_PROPERTY_FEATURE';
export const EDIT_PROPERTY_FEATURE_ORDER = 'EDIT_PROPERTY_FEATURE_ORDER';
export const REMOVE_PROPERTY_FEATURE     = 'REMOVE_PROPERTY_FEATURE';

// CATEGORIES
export const RECEIVE_PROPERTY_CATEGORIES  = 'RECEIVE_PROPERTY_CATEGORIES';
export const ADD_PROPERTY_CATEGORY        = 'ADD_PROPERTY_CATEGORY';
export const EDIT_PROPERTY_CATEGORY       = 'EDIT_PROPERTY_CATEGORY';
export const EDIT_PROPERTY_CATEGORY_ORDER = 'EDIT_PROPERTY_CATEGORY_ORDER';
export const REMOVE_PROPERTY_CATEGORY     = 'REMOVE_PROPERTY_CATEGORY';

// TYPES
export const RECEIVE_PROPERTY_TYPES   = 'RECEIVE_PROPERTY_TYPES';
export const ADD_PROPERTY_TYPE        = 'ADD_PROPERTY_TYPE';
export const EDIT_PROPERTY_TYPE       = 'EDIT_PROPERTY_TYPE';
export const EDIT_PROPERTY_TYPE_ORDER = 'EDIT_PROPERTY_TYPE_ORDER';
export const REMOVE_PROPERTY_TYPE     = 'REMOVE_PROPERTY_TYPE';

//TYPES
export const RECEIVE_PROPERTY_DOCUMENT_TYPES = 'RECEIVE_PROPERTY_DOCUMENT_TYPES';
export const ADD_PROPERTY_DOCUMENT_TYPE      = 'ADD_PROPERTY_DOCUMENT_TYPE';
export const EDIT_PROPERTY_DOCUMENT_TYPE     = 'EDIT_PROPERTY_DOCUMENT_TYPE';
export const REMOVE_PROPERTY_DOCUMENT_TYPE   = 'REMOVE_PROPERTY_DOCUMENT_TYPE';

// MEDIA
export const ADD_PROPERTY_MEDIUM    = 'ADD_PROPERTY_MEDIUM';
export const EDIT_PROPERTY_MEDIUM   = 'EDIT_PROPERTY_MEDIUM';
export const REMOVE_PROPERTY_MEDIUM = 'REMOVE_PROPERTY_MEDIUM';

//OFFER
export const RECEIVE_PROPERTY_OFFERS = 'RECEIVE_PROPERTY_OFFERS';
export const ADD_OFFER               = 'ADD_OFFER';
export const EDIT_OFFER              = 'EDIT_OFFER';
export const RECEIVE_OFFERS          = 'RECEIVE_OFFERS';

//BROCHURE
export const RECEIVE_BROCHURES          = 'RECEIVE_BROCHURES';
export const RECEIVE_PROPERTY_BROCHURES = 'RECEIVE_PROPERTY_BROCHURES';
export const RECEIVE_PROPERTY_BROCHURE  = 'RECEIVE_PROPERTY_BROCHURE';
export const ADD_PROPERTY_BROCHURE      = 'ADD_PROPERTY_BROCHURE';
export const ADD_BROCHURE               = 'ADD_BROCHURE';
export const REMOVE_BROCHURE            = 'REMOVE_BROCHURE';
export const EDIT_PROPERTY_BROCHURE     = 'EDIT_PROPERTY_BROCHURE';

//SOURCE
export const RECEIVE_PROPERTY_SOURCES = 'RECEIVE_PROPERTY_SOURCES';
export const ADD_PROPERTY_SOURCE      = 'ADD_PROPERTY_SOURCE';
export const EDIT_PROPERTY_SOURCE     = 'EDIT_PROPERTY_SOURCE';
export const REMOVE_PROPERTY_SOURCE   = 'REMOVE_PROPERTY_SOURCE';

//SOURCE
export const RECEIVE_PUBLICATIONS          = 'RECEIVE_PUBLICATIONS';
export const RECEIVE_PROPERTY_PUBLICATIONS = 'RECEIVE_PROPERTY_PUBLICATIONS';
export const RECEIVE_PROPERTY_PUBLICATION  = 'RECEIVE_PROPERTY_PUBLICATION';
export const ADD_PROPERTY_PUBLICATION      = 'ADD_PROPERTY_PUBLICATION';
export const REMOVE_PROPERTY_PUBLICATION   = 'REMOVE_PROPERTY_PUBLICATION';
export const EDIT_PROPERTY_PUBLICATION     = 'EDIT_PROPERTY_PUBLICATION';

// MANDATE
export const ADD_MANDATE                 = 'ADD_MANDATE';
export const RECEIVE_MANDATES            = 'RECEIVE_MANDATES';
export const RECEIVE_MANDATE_AVENANTS    = 'RECEIVE_MANDATE_AVENANTS';
export const RECEIVE_MANDATE_PROPERTIES  = 'RECEIVE_MANDATE_PROPERTIES';
export const ADD_MANDATE_PROPERTY        = 'ADD_MANDATE_PROPERTY';
export const REMOVE_MANDATE_PROPERTY     = 'REMOVE_MANDATE_PROPERTY';
export const RECEIVE_MANDATE_ISSUES      = 'RECEIVE_MANDATE_ISSUES';
export const EDIT_MANDATE                = 'EDIT_MANDATE';
export const REMOVE_MANDATE              = 'REMOVE_MANDATE';
export const GET_MANDATE_TEMPLATE        = 'GET_MANDATE_TEMPLATE';
export const RESET_MANDATE_TEMPLATE      = 'RESET_MANDATE_TEMPLATE';
export const OVERRIDE_MANDATE_TEMPLATE   = 'OVERRIDE_MANDATE_TEMPLATE';
export const RECEIVE_MANDATE_TYPES       = 'RECEIVE_MANDATE_TYPES';
export const ADD_MANDATE_TYPE            = 'ADD_MANDATE_TYPE';
export const EDIT_MANDATE_TYPE           = 'EDIT_MANDATE_TYPE';
export const MANDATE_ADD_DOCUMENT        = 'MANDATE_ADD_DOCUMENT';
export const MANDATE_EDIT_DOCUMENT       = 'MANDATE_UPDATE_DOCUMENT';
export const MANDATE_DELETE_DOCUMENT     = 'MANDATE_DELETE_DOCUMENT';
export const ADD_MANDATE_ISSUE           = 'ADD_MANDATE_ISSUE';
export const EDIT_MANDATE_PROPERTY       = 'EDIT_MANDATE_PROPERTY';
export const REMOVE_MANDATE_COLLABORATOR = 'REMOVE_MANDATE_COLLABORATOR';
export const REMOVE_MANDATE_COOWNER      = 'REMOVE_MANDATE_COOWNER';
export const VOID_EMANDATE               = 'VOID_EMANDATE';
export const CREATE_EMANDATE             = 'CREATE_EMANDATE';
export const RESEND_EMANDATE             = 'RESEND_EMANDATE';
export const RECEIVE_EMANDATE            = 'RECEIVE_EMANDATE';
export const ADD_EMANDATE                = 'ADD_EMANDATE';
export const EDIT_EMANDATE               = 'EDIT_EMANDATE';
export const REMOVE_EMANDATE             = 'REMOVE_EMANDATE';
export const RECEIVE_EAVENANT            = 'RECEIVE_EAVENANT';
export const EDIT_EAVENANT               = 'EDIT_EAVENANT';
export const REMOVE_EAVENANT             = 'REMOVE_EAVENANT';
export const VOID_EAVENANT               = 'VOID_EAVENANT';
export const CREATE_EAVENANT             = 'CREATE_EAVENANT';
export const RESEND_EAVENANT             = 'RESEND_EAVENANT';
export const INIT_MANDATE_COUNTS         = 'INIT_MANDATE_COUNTS';
export const EDIT_MANDATE_TYPE_ORDER     = 'EDIT_MANDATE_TYPE_ORDER';
export const RECEIVE_REGISTRY_MANDATES   = 'RECEIVE_REGISTRY_MANDATES';

// SOURCES
export const RECEIVE_MANDATE_SOURCES   = 'RECEIVE_MANDATE_SOURCES';
export const ADD_MANDATE_SOURCE        = 'ADD_MANDATE_SOURCE';
export const EDIT_MANDATE_SOURCE       = 'EDIT_MANDATE_SOURCE';
export const EDIT_MANDATE_SOURCE_ORDER = 'EDIT_MANDATE_SOURCE_ORDER';
export const REMOVE_MANDATE_SOURCE     = 'REMOVE_MANDATE_SOURCE';
export const UPDATE_MANDATE_SOURCE     = 'UPDATE_MANDATE_SOURCE';

//AVENANT
export const RECEIVE_AVENANTS      = 'RECEIVE_AVENANTS';
export const ADD_AVENANT           = 'ADD_AVENANT';
export const ADD_EAVENANT          = 'ADD_EAVENANT';
export const EDIT_AVENANT          = 'EDIT_AVENANT';
export const REMOVE_AVENANT        = 'REMOVE_AVENANT';
export const SELECT_MANDATE        = 'SELECT_MANDATE';
export const MANDATE_ADD_CLIENT    = 'MANDATE_ADD_CLIENT';
export const MANDATE_REMOVE_CLIENT = 'MANDATE_REMOVE_CLIENT';

//TYPES
export const RECEIVE_MANDATE_DOCUMENT_TYPES = 'RECEIVE_MANDATE_DOCUMENT_TYPES';
export const ADD_MANDATE_DOCUMENT_TYPE      = 'ADD_MANDATE_DOCUMENT_TYPE';
export const EDIT_MANDATE_DOCUMENT_TYPE     = 'EDIT_MANDATE_DOCUMENT_TYPE';
export const REMOVE_MANDATE_DOCUMENT_TYPE   = 'REMOVE_MANDATE_DOCUMENT_TYPE';

// CLIENTS
export const RECEIVE_CLIENTS                = 'RECEIVE_CLIENTS';
export const RECEIVE_SIMILAR_CLIENTS        = 'RECEIVE_SIMILAR_CLIENTS';
export const ADD_CLIENT                     = 'ADD_CLIENT';
export const EDIT_CLIENT                    = 'EDIT_CLIENT';
export const REMOVE_CLIENT                  = 'REMOVE_CLIENT';
export const RECEIVE_CLIENT_MANDATES        = 'RECEIVE_CLIENT_MANDATES';
export const RECEIVE_CLIENT_APPOINTMENTS    = 'RECEIVE_CLIENT_APPOINTMENTS';
export const EDIT_CLIENT_APPOINTMENT_STATUS = 'EDIT_CLIENT_APPOINTMENT_STATUS';
export const ADD_CLIENT_EMAIL               = 'ADD_CLIENT_EMAIL';
export const EDIT_CLIENT_EMAIL              = 'EDIT_CLIENT_EMAIL';
export const DELETE_CLIENT_EMAIL            = 'DELETE_CLIENT_EMAIL';
export const ADD_CLIENT_PHONE               = 'ADD_CLIENT_PHONE';
export const EDIT_CLIENT_PHONE              = 'EDIT_CLIENT_PHONE';
export const DELETE_CLIENT_PHONE            = 'DELETE_CLIENT_PHONE';
export const ADD_CLIENT_SEARCH              = 'ADD_CLIENT_SEARCH';
export const EDIT_CLIENT_SEARCH             = 'EDIT_CLIENT_SEARCH';
export const EDIT_CLIENT_SEARCH_DEFAULT     = 'EDIT_CLIENT_SEARCH_DEFAULT';
export const SELECT_CLIENT_SEARCH           = 'SELECT_CLIENT_SEARCH';
export const UNSELECT_CLIENT_SEARCH         = 'UNSELECT_CLIENT_SEARCH';
export const DELETE_CLIENT_SEARCH           = 'DELETE_CLIENT_SEARCH';
export const SELECT_CLIENT                  = 'SELECT_CLIENT';
export const CLIENT_ADD_DOCUMENT            = 'CLIENT_ADD_DOCUMENT';
export const CLIENT_EDIT_DOCUMENT           = 'CLIENT_EDIT_DOCUMENT';
export const CLIENT_DELETE_DOCUMENT         = 'CLIENT_DELETE_DOCUMENT';
export const REMOVE_CLIENT_COLLABORATOR     = 'REMOVE_CLIENT_COLLABORATOR';
export const REMOVE_CLIENT_COOWNER          = 'REMOVE_CLIENT_COOWNER';
export const ADD_CLIENT_RELATION            = 'ADD_CLIENT_RELATION';
export const REMOVE_CLIENT_RELATION         = 'REMOVE_CLIENT_RELATION';
export const RECEIVE_CLIENT_DISCUSSIONS     = 'RECEIVE_CLIENT_DISCUSSIONS';
export const ADD_CLIENT_DISCUSSION          = 'ADD_CLIENT_DISCUSSION';
export const RECEIVE_CLIENT_ISSUES          = 'RECEIVE_CLIENT_ISSUES';
export const ADD_CLIENT_ISSUE               = 'ADD_CLIENT_ISSUE';
export const ADD_CLIENT_OFFER               = 'ADD_CLIENT_OFFER';
export const EDIT_CLIENT_OFFER              = 'EDIT_CLIENT_OFFER';
export const ADD_CLIENT_APPOINTMENT         = 'ADD_CLIENT_APPOINTMENT';
export const EDIT_CLIENT_APPOINTMENT        = 'EDIT_CLIENT_APPOINTMENT';
export const REMOVE_CLIENT_APPOINTMENT      = 'REMOVE_CLIENT_APPOINTMENT';
export const INIT_CLIENT_COUNTS             = 'INIT_CLIENT_COUNTS';
export const RECEIVE_CLIENT_COMPANIES       = 'RECEIVE_CLIENT_COMPANIES';
export const RECEIVE_CLIENT_MATCHES         = 'RECEIVE_CLIENT_MATCHES';
export const ADD_THREAD_CLIENT_APPOINTMENT  = 'ADD_THREAD_CLIENT_APPOINTMENT';
export const RECEIVE_CLIENT_FLAGS           = 'RECEIVE_CLIENT_FLAGS';
export const ADD_FLAG                       = 'ADD_FLAG';
export const DELETE_FLAG                    = 'DELETE_FLAG';
export const EDIT_FLAG                      = 'EDIT_FLAG';
export const RECEIVE_CLIENTS_FAVORITE       = 'RECEIVE_CLIENTS_FAVORITE';
export const ADD_CLIENT_FAVORITE            = 'ADD_CLIENT_FAVORITE';
export const REMOVE_CLIENT_FAVORITE         = 'RECEIVE_CLIENT_FAVORITE';
export const ADD_MAILING_TEMPLATE           = 'ADD_MAILING_TEMPLATE';
export const RECEIVE_MAILING_TEMPLATES      = 'RECEIVE_MAILING_TEMPLATES';
export const EDIT_MAILING_TEMPLATE          = 'EDIT_MAILING_TEMPLATE';

//TYPES
export const RECEIVE_CLIENT_DOCUMENT_TYPES = 'RECEIVE_CLIENT_DOCUMENT_TYPES';
export const ADD_CLIENT_DOCUMENT_TYPE      = 'ADD_CLIENT_DOCUMENT_TYPE';
export const EDIT_CLIENT_DOCUMENT_TYPE     = 'EDIT_CLIENT_DOCUMENT_TYPE';
export const REMOVE_CLIENT_DOCUMENT_TYPE   = 'REMOVE_CLIENT_DOCUMENT_TYPE';

//TYPES Company
export const RECEIVE_COMPANY_DOCUMENT_TYPES = 'RECEIVE_COMPANY_DOCUMENT_TYPES';
export const ADD_COMPANY_DOCUMENT_TYPE      = 'ADD_COMPANY_DOCUMENT_TYPE';
export const EDIT_COMPANY_DOCUMENT_TYPE     = 'EDIT_COMPANY_DOCUMENT_TYPE';
export const REMOVE_COMPANY_DOCUMENT_TYPE   = 'REMOVE_COMPANY_DOCUMENT_TYPE';

// ISSUES
export const RECEIVE_THREADS                  = 'RECEIVE_THREADS';
export const SELECT_THREAD                    = 'SELECT_THREAD';
export const ADD_THREAD                       = 'ADD_THREAD';
export const EDIT_THREAD                      = 'EDIT_THREAD';
export const OPEN_THREAD                      = 'OPEN_THREAD';
export const CLOSE_THREAD                     = 'CLOSE_THREAD';
export const REMOVE_THREAD                    = 'REMOVE_THREAD';
export const RECEIVE_THREAD_LABELS            = 'RECEIVE_THREAD_LABELS';
export const ADD_THREAD_LABEL                 = 'ADD_THREAD_LABEL';
export const EDIT_THREAD_LABEL                = 'EDIT_THREAD_LABEL';
export const RECEIVE_THREAD_TEMPLATES         = 'RECEIVE_THREAD_TEMPLATES';
export const ADD_THREAD_TEMPLATE              = 'ADD_THREAD_TEMPLATE';
export const EDIT_THREAD_TEMPLATE             = 'EDIT_THREAD_TEMPLATE';
export const DELETE_THREAD_TEMPLATE           = 'DELETE_THREAD_TEMPLATE';
export const EDIT_READ_MULTIPLE_THREAD        = 'EDIT_READ_MULTIPLE_THREAD';
export const RECEIVE_THREAD_TEMPLATE_FAVORITE = 'RECEIVE_THREAD_TEMPLATE_FAVORITE';
export const ADD_THREAD_TEMPLATE_FAVORITE     = 'ADD_THREAD_TEMPLATE_FAVORITE';
export const REMOVE_THREAD_TEMPLATE_FAVORITE  = 'REMOVE_THREAD_TEMPLATE_FAVORITE';

// COMPANIES
export const RECEIVE_COMPANIES           = 'RECEIVE_COMPANIES';
export const ADD_COMPANY                 = 'ADD_COMPANY';
export const EDIT_COMPANY                = 'EDIT_COMPANY';
export const REMOVE_COMPANY              = 'REMOVE_COMPANY';
export const SELECT_COMPANY              = 'SELECT_COMPANY';
export const ADD_USER_COMPANY            = 'ADD_USER_COMPANY';
export const EDIT_USER_COMPANY           = 'EDIT_USER_COMPANY';
export const RECEIVE_COMPANY_MANDATES    = 'RECEIVE_COMPANY_MANDATES';
export const REMOVE_COMPANY_COLLABORATOR = 'REMOVE_COMPANY_COLLABORATOR';
export const REMOVE_COMPANY_COOWNER      = 'REMOVE_COMPANY_COOWNER';
export const RECEIVE_COMPANY_ISSUES      = 'RECEIVE_COMPANY_ISSUES';
export const ADD_COMPANY_ISSUE           = 'ADD_COMPANY_ISSUE';
export const RECEIVE_COMPANY_CLIENTS     = 'RECEIVE_COMPANY_CLIENTS';
export const ADD_COMPANY_CLIENT          = 'ADD_COMPANY_CLIENT';
export const REMOVE_COMPANY_CLIENT       = 'REMOVE_COMPANY_CLIENT';
export const EDIT_COMPANY_CLIENT         = 'EDIT_COMPANY_CLIENT';
export const EDIT_COMPANY_CLIENT_ORDER   = 'EDIT_COMPANY_CLIENT_ORDER';
export const INIT_COMPANY_COUNTS         = 'INIT_COMPANY_COUNTS';
export const COMPANY_ADD_DOCUMENT        = 'COMPANY_ADD_DOCUMENT';
export const COMPANY_EDIT_DOCUMENT       = 'COMPANY_EDIT_DOCUMENT';
export const COMPANY_DELETE_DOCUMENT     = 'COMPANY_DELETE_DOCUMENT';

// COMMISSIONS
export const RECEIVE_COMMISSIONS = 'RECEIVE_COMMISSIONS';
export const EDIT_COMMISSION     = 'EDIT_COMMISSION';

// USERS
export const RECEIVE_USERS        = 'RECEIVE_USERS';
export const ADD_USER             = 'ADD_USER';
export const EDIT_USER            = 'EDIT_USER';
export const REMOVE_USER          = 'REMOVE_USER';
export const RECEIVE_GROUPS       = 'RECEIVE_GROUPS';
export const ADD_USER_PHONE       = 'ADD_PHONE_USER';
export const EDIT_USER_PHONE      = 'EDIT_PHONE_USER';
export const DELETE_USER_PHONE    = 'DELETE_PHONE_USER';
export const SELECT_USER          = 'SELECT_USER';
export const ADD_GROUP            = 'ADD_GROUP';
export const EDIT_GROUP           = 'EDIT_GROUP';
export const REMOVE_GROUP         = 'REMOVE_GROUP';
export const RECEIVE_AGENTS       = 'RECEIVE_AGENTS';
export const RECEIVE_AGENTS_MAP   = 'RECEIVE_AGENTS_MAP';
export const RECEIVE_USER_COMMENT = 'RECEIVE_USER_COMMENT';
export const ADD_USER_COMMENT     = 'ADD_USER_COMMENT';
export const EDIT_USER_COMMENT    = 'EDIT_USER_COMMENT';
export const DELETE_USER_COMMENT  = 'DELETE_USER_COMMENT';

// BOOKMARKS
export const RECEIVE_BOOKMARK  = 'RECEIVE_BOOKMARKS';
export const RECEIVE_BOOKMARKS = 'RECEIVE_BOOKMARKS';
export const CLEAR_BOOKMARKS   = 'CLEAR_BOOKMARKS';
export const ADD_BOOKMARK      = 'ADD_BOOKMARK';
export const EDIT_BOOKMARK     = 'EDIT_BOOKMARK';
export const REMOVE_BOOKMARK   = 'REMOVE_BOOKMARK';
export const SELECT_BOOKMARK   = 'SELECT_BOOKMARK';
export const UNSELECT_BOOKMARK = 'UNSELECT_BOOKMARK';

//REQUEST
export const RECEIVE_REQUEST_SOURCES  = 'RECEIVE_REQUEST_SOURCES';
export const ADD_REQUEST_SOURCE       = 'ADD_REQUEST_SOURCE';
export const EDIT_REQUEST_SOURCE      = 'EDIT_REQUEST_SOURCE';
export const REMOVE_REQUEST_SOURCE    = 'REMOVE_REQUEST_SOURCE';
export const RECEIVE_REQUEST_INBOUNDS = 'RECEIVE_REQUEST_INBOUNDS';
export const ADD_REQUEST_INBOUND      = 'ADD_REQUEST_INBOUND';
export const EDIT_REQUEST_INBOUND     = 'EDIT_REQUEST_INBOUND';
export const REMOVE_REQUEST_INBOUND   = 'REMOVE_REQUEST_INBOUND';
export const RECEIVE_REQUESTS         = 'RECEIVE_REQUESTS';
export const RECEIVE_SIMILAR_REQUESTS = 'RECEIVE_SIMILAR_REQUESTS';
export const ADD_REQUEST              = 'ADD_REQUEST';
export const EDIT_REQUEST             = 'EDIT_REQUEST';
export const RECEIVE_REQUEST_RECEIVED = 'RECEIVE_REQUEST_RECEIVED';

// CALENDARS
export const RECEIVE_CALENDARS     = 'RECEIVE_CALENDARS';
export const ADD_CALENDAR          = 'ADD_CALENDAR';
export const EDIT_CALENDAR         = 'EDIT_CALENDAR';
export const REMOVE_CALENDAR       = 'REMOVE_CALENDAR';
export const SHOW_CALENDAR         = 'SHOW_CALENDAR';
export const HIDE_CALENDAR         = 'HIDE_CALENDAR';
export const RECEIVE_OWN_CALENDARS = 'RECEIVE_OWN_CALENDARS';

// EVENTS
export const RECEIVE_EVENTS = 'RECEIVE_EVENTS';
export const ADD_EVENT      = 'ADD_EVENT';
export const EDIT_EVENT     = 'EDIT_EVENT';
export const REMOVE_EVENT   = 'REMOVE_EVENT';
export const SELECT_EVENT   = 'SELECT_EVENT';
export const UNSELECT_EVENT = 'UNSELECT_EVENT';

// APPOINTMENTS
export const RECEIVE_APPOINTMENTS     = 'RECEIVE_APPOINTMENTS';
export const ADD_APPOINTMENT          = 'ADD_APPOINTMENT';
export const EDIT_APPOINTMENT         = 'EDIT_APPOINTMENT';
export const EDIT_APPOINTMENT_STATUS = 'EDIT_APPOINTMENT_STATUS';
export const EDIT_APPOINTMENT_CLIENT  = 'EDIT_APPOINTMENT_CLIENT';
export const REMOVE_APPOINTMENT       = 'REMOVE_APPOINTMENT';
export const SELECT_APPOINTMENT       = 'SELECT_APPOINTMENT';
export const EDIT_STATUS_APPOINTMENT  = 'EDIT_STATUS_APPOINTMENT';
export const RECEIVE_SEND_APPOINTMENT = 'RECEIVE_SEND_APPOINTMENT';
export const ADD_SEND_APPOINTMENT     = 'ADD_SEND_APPOINTMENT';
export const REMOVE_SEND_APPOINTMENT  = 'REMOVE_SEND_APPOINTMENT';
export const EDIT_SEND_APPOINTMENT    = 'EDIT_SEND_APPOINTMENT';
export const ADD_THREAD_APPOINTMENT   = 'ADD_THREAD_APPOINTMENT';
export const EDIT_APPOINTMENT_OWNER   = 'EDIT_APPOINTMENT_OWNER';

//TENANT
export const RECEIVE_TENANT       = 'RECEIVE_TENANT';
export const ADD_TENANT           = 'ADD_TENANT';
export const RECEIVE_AGENCIES     = 'RECEIVE_AGENCIES';
export const RECEIVE_AGENCIES_MAP = 'RECEIVE_AGENCIES_MAP';
export const ADD_AGENCY           = 'ADD_AGENCY';
export const EDIT_AGENCY          = 'EDIT_AGENCY';
export const REMOVE_AGENCY        = 'REMOVE_AGENCY';

// QUERY
export const UPDATE_QUERY            = 'UPDATE_QUERY';
export const CLEAR_QUERY             = 'CLEAR_QUERY';
export const UPDATE_QUERY_SEARCH     = 'UPDATE_QUERY_SEARCH';
export const REMOVE_QUERY_SEARCH     = 'REMOVE_QUERY_SEARCH';
export const UPDATE_QUERY_SORT       = 'UPDATE_QUERY_SORT';
export const UPDATE_QUERY_PAGINATION = 'UPDATE_QUERY_PAGINATION';

// FILTER
export const UPDATE_FILTERS_MAX = 'UPDATE_FILTERS_MAX';

// ACCESS
export const ADD_ACCESS      = 'ADD_ACCESS';
export const RECEIVE_ACCESSS = 'RECEIVE_ACCESSS';
export const ANSWER_ACCESS   = 'ANSWER_ACCESS';

// CLIENT COMMERCIALISATION
export const ADD_COMMERCIALISATION_CLIENT      = 'ADD_COMMERCIALISATION_CLIENT';
export const EDIT_COMMERCIALISATION_CLIENT     = 'EDIT_COMMERCIALISATION_CLIENT';
export const RECEIVE_COMMERCIALISATION_CLIENTS = 'RECEIVE_COMMERCIALISATION_CLIENTS';
export const REMOVE_COMMERCIALISATION_CLIENT   = 'REMOVE_COMMERCIALISATION_CLIENT';

// DEVELOPPER COMMERCIALISATION
export const RECEIVE_DEVELOPERS                = 'RECEIVE_DEVELOPERS';
export const ADD_DEVELOPER                     = 'ADD_DEVELOPER';
export const EDIT_DEVELOPER                    = 'EDIT_DEVELOPER';
export const RECEIVE_DEVELOPER                 = 'RECEIVE_DEVELOPER';
export const RECEIVE_DEVELOPER_LOGIN           = 'RECEIVE_DEVELOPER_LOGIN';
export const ADD_DEVELOPER_LOGIN               = 'ADD_DEVELOPER_LOGIN';
export const RECEIVE_DEVELOPER_MANDATES        = 'RECEIVE_DEVELOPER_MANDATES';
export const RECEIVE_DEVELOPER_BRANCHES        = 'RECEIVE_DEVELOPER_BRANCHES';
export const RECEIVE_DEVELOPER_BRANCH_CONTACTS = 'RECEIVE_DEVELOPER_BRANCH_CONTACTS';
export const DELETE_DEVELOPER_CONTACT          = 'DELETE_DEVELOPER_CONTACT';

// PROGRAM COMMERCIALISATION
export const RECEIVE_PROGRAMS              = 'RECEIVE_PROGRAMS';
export const ADD_PROGRAM                   = 'ADD_PROGRAM';
export const EDIT_PROGRAM                  = 'EDIT_PROGRAM';
export const RECEIVE_PROGRAM               = 'RECEIVE_PROGRAM';
export const PROGRAM_ADD_DOCUMENT          = 'PROGRAM_ADD_DOCUMENT';
export const PROGRAM_DELETE_DOCUMENT       = 'PROGRAM_DELETE_DOCUMENT';
export const ADD_PROGRAM_IMAGE             = 'ADD_PROGRAM_IMAGE';
export const REMOVE_PROGRAM_IMAGE          = 'REMOVE_PROGRAM_IMAGE';
export const PROGRAM_ADD_ELIGIBILITY       = 'PROGRAM_ADD_ELIGIBILITY';
export const PROGRAM_DELETE_ELIGIBILITY    = 'PROGRAM_DELETE_ELIGIBILITY';
export const RECEIVE_PROGRAM_LOTS          = 'RECEIVE_PROGRAM_LOTS';
export const ADD_PROGRAM_LOT               = 'ADD_PROGRAM_LOT';
export const EDIT_PROGRAM_LOT              = 'EDIT_PROGRAM_LOT';
export const REMOVE_PROGRAM_COLLABORATOR   = 'REMOVE_PROGRAM_COLLABORATOR';
export const REMOVE_PROGRAM_COOWNER        = 'REMOVE_PROGRAM_COOWNER';
export const REMOVE_DEVELOPER_COLLABORATOR = 'REMOVE_DEVELOPER_COLLABORATOR';
export const REMOVE_DEVELOPER_COOWNER      = 'REMOVE_DEVELOPER_COOWNER';
export const REMOVE_LOT_COLLABORATOR       = 'REMOVE_LOT_COLLABORATOR';
export const REMOVE_LOT_COOWNER            = 'REMOVE_LOT_COOWNER';
export const EDIT_LOT                      = 'EDIT_LOT';

// LOT COMMERCIALISATION
export const RECEIVE_LOTS = 'RECEIVE_LOTS';

//CONTACT COMMERCIALISATION
export const RECEIVE_CONTACTS = 'RECEIVE_CONTACTS';
export const ADD_CONTACT      = 'ADD_CONTACT';
export const EDIT_CONTACT     = 'EDIT_CONTACT';
export const SELECT_CONTACT   = 'SELECT_CONTACT';
export const REMOVE_CONTACT   = 'REMOVE_CONTACT';

//BRANCH COMMERCIALISATION
export const RECEIVE_BRANCHES         = 'RECEIVE_BRANCHES';
export const ADD_BRANCH               = 'ADD_BRANCH';
export const EDIT_BRANCH              = 'EDIT_BRANCH';
export const RECEIVE_BRANCH           = 'RECEIVE_BRANCH';
export const REMOVE_BRANCH            = 'REMOVE_BRANCH';
export const ADD_BRANCH_CONTACT       = 'ADD_BRANCH_CONTACT';
export const BRANCH_DELETE_CONTACT    = 'BRANCH_DELETE_CONTACT';
export const RECEIVE_CONTACT_PROGRAMS = 'RECEIVE_CONTACT_PROGRAMS';

//ELIGIBILITY COMMERCIALISATION
export const RECEIVE_ELIGIBILITIES = 'RECEIVE_ELIGIBILITIES';
export const ADD_ELIGIBILITY       = 'ADD_ELIGIBILITY';
export const EDIT_ELIGIBILITY      = 'EDIT_ELIGIBILITY';
export const RECEIVE_ELIGIBILITY   = 'RECEIVE_ELIGIBILITY';
export const SELECT_ELIGIBILITY    = 'SELECT_ELIGIBILITY';
export const REMOVE_ELIGIBILITY    = 'REMOVE_ELIGIBILITY';

//MANDATE COMMERCIALISATION
export const RECEIVE_MANDATESCO            = 'RECEIVE_MANDATESCO';
export const ADD_MANDATECO                 = 'ADD_MANDATECO';
export const EDIT_MANDATECO                = 'EDIT_MANDATECO';
export const SELECT_MANDATE_CO             = 'SELECT_MANDATE_CO';
export const ADD_MANDATECO_AVENANT         = 'ADD_MANDATECO_AVENANT';
export const REMOVE_MANDATECO_AVENANT      = 'REMOVE_MANDATECO_AVENANT';
export const RECEIVE_MANDATECO_PROGRAMS    = 'RECEIVE_MANDATECO_PROGRAMS';
export const REMOVE_MANDATECO_COLLABORATOR = 'REMOVE_MANDATECO_COLLABORATOR';
export const REMOVE_MANDATECO_COOWNER      = 'REMOVE_MANDATECO_COOWNER';

//IMPORTER
export const RECEIVE_IMPORTERS = 'RECEIVE_IMPORTERS';
export const ADD_IMPORTER      = 'ADD_IMPORTER';
export const EDIT_IMPORTER     = 'EDIT_IMPORTER';

//PARTNER
export const RECEIVE_PARTNERS            = 'RECEIVE_PARTNERS';
export const ADD_PARTNER                 = 'ADD_PARTNER';
export const SELECT_PARTNER              = 'SELECT_PARTNER';
export const EDIT_PARTNER                = 'EDIT_PARTNER';
export const ADD_PARTNER_COOWNER         = 'ADD_PARTNER_COOWNER';
export const REMOVE_PARTNER_COOWNER      = 'REMOVE_PARTNER_COOWNER';
export const EDIT_PARTNER_DOCUMENT       = 'EDIT_PARTNER_DOCUMENT';
export const ADD_PARTNER_COLLABORATOR    = 'ADD_PARTNER_COLLABORATOR';
export const REMOVE_PARTNER_COLLABORATOR = 'REMOVE_PARTNER_COLLABORATOR';
export const EDIT_PARTNER_OWNER          = 'EDIT_PARTNER_OWNER';
export const PARTNER_DELETE_DOCUMENT     = 'PARTNER_DELETE_DOCUMENT';

//PARTNER PROPERTIES
export const RECEIVE_PARTNER_PROPERTIES = 'RECEIVE_PARTNER_PROPERTIES';
export const IMPORT_PARTNER_PROPERTY    = 'IMPORT_PARTNER_PROPERTY';

// AUDIT
export const RECEIVE_AUDIT_EVENTS = 'RECEIVE_AUDIT_EVENTS';

// NOTIFICATION
export const RECEIVE_NOTIFICATIONS        = 'RECEIVE_NOTIFICATIONS';
export const RECEIVE_NOTIFICATIONS_UNREAD = 'RECEIVE_NOTIFICATIONS_UNREAD';
export const READ_NOTIFICATION            = 'READ_NOTIFICATION';
export const UNREAD_NOTIFICATION          = 'UNREAD_NOTIFICATION';

// SCHEDULE
export const RECEIVE_SCHEDULES  = 'RECEIVE_SCHEDULES';
export const GET_SCHEDULE_TYPE  = 'GET_SCHEDULE_TYPE';
export const EDIT_SCHEDULE_TYPE = 'EDIT_SCHEDULE_TYPE';

// TIMEMACHINE
export const RECEIVE_TIMECAPSULES      = 'RECEIVE_TIMECAPSULES';
export const RECEIVE_TIMECAPSULES_USER = 'RECEIVE_TIMECAPSULES_USER';

// MAP
export const ADD_DURATION = 'ADD_DURATION';

//PORTFOLIO
export const INIT_PORTFOLIO_TRANSFER          = 'INIT_PORTFOLIO_TRANSFER';
export const SET_TRANSFER_INFOS               = 'SET_TRANSFER_INFOS';
export const ADD_SPECIFIC_RULE                = 'ADD_SPECIFIC_RULE';
export const SET_RESOURCES                    = 'SET_RESOURCES';
export const REMOVE_SPECIFIC_RULE             = 'REMOVE_SPECIFIC_RULE';
export const SET_MAIN_RULES                   = 'SET_MAIN_RULES';
export const ADD_USER_RESOURCE_ROLE           = 'ADD_USER_RESOURCE_ROLE';
export const SET_ALL_USER_RESOURCE_ROLE       = 'SET_ALL_USER_RESOURCE_ROLE';
export const UPDATE_RESOURCE_AGENT            = 'UPDATE_RESOURCE_AGENT';
export const UPDATE_MULTIPLE_RESOURCE_AGENT   = 'UPDATE_MULTIPLE_RESOURCE_AGENT';
export const UPDATE_PORTFOLIO_TRANSFER_STATUS = 'UPDATE_PORTFOLIO_TRANSFER_STATUS';

// WALLET
export const SET_CONFIGURATION  = 'SET_CONFIGURATION';
export const SET_WALLET         = 'SET_WALLET';
export const SET_TRANSACTIONS   = 'SET_TRANSACTIONS';
export const SET_PAYMENT_INTENT = 'SET_PAYMENT_INTENT';
export const SET_PACKS          = 'SET_PACKS';
export const CREATE_PACK        = 'CREATE_PACK';
export const EDIT_PACK          = 'EDIT_PACK';
export const DELETE_PACK        = 'DELETE_PACK';

// MARKETPLACE

export const SET_CART = 'SET_CART';

