
// eslint-disable-next-line @hapi/hapi/scope-start
export default file => async () => {

    const comps = import.meta.glob('../views/**/*.vue');
    const match = comps[`../views/${file}.vue`];
    //For TS: const match: () => Promise<any> = comps[`../views/${view}.vue`];

    return (await match()).default;
}
