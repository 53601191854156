import * as Types from '../mutationTypes';

import HestiaApi          from '@/api';
import ObjectManipulation from '@/utils/object';
import { cacheAction }    from 'vuex-cache';
import ObjectUtils        from '@/utils/object';

// initial state
const state = {
    clients                   : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentClient             : {},
    currentClientCounts       : {
        'documents'    : null,
        'searches'     : null,
        'issues'       : null,
        'mandates'     : null,
        'properties'   : null,
        'relations'    : null,
        'discussions'  : null,
        'appointments' : null,
        'offers'       : null,
        'companies'    : null
    },
    currentClientMandates     : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentClientAppointments : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentClientIssues       : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentClientDiscussions  : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentClientOffers       : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    clientDocumentTypes       : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentClientCompanies    : [],
    flags                     : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentClientMatches      : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    mailing_templates : []

};

// getters
const getters = {
    clients                   : state => state.clients,
    currentClient             : state => state.currentClient,
    currentClientCounts       : state => state.currentClientCounts,
    currentClientAppointments : state => state.currentClientAppointments,
    currentClientMandates     : state => state.currentClientMandates,
    clientDocumentTypes       : state => state.clientDocumentTypes,
    selectedSearchClient      : state => state.currentClient.selectedSearchClient,
    currentClientIssues       : state => state.currentClientIssues,
    currentClientDiscussions  : state => state.currentClientDiscussions,
    currentClientOffers       : state => state.currentClientOffers,
    currentClientCompanies    : state => state.currentClientCompanies,
    currentClientMatches      : state => state.currentClientMatches,
    flags                     : state => state.flags,
    mailing_templates         : state => state.mailing_templates
};

// actions
const actions = {
    'getClient' : cacheAction(({}, { tenantId, id }) => {

        return HestiaApi.Client.client.get(tenantId, id).json().then((client) => {

            return client;
        });
    }),
    async getClients({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Client.client.search(tenantId, query).json();

        const clients = {
            results    : response.hits.hits.map((m) => m._source),
            pagination : {
                perPage : query.size,
                page    : ((query.from / query.size) + 1),
                total   : response.hits.total.value
            }
        };

        commit(Types.RECEIVE_CLIENTS, clients);
    },
    async addClient({ commit }, { tenantId, client }) {

        const response = await HestiaApi.Client.client.create(tenantId, client).json();
        commit(Types.ADD_CLIENT, response);
        return response;
    },
    async editClient({ commit }, { tenantId, clientId, client }) {

        const editedClient = await HestiaApi.Client.client.update(tenantId, clientId, client).json();

        commit(Types.EDIT_CLIENT, editedClient);

        return editedClient;
    },
    async removeClient({ commit }, { tenantId, clientId }) {

        await HestiaApi.Client.client.delete(tenantId, clientId);
        commit(Types.REMOVE_CLIENT, clientId);
    },
    async getSimilarClients({ commit }, { tenantId, clients }) {

        const response = await HestiaApi.Client.client.similar(tenantId, { clients }).json();

        const similarClients = {
            results    : response.hits.hits.map((m) => m._source),
            pagination : {
                perPage : 10,
                page    : 1,
                total   : response.hits.total.value
            }
        };

        commit(Types.RECEIVE_SIMILAR_CLIENTS, similarClients);
    },
    async addEmailClient({ commit }, { tenantId, clientId, email }) {

        const response = await HestiaApi.Client.email.add(tenantId, clientId, email).json();
        commit(Types.ADD_CLIENT_EMAIL, response);

        return response;
    },
    async editEmailClient({ commit }, { tenantId, clientId, emailId, email }) {

        const response = await HestiaApi.Client.email.update(tenantId, clientId, emailId, email).json();
        commit(Types.EDIT_CLIENT_EMAIL, response);
    },
    async deleteEmail({ commit }, { tenantId, clientId, emailId }) {

        await HestiaApi.Client.email.delete(tenantId, clientId, emailId);
        commit(Types.DELETE_CLIENT_EMAIL, emailId);
    },
    async addPhoneClient({ commit }, { tenantId, clientId, phone }) {

        const response = await HestiaApi.Client.phone.add(tenantId, clientId, phone).json();
        commit(Types.ADD_CLIENT_PHONE, response);

        return response;
    },
    async editPhoneClient({ commit }, { tenantId, clientId, phoneId, phone }) {

        const response = await HestiaApi.Client.phone.update(tenantId, clientId, phoneId, phone).json();
        commit(Types.EDIT_CLIENT_PHONE, response);
    },
    async deletePhone({ commit }, { tenantId, clientId, phoneId }) {

        await HestiaApi.Client.phone.delete(tenantId, clientId, phoneId);
        commit(Types.DELETE_CLIENT_PHONE, phoneId);
    },
    async selectClient({ commit }, { tenantId, id }) {

        const [client, searches] = await Promise.all([
            HestiaApi.Client.client.get(tenantId, id).json(),
            HestiaApi.Client.search.listByClient(tenantId, id).json()
        ]);

        client.searches             = searches;
        client.selectedSearchClient = null;

        commit(Types.SELECT_CLIENT, client);

        let mandates;
        let mandatesData = [];

        try {

            // Get mandate before others count, because, we need them to get properties count
            mandatesData = await HestiaApi.Mandate.mandate.search(tenantId, {
                'query' : { 'nested' : { 'path' : 'clients', 'query' : { 'terms' : { 'clients.id' : [id] } } } },
                'from'  : 0,
                'size'  : 50
            }).json();

            mandates = mandatesData.hits.total.value;
        }
        catch (error) {

            mandates = 0;
        }

        commit(Types.INIT_CLIENT_COUNTS, {
            'client' : client,
            'counts' : {
                'mandates' : mandates
            }
        });

        const statuses = ['sale', 'estimation', 'offer', 'offer_other'];

        try {
            const { hits : { total : { value : properties } } } = mandates === 0 ? { hits : { total : { value : 0 } } } : await HestiaApi.Property.property.search(tenantId, {
                _source : 'id',
                query   : {
                    bool : {
                        must : [
                            {
                                bool : {
                                    should : statuses.map((status) => {

                                        return {
                                            match : {
                                                status
                                            }
                                        };
                                    })
                                }
                            },
                            mandatesData ?
                                {
                                    nested : {
                                        path  : 'mandates',
                                        query : {
                                            bool : {
                                                should : mandatesData.hits.hits.map((m) => {

                                                    return { match : { 'mandates.id' : m._source.id } };
                                                })
                                            }
                                        }
                                    }
                                }
                                :
                                {}
                        ]
                    }
                }
            }).json();
            commit(Types.INIT_CLIENT_COUNTS, {
                'client' : client,
                'counts' : {
                    'properties' : properties
                }
            });
        }
        catch (e) {
        }

        try {
            const { pagination : { total : appointments } } = await HestiaApi.Appointment.appointment.list(tenantId, { searchParams : { 'clients:in' : id } })
                .json();
            commit(Types.INIT_CLIENT_COUNTS, {
                'client' : client,
                'counts' : {
                    'appointments' : appointments
                }
            });

        }
        catch (e) {
        }

        try {
            const { pagination : { total : offers } } = await HestiaApi.Property.offer.list(tenantId, { searchParams : { 'client' : id } })
                .json();
            commit(Types.INIT_CLIENT_COUNTS, {
                'client' : client,
                'counts' : {
                    'offers' : offers
                }
            });

        }
        catch (e) {
        }

        try {
            const { hits : { total : { value : issues } } } = await HestiaApi.Thread.thread.search(tenantId, {
                'track_total_hits' : true,
                'query'            : {
                    'bool' : {
                        'must' : [
                            { 'bool' : { 'should' : [{ 'match' : { 'type' : 'ISSUE' } }] } }, {
                                'bool' : {
                                    'should' : [
                                        {
                                            'nested' : {
                                                'path'  : 'metadata',
                                                'query' : { 'bool' : { 'should' : [{ 'match' : { 'metadata.identifier.id' : id } }] } }
                                            }
                                        }, {
                                            'nested' : {
                                                'path'  : 'additionalMetadata',
                                                'query' : { 'bool' : { 'should' : [{ 'match' : { 'additionalMetadata.identifier.id' : id } }] } }
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                }
            }).json();
            commit(Types.INIT_CLIENT_COUNTS, {
                'client' : client,
                'counts' : {
                    'issues' : issues
                }
            });

        }
        catch (e) {
        }

        try {
            const { hits : { total : { value : discussions } } } = await HestiaApi.Thread.thread.search(tenantId, {
                'track_total_hits' : true,
                'query'            : {
                    'bool' : {
                        'must' : [
                            { 'bool' : { 'should' : [{ 'match' : { 'type' : 'MESSAGE' } }, { 'match' : { 'type' : 'REQUEST' } }] } },
                            {
                                'bool' : {
                                    'should' : [
                                        {
                                            'nested' : {
                                                'path'  : 'metadata',
                                                'query' : { 'bool' : { 'should' : [{ 'match' : { 'metadata.identifier.id' : id } }] } }
                                            }
                                        }, {
                                            'nested' : {
                                                'path'  : 'additionalMetadata',
                                                'query' : { 'bool' : { 'should' : [{ 'match' : { 'additionalMetadata.identifier.id' : id } }] } }
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                }
            }).json();
            commit(Types.INIT_CLIENT_COUNTS, {
                'client' : client,
                'counts' : {
                    'discussions' : discussions
                }
            });

        }
        catch (e) {
        }

        try {
            const companies = await HestiaApi.Company.client.clientCompanies(tenantId, client.id).json();
            commit(Types.INIT_CLIENT_COUNTS, {
                'client' : client,
                'counts' : {
                    'companies' : companies
                }
            });

        }
        catch (e) {
        }
    },
    async addClientDocument({ commit }, { tenantId, id, document }) {

        const newDocument = await HestiaApi.Client.document.add(tenantId, id, document).json();
        commit(Types.CLIENT_ADD_DOCUMENT, newDocument);
    },
    async editClientDocument({ commit }, { tenantId, clientId, id, document }) {

        const response = await HestiaApi.Client.document.update(tenantId, clientId, id, document).json();
        commit(Types.CLIENT_EDIT_DOCUMENT, response);
    },
    async removeClientDocument({ commit }, { tenantId, clientId, documentId }) {

        await HestiaApi.Client.document.remove(tenantId, clientId, documentId);
        commit(Types.CLIENT_DELETE_DOCUMENT, documentId);
    },

    // CLIENT SEARCH
    async addClientSearch({ commit }, { tenantId, clientId, search }) {

        // Reformat location propertly
        if ('departments' in search || 'postcodes' in search || 'insees' in search) {

            search.location = {};

            if ('departments' in search) {

                search.location.departments = search.departments;
                delete search.departments;
            }

            if ('postcodes' in search) {

                search.location.postcodes = search.postcodes;
                delete search.postcodes;
            }

            if ('insees' in search) {

                search.location.insees = search.insees;
                delete search.insees;
            }
        }

        const response = await HestiaApi.Client.search.create(tenantId, clientId, search).json();

        commit(Types.ADD_CLIENT_SEARCH, response);
        return response;
    },
    // CLIENT SEARCH
    async editSearchClient({ commit }, { tenantId, clientId, id, search }) {
        // Reformat location propertly
        if ('departments' in search || 'postcodes' in search || 'insees' in search) {

            search.location = {};

            if ('departments' in search) {

                search.location.departments = search.departments;
                delete search.departments;
            }

            if ('postcodes' in search) {

                search.location.postcodes = search.postcodes;
                delete search.postcodes;
            }

            if ('insees' in search) {

                search.location.insees = search.insees;
                delete search.insees;
            }
        }

        const response = await HestiaApi.Client.search.update(tenantId, clientId, id, search).json();

        commit(Types.EDIT_CLIENT_SEARCH, response);
        return response;
    },
    async editSearchClientDefault({ commit }, { tenantId, clientId, id, defaultSearch }) {

        const response = await HestiaApi.Client.search.update(tenantId, clientId, id, { default : defaultSearch }).json();

        commit(Types.EDIT_CLIENT_SEARCH_DEFAULT, response);
        return response;
    },
    async selectSearchClient({ commit }, { id, data }) {

        commit(Types.SELECT_CLIENT_SEARCH, id);

        const search = {};

        for (const key of Object.keys(data)) {

            if (['price', 'types', 'features', 'rooms', 'spaces', 'location', 'rating'].includes(key) && data[key] !== null) {
                search[key] = data[key];
            }
        }

        if ('location' in search) {

            for (const key of Object.keys(search.location)) {

                search[key] = search.location[key];
            }

            delete search.location;
        }

        commit(Types.UPDATE_QUERY, {
            model : 'client_search', query : {
                search     : search,
                pagination : {
                    perPage : 10,
                    page    : 1
                }
            }
        });
    },
    unselectSearchClient({ commit }) {

        commit(Types.UNSELECT_CLIENT_SEARCH);
    },
    async removeClientSearch({ commit }, { tenantId, clientId, searchId }) {

        await HestiaApi.Client.search.delete(tenantId, clientId, searchId);

        commit(Types.DELETE_CLIENT_SEARCH, searchId);
    },

    async getClientMandates({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Mandate.mandate.search(tenantId, query).json();

        const mandates = {
            results    : response.hits.hits.map((m) => m._source),
            pagination : {
                perPage : query.size,
                page    : ((query.from / query.size) + 1),
                total   : response.hits.total.value
            }
        };

        commit(Types.RECEIVE_CLIENT_MANDATES, mandates);

    },
    async getClientAppointments({ commit }, { tenantId, query }) {

        const appointments = await HestiaApi.Appointment.appointment.list(tenantId, { searchParams : query }).json();

        for (const appointment of appointments.results) {

            appointment.clients = await Promise.all(appointment.clients.map(async (clientId) => {

                const threads = await HestiaApi.Thread.thread.search(tenantId, {
                    'sort'  : [{ 'createdAt' : { 'order' : 'desc' } }],
                    'query' : {
                        'bool' : {
                            'must' : [
                                { 'match' : { 'type' : 'MESSAGE' } },
                                {
                                    'nested' : {
                                        'path'  : 'metadata',
                                        'query' : {
                                            'bool' : {
                                                'must' : [
                                                    { 'match' : { 'metadata.identifier.id' : appointment.id } },
                                                    { 'match' : { 'metadata.identifier.id' : clientId } }
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    }
                }).json();

                return {
                    clientId,
                    threads : threads.hits.hits.map((thread) => thread._source)
                };
            }));
        }

        commit(Types.RECEIVE_CLIENT_APPOINTMENTS, appointments);
    },
    editClientAppointmentStatus({ commit }, { appointment }) {

        commit(Types.EDIT_CLIENT_APPOINTMENT_STATUS, { appointment });
    },
    removeClientAppointment({ commit }, { appointmentId }) {

        commit(Types.REMOVE_CLIENT_APPOINTMENT, { appointmentId });
    },
    addThreadToClientAppointment({ commit }, { appointmentId, clientId, thread }) {

        commit(Types.ADD_THREAD_CLIENT_APPOINTMENT, { appointmentId, clientId, thread });
    },
    async getClientIssues({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Thread.thread.search(tenantId, { query : query.query }).json();

        const issues = {
            results    : response.hits.hits.map((m) => m._source),
            pagination : {
                perPage : query.size,
                page    : ((query.from / query.size) + 1),
                total   : response.hits.total.value
            }
        };

        commit(Types.RECEIVE_CLIENT_ISSUES, issues);
    },
    async getClientDiscussions({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Thread.thread.search(tenantId, query).json();

        const discussions = {
            results    : response.hits.hits.map((m) => m._source),
            pagination : {
                perPage : query.size,
                page    : ((query.from / query.size) + 1),
                total   : response.hits.total.value
            }
        };

        commit(Types.RECEIVE_CLIENT_DISCUSSIONS, discussions);
    },
    // ACL
    async editClientOwner({ commit }, { tenantId, id, owner }) {

        const response = await HestiaApi.Client.client.editOwner(tenantId, id, owner).json();

        commit(Types.EDIT_CLIENT, response);
    },
    async addClientCollaborator({ commit }, { tenantId, id, collaborator }) {

        const response = await HestiaApi.Client.client.addCollaborator(tenantId, id, collaborator).json();

        commit(Types.EDIT_CLIENT, response);
    },
    async addClientCoowner({ commit }, { tenantId, id, coowner }) {

        const response = await HestiaApi.Client.client.addCoOwner(tenantId, id, coowner).json();

        commit(Types.EDIT_CLIENT, response);
    },
    async addClientRelation({ commit }, { tenantId, id, relation }) {

        await HestiaApi.Client.client.addRelation(tenantId, id, relation);

        commit(Types.ADD_CLIENT_RELATION, relation);
    },
    async removeClientRelation({ commit }, { tenantId, id, relation }) {

        await HestiaApi.Client.client.removeRelation(tenantId, id, relation);

        commit(Types.REMOVE_CLIENT_RELATION, relation);
    },
    async addClientIssue({ commit }, { issue }) {

        commit(Types.ADD_CLIENT_ISSUE, issue);
    },
    async addClientDiscussion({ commit }, { discussion }) {

        commit(Types.ADD_CLIENT_DISCUSSION, discussion);
    },
    async addClientOffer({ commit }, { offer }) {

        commit(Types.ADD_CLIENT_OFFER, offer);
    },
    async editClientOffer({ commit }, offer) {

        commit(Types.EDIT_CLIENT_OFFER, offer);
    },
    async addClientAppointment({ commit }) {

        commit(Types.ADD_CLIENT_APPOINTMENT);
    },
    async editClientAppointment({ commit }, { appointment }) {

        commit(Types.EDIT_CLIENT_APPOINTMENT, appointment);
    },
    async removeClientCollaborator({ commit }, { tenantId, id, collaborator }) {

        await HestiaApi.Client.client.removeCollaborator(tenantId, id, collaborator);

        commit(Types.REMOVE_CLIENT_COLLABORATOR, collaborator);
    },
    async removeClientCoowner({ commit }, { tenantId, id, coowner }) {

        await HestiaApi.Client.client.removeCoOwner(tenantId, id, coowner);

        commit(Types.REMOVE_CLIENT_COOWNER, coowner);
    },
    async getOffers({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Property.offer.list(tenantId, { searchParams : query }).json();

        commit(Types.RECEIVE_OFFERS, response);
    },
    async getClientCompanies({ commit }, { tenantId, query }) {

        const id = state.currentClient.id;

        const response = await HestiaApi.Company.client.clientCompanies(tenantId, id, query).json();

        commit(Types.RECEIVE_CLIENT_COMPANIES, response);
    },
    async countClientMatches({ commit }, { tenantId, query }) {

        const queryPercolate = {
            from  : 0,
            size  : 0,
            query : {
                constant_score : {
                    filter : {
                        percolate : {
                            field    : 'query',
                            document : {
                                status : 'sale',
                                ...query
                            }
                        }
                    }
                }
            }
        }
        const response = await HestiaApi.Client.search.search(tenantId, queryPercolate).json();

        const searches = {
            results    : response.hits.hits.map((m) => m._source.search),
            pagination : {
                perPage : query.perPage,
                page    : query.page,
                total   : response.hits.total.value
            }
        };

        commit(Types.RECEIVE_CLIENT_MATCHES, searches);
    },
    async getFlags({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Client.flag.list(tenantId, { searchParams : query }).json();

        commit(Types.RECEIVE_CLIENT_FLAGS, response);
    },
    async addFlag({ commit }, { tenantId, flag }) {

        const response = await HestiaApi.Client.flag.create(tenantId, flag).json();

        commit(Types.ADD_FLAG, response);
    },
    async deleteFlag({ commit }, { tenantId, flagId }) {

        await HestiaApi.Client.flag.delete(tenantId, flagId);

        commit(Types.DELETE_FLAG, flagId);
    },
    async editFlag({ commit }, { tenantId, flagId, flag }) {

        const response = await HestiaApi.Client.flag.update(tenantId, flagId, flag).json();

        commit(Types.EDIT_FLAG, response);
    },

    // TYPES
    async getClientDocumentTypes({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Client.documentType.list(tenantId, query).json();
        commit(Types.RECEIVE_CLIENT_DOCUMENT_TYPES, response);
    },
    async addClientDocumentType({ commit }, { tenantId, documentType }) {

        const response = await HestiaApi.Client.documentType.create(tenantId, documentType).json();
        commit(Types.ADD_CLIENT_DOCUMENT_TYPE, response);
    },
    async editClientDocumentType({ commit }, { tenantId, id, documentType }) {

        const response = await HestiaApi.Client.documentType.update(tenantId, id, documentType).json();
        commit(Types.EDIT_CLIENT_DOCUMENT_TYPE, response);
    },
    async deleteClientDocumentType({ commit }, { tenantId, id }) {

        const response = await HestiaApi.Client.documentType.delete(tenantId, id).json();
        commit(Types.REMOVE_CLIENT_DOCUMENT_TYPE, response);
    },
    async addMailingTemplate({ commit }, { tenantId, mailingTemplate, pageType }) {

        const response = await HestiaApi.Client.mailing.createTemplate(tenantId, mailingTemplate).json();

        if (response.type && pageType === response.type.toLowerCase()) {

            commit(Types.ADD_MAILING_TEMPLATE, response);
        }

        return response;
    },
    async getMailingTemplates({ commit }, { tenantId, query }) {

        query = ObjectUtils.omit(query, ['type', 'page', 'perPage']);
        const response = await HestiaApi.Client.mailing.listTemplate(tenantId, query).json();
        commit(Types.RECEIVE_MAILING_TEMPLATES, response);
    },

    async editMailingTemplate({ commit }, { tenantId,id, mailingTemplate }) {

        const response = await HestiaApi.Client.mailing.updateTemplate(tenantId,id, mailingTemplate).json();

        commit(Types.EDIT_MAILING_TEMPLATE, response);

        return response;
    },

    async resetClientMatches({commit}) {

        commit(Types.RECEIVE_CLIENT_MATCHES, {
            results    : [],
            pagination : {
                perPage : 0,
                page    : 1,
                total   : 0
            }
        })
    }
};

// mutations
const mutations = {
    [Types.RECEIVE_CLIENTS](state, clients) {

        state.clients = clients;
    },
    [Types.RECEIVE_SIMILAR_CLIENTS](state, clients) {

        state.clients = clients;
    },
    [Types.RECEIVE_CLIENT_MANDATES](state, client_mandates) {

        state.currentClientMandates = client_mandates;
    },

    [Types.RECEIVE_CLIENT_APPOINTMENTS](state, client_appointment) {

        state.currentClientAppointments = client_appointment;
    },

    [Types.REMOVE_CLIENT_APPOINTMENT](state, { appointmentId }) {

        const indexApp = state.currentClientAppointments.results.findIndex(a => a.id === appointmentId);

        if (indexApp !== -1) {

            state.currentClientAppointments.results.splice(indexApp, 1);
            state.currentClientCounts.appointments--;
        }
    },
    [Types.EDIT_CLIENT_APPOINTMENT_STATUS](state, { appointment }) {

        const indexApp = state.currentClientAppointments.results.findIndex(a => a.id === appointment.id);

        if (indexApp !== -1) {

            state.currentClientAppointments.results[indexApp].statuses = appointment.statuses;
        }
    },

    [Types.ADD_THREAD_CLIENT_APPOINTMENT](state, { appointmentId, clientId, thread }) {

        const indexApp    = state.currentClientAppointments.results.findIndex(a => a.id === appointmentId);
        const indexClient = state.currentClientAppointments.results[indexApp].clients.findIndex(c => c.clientId === clientId);

        state.currentClientAppointments.results[indexApp].clients[indexClient].threads.push(thread);
    },
    [Types.RECEIVE_CLIENT_ISSUES](state, client_issues) {

        state.currentClientIssues = client_issues;
    },
    [Types.RECEIVE_CLIENT_DISCUSSIONS](state, client_discussions) {

        state.currentClientDiscussions = client_discussions;
    },
    [Types.ADD_CLIENT](state, client) {

        state.clients.results.push(client);
    },
    [Types.EDIT_CLIENT](state, client) {

        client = {
            ...client,
            searches             : state.currentClient.searches,
            selectedSearchClient : state.currentClient.selectedSearchClient,
            emails               : state.currentClient.emails,
            phones               : state.currentClient.phones
        };

        state.currentClient = client;
        const index         = state.clients.results.findIndex((cli) => cli.id === client.id);

        state.clients.results.splice(index, 1, client);
        state.clients = ObjectManipulation.clone(state.clients); // else deep objects are not updated, if it's not a deep object use splice instead ;)
    },
    [Types.REMOVE_CLIENT](state, clientId) {

        state.clients.results.splice(clientId, 1);
    },
    [Types.ADD_CLIENT_EMAIL](state, email) {

        if (state.currentClient.emails) {

            // Add Email to currentClient
            state.currentClient.emails.push(email);

            // Add email to the client list
            const index = state.clients.results.findIndex((client) => client.id === state.currentClient.id);
            if (index !== -1 && index !== undefined) {
                state.clients.results[index].emails.push(email);
            }

            state.currentClientCounts.emails++;
        }
    },
    [Types.EDIT_CLIENT_EMAIL](state, email) {

        // Update email in CurrentEmail
        if (state.currentClient.emails) {
            const index = state.currentClient.emails.findIndex((e) => e.id === email.id);
            state.currentClient.emails.splice(index, 1, email);
        }

        // Add email to the client list
        if (state.clients.results) {
            const indexCli = state.clients.results.findIndex((client) => client.id === state.currentClient.id);
            if (indexCli !== -1 && indexCli !== undefined) {
                state.clients.results[indexCli].emails = state.currentClient.emails;
            }
        }

    },
    [Types.DELETE_CLIENT_EMAIL](state, emailId) {

        // Delete Email on currentClient
        const index = state.currentClient.emails.findIndex((e) => e.id === emailId);
        state.currentClient.emails.splice(index, 1);

        // Delete email in client list
        const indexCli = state.clients.results.findIndex((client) => client.id === state.currentClient.id);
        if (indexCli !== -1 && indexCli !== undefined) {
            state.clients.results[indexCli].emails = state.currentClient.emails;
        }

        state.currentClientCounts.emails--;
    },
    [Types.ADD_CLIENT_PHONE](state, phone) {

        if (state.currentClient.phones) {
            // Add Email to currentClient
            state.currentClient.phones.push(phone);

            // Add phone to the client list
            const index = state.clients.results.findIndex((client) => client.id === state.currentClient.id);
            if (index !== -1 && index !== undefined) {
                state.clients.results[index].phones.push(phone);
            }

            state.currentClientCounts.phones++;
        }
    },
    [Types.EDIT_CLIENT_PHONE](state, phone) {

        // Update phone in CurrentEmail
        if (state.currentClient.phones) {
            const index = state.currentClient.phones.findIndex((p) => p.id === phone.id);
            state.currentClient.phones.splice(index, 1, phone);
        }

        // Add phone to the client list
        if (state.clients.results) {
            const indexCli = state.clients.results.findIndex((client) => client.id === state.currentClient.id);
            if (indexCli !== -1 && indexCli !== undefined) {
                state.clients.results[indexCli].phones = state.currentClient.emails;
            }
        }

    },
    [Types.DELETE_CLIENT_PHONE](state, phoneId) {

        // Delete phone on currentClient
        const index = state.currentClient.phones.findIndex((p) => p.id === phoneId);
        state.currentClient.phones.splice(index, 1);

        // Delete phone in client list
        const indexCli = state.clients.results.findIndex((client) => client.id === state.currentClient.clientId);
        if (indexCli !== -1 && indexCli !== undefined) {
            state.clients.results[indexCli].phones = state.currentClient.phones;
        }

        state.currentClientCounts.phones--;
    },
    // SEARCHES
    [Types.ADD_CLIENT_SEARCH](state, search) {

        //Client is being created so currentClient is empty at this time
        if (!state.currentClient.searches && !state.currentClient.state) {
            return;
        }

        if (state.currentClient.searches.length === 0) {

            const indexState = state.currentClient.state.findIndex(state => state === 'inactive_buyer');

            if (indexState !== -1) {
                state.currentClient.state.splice(indexState, 1, 'active_buyer');
            }
            else {
                state.currentClient.state.push('active_buyer');
            }
        }

        state.currentClient.searches.push(search);

        // Update counts
        state.currentClientCounts.searches++;
    },
    [Types.EDIT_CLIENT_SEARCH](state, search) {

        const index = state.currentClient.searches.findIndex((s) => s.id === search.id);
        state.currentClient.searches.splice(index, 1, search);
    },
    [Types.EDIT_CLIENT_SEARCH_DEFAULT](state, search) {

        const index = state.currentClient.searches.findIndex((s) => s.id === search.id);
        state.currentClient.searches.splice(index, 1, search);
    },
    [Types.SELECT_CLIENT_SEARCH](state, id) {

        state.currentClient.selectedSearchClient = id;
    },
    [Types.UNSELECT_CLIENT_SEARCH](state) {

        state.currentClient.selectedSearchClient = null;
    },
    [Types.DELETE_CLIENT_SEARCH](state, search) {

        if (state.currentClient.searches.length === 1) {

            const indexState = state.currentClient.state.findIndex(state => state === 'active_buyer');

            if (indexState !== -1) {
                state.currentClient.state.splice(indexState, 1, 'inactive_buyer');
            }
            else {
                state.currentClient.state.push('inactive_buyer');
            }
        }

        const index = state.currentClient.searches.findIndex((s) => s.id === search);
        state.currentClient.searches.splice(index, 1);
        state.currentClientCounts.searches--;
    },

    // CLIENT
    [Types.SELECT_CLIENT](state, client) {

        state.currentClient = client;

        state.currentClientAppointments = {
            results    : [],
            pagination : {
                perPage : 0,
                page    : 1,
                total   : 0
            }
        };
    },
    [Types.INIT_CLIENT_COUNTS](state, { client, counts }) {

        state.currentClientCounts = {
            'documents'    : 'documents' in client ? client.documents.length : 0,
            'searches'     : 'searches' in client ? client.searches.length : 0,
            'relations'    : 'relations' in client ? client.relations.length : 0,
            'phones'       : 'phones' in client ? client.phones.length : 0,
            'emails'       : 'emails' in client ? client.emails.length : 0,
            'companies'    : counts.companies ? counts.companies.length : state.currentClientCounts.companies,
            'mandates'     : !isNaN(counts.mandates) ? counts.mandates : state.currentClientCounts.mandates,
            'properties'   : !isNaN(counts.properties) ? counts.properties : state.currentClientCounts.properties,
            'requests'     : !isNaN(counts.requests) ? counts.requests : state.currentClientCounts.requests,
            'discussions'  : !isNaN(counts.discussions) ? counts.discussions : state.currentClientCounts.discussions,
            'appointments' : !isNaN(counts.appointments) ? counts.appointments : state.currentClientCounts.appointments,
            'offers'       : !isNaN(counts.offers) ? counts.offers : state.currentClientCounts.offers,
            'issues'       : !isNaN(counts.issues) ? counts.issues : state.currentClientCounts.issues
        };
    },
    [Types.CLIENT_ADD_DOCUMENT](state, document) {

        state.currentClient.documents.push(document);

        // Update counts
        state.currentClientCounts['documents']++;
    },
    [Types.CLIENT_EDIT_DOCUMENT](state, document) {

        const index = state.currentClient.documents.findIndex((a) => a.id === document.id);
        state.currentClient.documents.splice(index, 1, document);
    },
    [Types.CLIENT_DELETE_DOCUMENT](state, documentId) {

        const index = state.currentClient.documents.findIndex((document) => document.id === documentId);
        state.currentClient.documents.splice(index, 1);

        // Update counts
        state.currentClientCounts['documents']--;
    },
    [Types.ADD_CLIENT_ISSUE](state, issue) {

        // Manually Add
        state.currentClientIssues.results.unshift(issue);
        state.currentClientIssues.pagination.total++;

        if (state.currentClientIssues.pagination.total > state.currentClientIssues.pagination.perPage) {
            state.currentClientIssues.results.pop();
        }

        // Update counts
        state.currentClientCounts['issues']++;
    },
    [Types.ADD_CLIENT_DISCUSSION](state, discussion) {

        // Manually Add
        state.currentClientDiscussions.results.unshift(discussion);
        state.currentClientDiscussions.pagination.total++;

        if (state.currentClientDiscussions.pagination.total > state.currentClientDiscussions.pagination.perPage) {
            state.currentClientDiscussions.results.pop();
        }

        // Update counts
        state.currentClientCounts['discussions']++;
    },
    [Types.ADD_CLIENT_OFFER](state, offer) {

        state.currentClientOffers.results.push(offer);

        // Update counts
        state.currentClientCounts['offers']++;
    },
    [Types.EDIT_CLIENT_OFFER](state, offer) {

        const index = state.currentClientOffers.results.findIndex((of) => of.id === offer.id);

        state.currentClientOffers.results.splice(index, 1, offer);
    },
    [Types.ADD_CLIENT_APPOINTMENT](state) {

        // Update counts
        state.currentClientCounts['appointments']++;
    },
    [Types.EDIT_CLIENT_APPOINTMENT](state, appointment) {

        const index = state.currentClientAppointments.results.findIndex((a) => a.id === appointment.id);
        if (index !== -1) {

            state.currentClientAppointments.results.splice(index, 1, appointment);
        }
    },

    // ACL

    [Types.REMOVE_CLIENT_COLLABORATOR](state, collaborator) {

        const index = state.currentClient.collaborators.findIndex((c) => c.id === collaborator.id);
        state.currentClient.collaborators.splice(index, 1);
    },
    [Types.ADD_CLIENT_RELATION](state, relation) {

        state.currentClientCounts['relations']++;
        state.currentClient.relations.push(relation.clientId);
    },
    [Types.REMOVE_CLIENT_RELATION](state, relationId) {

        state.currentClientCounts['relations']--;
        const index = state.currentClient.relations.findIndex((c) => c === relationId);
        state.currentClient.relations.splice(index, 1);
    },
    [Types.REMOVE_CLIENT_COOWNER](state, coowner) {

        const index = state.currentClient.coOwners.findIndex((c) => c.id === coowner.id);
        state.currentClient.coOwners.splice(index, 1);
    },
    [Types.RECEIVE_OFFERS](state, offers) {

        state.currentClientOffers = offers;
    },
    [Types.RECEIVE_CLIENT_COMPANIES](state, clientCompanies) {

        state.currentClientCompanies = clientCompanies;
    },
    [Types.RECEIVE_CLIENT_MATCHES](state, clientsMatches) {

        state.currentClientMatches = clientsMatches;
    },
    [Types.RECEIVE_CLIENT_FLAGS](state, flags) {

        state.flags = flags;
    },
    [Types.ADD_FLAG](state, flag) {

        state.flags.results.push(flag);
    },
    [Types.DELETE_FLAG](state, flagId) {

        const index = state.flags.results.findIndex((flag) => flag.id === flagId);
        state.flags.results.splice(index, 1);
    },
    [Types.EDIT_FLAG](state, editedFlag) {

        const index = state.flags.results.findIndex((flag) => flag.id === editedFlag.id);
        state.flags.results.splice(index, 1, editedFlag);
    },

    // TYPES
    [Types.RECEIVE_CLIENT_DOCUMENT_TYPES](state, documentTypes) {

        state.clientDocumentTypes = documentTypes;
    },
    [Types.ADD_CLIENT_DOCUMENT_TYPE](state, documentType) {

        state.clientDocumentTypes.results.unshift(documentType);

        if (state.clientDocumentTypes.results.length === state.clientDocumentTypes.pagination.perPage) {
            state.clientDocumentTypes.results.pop();
        }
    },
    [Types.EDIT_CLIENT_DOCUMENT_TYPE](state, documentType) {

        const index = state.clientDocumentTypes.results.findIndex((clientDocumentType) => clientDocumentType.id === documentType[0].id);
        state.clientDocumentTypes.results.splice(index, 1, documentType[0]);
    },
    [Types.REMOVE_CLIENT_DOCUMENT_TYPE](state, id) {

        const index = state.clientDocumentTypes.results.findIndex((clientDocumentType) => clientDocumentType.id === id);
        state.clientDocumentTypes.results.splice(index, 1);
    },
    [Types.ADD_MAILING_TEMPLATE](state, mailingTemplate) {

        state.mailing_templates.push(mailingTemplate);
    },
    [Types.RECEIVE_MAILING_TEMPLATES](state, mailingTemplate) {

        state.mailing_templates.push(mailingTemplate);
    },
    [Types.EDIT_MAILING_TEMPLATE](state, mailingTemplate) {

        state.mailing_templates.splice(state.mailing_templates.findIndex((template) => template.id === mailingTemplate.id), 1, mailingTemplate);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
