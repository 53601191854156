import Store from '@/store/index.js';

export default {
    methods : {
        routeWithTenant({ name, path, params = {} }) {

            return {
                name   : name ? name : '',
                path   : path ? path : '',
                params : {
                    ...params,
                    tenant : Store.getters.tenant.id
                }
            }
        }
    }
}
