import Avenant        from '@/models/avenant';
import Calendar       from '@/models/calendar';
import Event          from '@/models/event';
import RBAC           from '@pixul/hestia-rbac';
import { mapGetters } from 'vuex';


export default {
    computed : {
        ...mapGetters(['user'])
    },
    methods : {

        /**
         * function that return if the user is allowed to do the action or not
         * we need to check all the differrent rules
         * @param {string} model
         * @param {string} action
         * @param {Object} data
         * @deprecated
         */
        canDoAction(model, action, data, subAction = '') {


            if (model === '' && action === '') {

                return true
            }

            // 1st - Check ACL
            if (!this.canDoActionACL(model, action, data)) {

                return false
            }

            // 2nd - Check with data
            // Check rules on data (.eg : Mandate status)

            return this.canDoActionOnData(model, action, data, subAction);
        },

        getWhyCantdoAction(model, action, data, subAction = '') {

            if (model === '' && action === '') {

                return { reason : ''}
            }

            if (!this.canDoActionACL(model, action, data)) {
                return {
                    reason : 'acl'
                }
                // @TODO add others informations
                // userRole
                // needeRole
            }

            if (!this.canDoActionOnData(model, action, data, subAction)) {
                return {
                    reason : 'data'
                }
                // @TODO add others informations about the data reason
                //
            }

            return { reason : ''}

        },

        canDoActionACL(model, action, data) {

            let roles = []

            if (data !== undefined && data[model] !== undefined) {

                roles = RBAC.getRolesForUser(this.user, data[model])
            } else {

                roles = RBAC.getStaticRolesForUser(this.user)
            }

            const possibleActions = this.getPossibleActionsForResource(roles, model);

            return possibleActions.includes(action)
        },

        canDoActionOnData(model, action, data, subAction) {

            if (data !== undefined) {

                // On Calendar
                if (model === 'calendar') {

                    if (data.calendar !== undefined && data.permission !== undefined) { // && data.calendar.shares !== undefined) {

                        return Calendar.actionByPermissions[data.permission][action]
                    }
                }

                // On Event
                if (model === 'event') {

                    if (data.event !== undefined && data.event.permission !== undefined ) { // && data.calendar.shares !== undefined) {

                        return Event.actionByPermissions[data.event.permission][action]
                    }
                }

                // On Mandate
                if (model === 'mandate') {

                    if (data.mandate !== undefined && data.mandate.status !== undefined) {

                        if (subAction === 'add_avenant') {

                            const roles = RBAC.getRolesForUser(this.user, data.mandate);
                            const can   = RBAC.can(roles, ['super_admin', 'mandate', 'owner', 'coOwner']);

                            return can && data.mandate.status === 'completed'
                        }

                        return true
                    }
                }

                if (model === 'avenant') {

                    if (data.mandate !== undefined && data.avenant.status) {

                        const roles = RBAC.getRolesForUser(this.user, data.mandate);
                        const can   = RBAC.can(roles, ['super_admin', 'mandate', 'owner', 'coOwner']);

                        return can && Avenant.actionByStatus[data.avenant.status][action]
                    }

                    if (data.avenant.status !== undefined) {

                        return Avenant.actionByStatus[data.avenant.status][action]
                    }
                }

                if (model === 'request') {

                    if (action === 'edit' && subAction === '') {

                        const roles = RBAC.getRolesForUser(this.user, data.request);

                        return RBAC.can(roles, ['owner', 'coOwner', 'super_admin', 'lead'])
                    }

                    if (action === 'edit' && subAction === 'answer') {

                        if (data.request.status === 'todo' || data.request.status === 'user_requested' || data.request.threads.length === 0 ) {

                            const roles = RBAC.getRolesForUser(this.user, data.request);

                            return RBAC.can(roles, ['super_admin', 'lead'])
                        }

                        return false

                    }
                }
            }

            return true
        },
        /**
         * list all the possible action the user can do on this resource
         * @param {Object} roles roles of the user
         * @param {String} model String name of the model
         */
        getPossibleActionsForResource(roles, model) {

            if (roles.includes('super_admin') || roles.includes(model) || roles.includes('owner')) {
                return ['read', 'edit', 'add', 'delete', 'edit_collaborator', 'edit_coowner', 'edit_owner']
            }

            // CoOwner
            if (roles.includes('coOwner')) {

                return ['read', 'edit', 'add', 'delete', 'edit_collaborator']
            }

            // Collab client
            if (roles.includes('collaborator') && (model === 'client' || model === 'company')) {
                return ['read', 'edit', 'add', 'delete', 'edit_collaborator']
            }

            // Collab
            if (roles.includes('collaborator')) {
                return ['read', 'add']
            }

            return ['read', 'add']
        }
    }
}
