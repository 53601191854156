import * as Types from '../mutationTypes';

import HestiaApi          from '@/api';
import ObjectManipulation from '@/utils/object';

// initial state
const state = {
    mandates               : {
        results      : [],
        pagination   : {
            perPage : 0,
            page    : 1,
            total   : 0
        },
        aggregations : []
    },
    types                  : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    mandateDocumentTypes   : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentMandate         : {},
    loadingMandate         : false,
    mandate_sources        : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentMandateCounts   : {
        'clients'    : null,
        'documents'  : null,
        'avenants'   : null,
        'emandates'  : null,
        'properties' : null,
        'issues'     : null,
        'commission' : null
    },
    currentMandateAvenants : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentMandateIssues   : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    avenants               : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    eavenantsTemplate      : [],
    emandatesTemplate      : [],
    registry_mandates      : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    }
};

// getters
const getters = {
    mandates               : state => state.mandates,
    avenants               : state => state.avenants,
    eavenantsTemplate      : state => state.eavenantsTemplate,
    emandatesTemplate      : state => state.emandatesTemplate,
    mandateTypes           : state => state.types,
    mandateDocumentTypes   : state => state.mandateDocumentTypes,
    mandate_sources        : state => state.mandate_sources,
    registry_mandates      : state => state.registry_mandates,
    currentMandate         : state => state.currentMandate,
    currentMandateCounts   : state => state.currentMandateCounts,
    currentMandateAvenants : state => state.currentMandateAvenants,
    currentMandateIssues   : state => state.currentMandateIssues,
    loadingMandate         : state => state.loadingMandate
};

// actions
const actions = {
    async getMandates({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Mandate.mandate.search(tenantId, query).json();

        const mandates = {
            results      : response.hits.hits.map((m) => m._source),
            pagination   : {
                perPage : query.size,
                page    : ((query.from / query.size) + 1),
                total   : response.hits.total.value
            },
            aggregations : response.aggregations
        };

        commit(Types.RECEIVE_MANDATES, mandates);
    },
    async addMandate({ commit }, { tenantId, mandate }) {

        const response = await HestiaApi.Mandate.mandate.create(tenantId, mandate).json();
        commit(Types.ADD_MANDATE, response);

        return response;
    },
    async editMandate({ commit }, { id, mandate, tenantId, editCurrentMandate = true }) {

        const response = await HestiaApi.Mandate.mandate.update(tenantId, id, mandate).json();

        if (editCurrentMandate) {

            commit(Types.EDIT_MANDATE, response);
        }
    },

    async getMandateTemplate({ commit }, { id, tenantId }) {

        const response = await HestiaApi.Mandate.mandate.template(tenantId, id).json();

        commit(Types.GET_MANDATE_TEMPLATE, response);
    },

    async overrideMandateTemplate({ commit }, { id, tenantId, template }) {

        await HestiaApi.Mandate.mandate.templateOverride(tenantId, id, template);

        commit(Types.OVERRIDE_MANDATE_TEMPLATE, template);
    },

    async resetMandateTemplate({ commit }, { id, tenantId }) {

        const response = await HestiaApi.Mandate.mandate.templateReset(tenantId, id).json();
        const type     = await HestiaApi.Mandate.type.get(tenantId, response.type.id).json();

        commit(Types.RESET_MANDATE_TEMPLATE, type);
    },
    async getMandateTypes({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Mandate.type.list(tenantId, { searchParams : query }).json();
        commit(Types.RECEIVE_MANDATE_TYPES, response);
    },
    async addMandateType({ commit }, { tenantId, type }) {

        const response = await HestiaApi.Mandate.type.create(tenantId, type).json();
        commit(Types.ADD_MANDATE_TYPE, response);
    },
    async editMandateType({ commit }, { tenantId, id, type }) {

        const response = await HestiaApi.Mandate.type.update(tenantId, id, type).json();
        commit(Types.EDIT_MANDATE_TYPE, response);
    },
    async editMandateTypeOrder({ commit }, { tenantId, id, newOrder, oldOrder }) {

        const response = await HestiaApi.Mandate.type.update(tenantId, id, { order : newOrder }).json();
        commit(Types.EDIT_MANDATE_TYPE_ORDER, { type : response, newOrder : newOrder, oldOrder : oldOrder });
    },
    async getMandateSources({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Mandate.source.list(tenantId, { searchParams : query }).json();
        commit(Types.RECEIVE_MANDATE_SOURCES, response);
    },
    async addMandateSource({ commit }, { tenantId, source }) {

        const response = await HestiaApi.Mandate.source.create(tenantId, source).json();
        commit(Types.ADD_MANDATE_SOURCE, response);
        return response;
    },
    async editMandateSource({ commit }, { tenantId, id, source }) {

        const response = await HestiaApi.Mandate.source.update(tenantId, id, source).json();
        commit(Types.EDIT_MANDATE_SOURCE, response);
    },
    async editMandateSourceOrder({ commit }, { tenantId, id, newOrder, oldOrder }) {

        const response = await HestiaApi.Mandate.source.update(tenantId, id, { order : newOrder }).json();
        commit(Types.EDIT_MANDATE_SOURCE_ORDER, { source : response, newOrder : newOrder, oldOrder : oldOrder });
    },
    async removeMandateSource({ commit }, { tenantId, id }) {

        await HestiaApi.Mandate.source.delete(tenantId, id);
        commit(Types.REMOVE_MANDATE_SOURCE, id);
    },
    async updateMandateSource({ commit }, { tenantId, id, mandate }) {

        const response = await HestiaApi.Mandate.mandate.updateSource(tenantId, id, mandate).json();
        commit(Types.UPDATE_MANDATE_SOURCE, response);
    },
    async addMandateDocument({ commit }, { tenantId, id, document }) {

        const newDocument = await HestiaApi.Mandate.document.add(tenantId, id, document).json();
        commit(Types.MANDATE_ADD_DOCUMENT, newDocument);
    },
    async editMandateDocument({ commit }, { tenantId, mandateId, id, document }) {

        const response = await HestiaApi.Mandate.document.update(tenantId, mandateId, id, document).json();
        commit(Types.MANDATE_EDIT_DOCUMENT, response);
    },
    async removeMandateDocument({ commit }, { tenantId, mandateId, documentId }) {

        await HestiaApi.Mandate.document.delete(tenantId, mandateId, documentId);
        commit(Types.MANDATE_DELETE_DOCUMENT, documentId);
    },
    async getAvenants({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Mandate.avenant.search(tenantId, query).json();

        const avenants = {
            results      : response.hits.hits.map((m) => m._source),
            pagination   : {
                perPage : query.size,
                page    : ((query.from / query.size) + 1),
                total   : response.hits.total.value
            },
            aggregations : response.aggregations
        };

        commit(Types.RECEIVE_AVENANTS, avenants);
    },
    async addAvenant({ commit }, { tenantId, mandateId, avenant }) {

        const response = await HestiaApi.Mandate.avenant.create(tenantId, mandateId, avenant).json();
        commit(Types.ADD_AVENANT, response);
    },
    async editAvenant({ commit }, { tenantId, mandateId, id, avenant }) {

        const response = await HestiaApi.Mandate.avenant.update(tenantId, mandateId, id, avenant).json();
        commit(Types.EDIT_AVENANT, response);

        return response;
    },
    async removeAvenant({ commit }, { tenantId, mandateId, avenantId }) {

        await HestiaApi.Mandate.avenant.delete(tenantId, mandateId, avenantId);
        commit(Types.REMOVE_AVENANT, avenantId);
    },
    async selectMandate({ commit, state }, { tenantId, id }) {

        state.loadingMandate = true;

        const mandate = await HestiaApi.Mandate.mandate.get(tenantId, id).json();

        commit(Types.SELECT_MANDATE, mandate);

        try {
            const { results : properties } = await HestiaApi.Property.property.list(tenantId, { searchParams : { 'mandates:contains' : id } })
                .json();
            mandate.properties             = properties;
            commit(Types.INIT_MANDATE_COUNTS, { 'mandate' : mandate });
        }
        catch (e) {
        }

        try {
            const { hits : { total : { value : issues } } } = await HestiaApi.Thread.thread.search(tenantId, {
                'query' : {
                    'bool' : {
                        'must' : [
                            { 'match' : { 'type' : 'ISSUE' } },
                            {
                                'bool' : {
                                    'should' : [
                                        {
                                            'nested' : {
                                                'path'  : 'metadata',
                                                'query' : { 'bool' : { 'should' : [{ 'match' : { 'metadata.identifier.id' : id } }] } }
                                            }
                                        }, {
                                            'nested' : {
                                                'path'  : 'additionalMetadata',
                                                'query' : { 'bool' : { 'should' : [{ 'match' : { 'additionalMetadata.identifier.id' : id } }] } }
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                }
            }).json();
            commit(Types.INIT_MANDATE_COUNTS, { 'mandate' : mandate, 'counts' : { 'issues' : issues } });
        }
        catch (e) {

        }

        state.loadingMandate = false;
    },
    addMandateIssue({ commit }, { issue }) {

        commit(Types.ADD_MANDATE_ISSUE, issue);
    },
    async getMandateAvenants({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Mandate.avenant.search(tenantId, query).json();

        const mandate_avenants = {
            results    : response.hits.hits.map((m) => m._source),
            pagination : {
                perPage : query.size,
                page    : ((query.from / query.size) + 1),
                total   : response.hits.total.value
            }
        };

        commit(Types.RECEIVE_MANDATE_AVENANTS, mandate_avenants);
    },
    async getMandateIssues({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Thread.thread.search(tenantId, query).json();

        const issues = {
            results    : response.hits.hits.map((m) => m._source),
            pagination : {
                perPage : query.size,
                page    : ((query.from / query.size) + 1),
                total   : response.hits.total.value
            }
        };

        commit(Types.RECEIVE_MANDATE_ISSUES, issues);

    },
    async getMandateProperties({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Property.property.list(tenantId, query).json();

        const mandate_properties = {
            results    : response.hits.hits.map((m) => m._source),
            pagination : {
                perPage : query.size,
                page    : ((query.from / query.size) + 1),
                total   : response.hits.total.value
            }
        };

        commit(Types.RECEIVE_MANDATE_PROPERTIES, mandate_properties);
    },
    async addMandateProperty({ commit }, { property }) {

        commit(Types.ADD_MANDATE_PROPERTY, property);
    },
    async removeMandateProperty({ commit }, { propertyId }) {

        commit(Types.REMOVE_MANDATE_PROPERTY, propertyId);
    },
    // ACL
    async editMandateOwner({ commit }, { tenantId, id, owner, editCurrentMandate = true }) {

        const response = await HestiaApi.Mandate.mandate.editOwner(tenantId, id, owner).json();

        if (editCurrentMandate) {

            commit(Types.EDIT_MANDATE, response);
        }
    },
    async addMandateCollaborator({ commit }, { tenantId, id, collaborator, editCurrentMandate = true }) {

        const response = await HestiaApi.Mandate.mandate.addCollaborator(tenantId, id, collaborator).json();

        if (editCurrentMandate) {

            commit(Types.EDIT_MANDATE, response);
        }
    },
    async addMandateCoowner({ commit }, { tenantId, id, coowner, editCurrentMandate = true }) {

        const response = await HestiaApi.Mandate.mandate.addCoOwner(tenantId, id, coowner).json();

        if (editCurrentMandate) {

            commit(Types.EDIT_MANDATE, response);
        }
    },
    async removeMandateCollaborator({ commit }, { tenantId, id, collaborator, editCurrentMandate = true }) {

        await HestiaApi.Mandate.mandate.removeCollaborator(tenantId, id, collaborator);

        if (editCurrentMandate) {

            commit(Types.REMOVE_MANDATE_COLLABORATOR, collaborator);
        }
    },
    async removeMandateCoowner({ commit }, { tenantId, id, coowner, editCurrentMandate = true }) {

        await HestiaApi.Mandate.mandate.removeCoOwner(tenantId, id, coowner);

        if (editCurrentMandate) {

            commit(Types.REMOVE_MANDATE_COOWNER, coowner);
        }
    },

    // EMANDATES

    async voidEMandate({ commit }, { tenantId, mandateId, id, reason }) {

        const emandate = await HestiaApi.Mandate.emandate.void(tenantId, mandateId, id, reason).json();

        commit(Types.VOID_EMANDATE, emandate);
    },

    async resendEMandate({}, { tenantId, mandateId, id }) {

        await HestiaApi.Mandate.emandate.resend(tenantId, mandateId, id);
    },
    async createEMandate({ commit }, { tenantId, mandateId, document }) {

        const emandate = await HestiaApi.Mandate.emandate.create(tenantId, mandateId, document).json();

        commit(Types.CREATE_EMANDATE, emandate);
    },
    async getEMandateTemplates({ commit }, tenantId) {

        const response = await HestiaApi.Mandate.emandate.templateList(tenantId).json();
        commit(Types.RECEIVE_EMANDATE, response);
    },
    async addEMandate({ commit }, { tenantId, emandate }) {

        const response = await HestiaApi.Mandate.emandate.templateCreate(tenantId, emandate).json();
        commit(Types.ADD_EMANDATE, response);
    },
    async editEMandate({ commit }, { tenantId, language, emandate }) {

        const response = await HestiaApi.Mandate.emandate.templateUpdate(tenantId, language, emandate).json();
        commit(Types.EDIT_EAVENANT, response);
    },
    async deleteEMandate({ commit }, { tenantId, language }) {

        await HestiaApi.Mandate.emandate.templateDelete(tenantId, language).json();
        commit(Types.REMOVE_EMANDATE, language);
    },

    // EAVENANTS

    async createEAvenant({ commit }, { tenantId, mandateId, avenantId, document }) {

        const eavenant = await HestiaApi.Mandate.eavenant.create(tenantId, mandateId, avenantId, document).json();

        commit(Types.CREATE_EAVENANT, { eavenant, avenantId });
    },
    async getEAvenantTemplates({ commit }, tenantId) {

        const response = await HestiaApi.Mandate.eavenant.templateList(tenantId).json();
        commit(Types.RECEIVE_EAVENANT, response);
    },
    async addEAvenant({ commit }, { tenantId, eavenant }) {

        const response = await HestiaApi.Mandate.eavenant.templateCreate(tenantId, eavenant).json();
        commit(Types.ADD_EAVENANT, response);
    },
    async editEAvenant({ commit }, { tenantId, language, eavenant }) {

        const response = await HestiaApi.Mandate.eavenant.templateUpdate(tenantId, language, eavenant).json();
        commit(Types.EDIT_EAVENANT, response);
    },
    async deleteEAvenant({ commit }, { tenantId, language }) {

        await HestiaApi.Mandate.eavenant.templateDelete(tenantId, language);
        commit(Types.REMOVE_EAVENANT, language);
    },

    async resendEAvenant({}, { tenantId, mandateId, avenantId, id }) {

        await HestiaApi.Mandate.eavenant.resend(tenantId, mandateId, avenantId, id);
    },

    async voidEAvenant({ commit }, { tenantId, mandateId, avenantId, id, reason }) {

        const eavenant = await HestiaApi.Mandate.eavenant.void(tenantId, mandateId, avenantId, id, reason).json();

        commit(Types.VOID_EAVENANT, eavenant, avenantId);
    },

    // TYPES
    async getMandateDocumentTypes({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Mandate.documentType.list(tenantId, query).json();
        commit(Types.RECEIVE_MANDATE_DOCUMENT_TYPES, response);
    },
    async addMandateDocumentType({ commit }, { tenantId, documentType }) {

        const response = await HestiaApi.Mandate.documentType.create(tenantId, documentType).json();
        commit(Types.ADD_MANDATE_DOCUMENT_TYPE, response);
    },
    async editMandateDocumentType({ commit }, { tenantId, id, documentType }) {

        const response = await HestiaApi.Mandate.documentType.update(tenantId, id, documentType).json();
        commit(Types.EDIT_MANDATE_DOCUMENT_TYPE, response);
    },
    async deleteMandateDocumentType({ commit }, { tenantId, id }) {

        const response = await HestiaApi.Mandate.documentType.delete(tenantId, id).json();
        commit(Types.REMOVE_MANDATE_DOCUMENT_TYPE, response);
    },
    // REGISTRY
    async getRegistryMandates({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Mandate.mandate.registry(tenantId, query).json();

        commit(Types.RECEIVE_REGISTRY_MANDATES, response);
    }
};

// mutations
const mutations = {
    [Types.RECEIVE_MANDATES](state, mandates) {

        state.mandates = mandates;
    },
    [Types.RECEIVE_MANDATE_AVENANTS](state, mandate_avenants) {

        state.currentMandateAvenants = mandate_avenants;

        state.currentMandateCounts.avenants = mandate_avenants.results.length;
    },
    [Types.RECEIVE_MANDATE_ISSUES](state, mandate_issues) {

        state.currentMandateIssues = mandate_issues;
    },
    [Types.ADD_MANDATE_PROPERTY](state, property) {

        state.currentMandate.properties.push(property);

        // Update Counts
        state.currentMandateCounts.properties++;
    },
    [Types.REMOVE_MANDATE_PROPERTY](state, propertyId) {

        const index = state.currentMandate.properties.findIndex((p) => p.id === propertyId);

        state.currentMandate.properties.splice(index, 1);

        // Update Counts
        state.currentMandateCounts.properties--;
    },
    [Types.ADD_MANDATE](state, mandate) {

        state.mandates.results.push(mandate);
    },
    [Types.EDIT_MANDATE](state, mandate) {

        state.currentMandate = {
            ...mandate,
            threads_issues : state.currentMandate.threads_issues,
            properties     : state.currentMandate.properties
        };
        const index          = state.mandates.results.findIndex((m) => m.id === mandate.id);

        state.mandates.results[index] = mandate;
        state.mandates                = ObjectManipulation.clone(state.mandates); // else deep objects are not updated, if it's not a deep object use splice instead ;)
        if ('clients' in mandate) {
            state.currentMandateCounts = {
                ...state.currentMandateCounts,
                'clients'    : ('clients' in mandate && 'companies' in mandate) ? (mandate.clients.length + mandate.companies.length) : ('clients' in mandate) ? mandate.clients.length : ('companies' in mandate) ? mandate.companies.length : 0,
                'emandates'  : 'emandates' in mandate ? mandate.emandates.length : 0,
                'commission' : 'commission' in mandate ? mandate.commission.length : 0
            };
        }
    },
    [Types.REMOVE_MANDATE](state, mandateId) {

        state.mandates.results.splice(mandateId, 1);
    },

    [Types.GET_MANDATE_TEMPLATE](state, template) {

        state.currentMandate.template = template.template;
    },

    [Types.RESET_MANDATE_TEMPLATE](state, type) {

        state.currentMandate.template   = type.template.template;
        state.currentMandate.templateId = null;
    },
    [Types.OVERRIDE_MANDATE_TEMPLATE](state, template) {

        state.currentMandate.template   = template.template;
        state.currentMandate.templateId = template.id;
    },

    [Types.RECEIVE_MANDATE_TYPES](state, types) {

        state.types = types;
    },
    [Types.ADD_MANDATE_TYPE](state, type) {

        state.types.results.push(type);
    },
    [Types.EDIT_MANDATE_TYPE](state, type) {

        const index = state.types.results.findIndex((t) => t.id === type.id);

        state.types.results.splice(index, 1, type);
    },
    [Types.EDIT_MANDATE_TYPE_ORDER](state, { type, newOrder, oldOrder }) {

        if (newOrder > oldOrder) {

            state.types.results.reduce((acc, t) => {

                if (t.order > oldOrder && t.order <= newOrder) {
                    t.order = t.order - 1;
                }

                acc.push(t);

                return acc;
            }, []);

        }
        else {

            state.types.results.reduce((acc, t) => {

                if (t.order < oldOrder && t.order >= newOrder) {
                    t.order = t.order + 1;
                }

                acc.push(t);

                return acc;
            }, []);
        }

        const index = state.types.results.findIndex((t) => t.id === type.id);

        state.types.results.splice(index, 1, type);
    },

    [Types.RECEIVE_MANDATE_SOURCES](state, sources) {

        state.mandate_sources = sources;
    },
    [Types.ADD_MANDATE_SOURCE](state, source) {

        state.mandate_sources.results.push(source);
    },
    [Types.EDIT_MANDATE_SOURCE](state, source) {

        const index = state.mandate_sources.results.findIndex((s) => s.id === source.id);

        state.mandate_sources.results.splice(index, 1, source);
    },
    [Types.EDIT_MANDATE_SOURCE_ORDER](state, { source, newOrder, oldOrder }) {

        if (newOrder > oldOrder) {

            state.mandate_sources.results.reduce((acc, t) => {

                if (t.order > oldOrder && t.order <= newOrder) {
                    t.order = t.order - 1;
                }

                acc.push(t);

                return acc;
            }, []);

        }
        else {

            state.mandate_sources.results.reduce((acc, t) => {

                if (t.order < oldOrder && t.order >= newOrder) {
                    t.order = t.order + 1;
                }

                acc.push(t);

                return acc;
            }, []);
        }

        const index = state.mandate_sources.results.findIndex((s) => s.id === source.id);

        state.mandate_sources.results.splice(index, 1, source);
    },
    [Types.REMOVE_MANDATE_SOURCE](state, id) {

        const index = state.mandate_sources.results.findIndex((s) => s.id === id);

        state.mandate_sources.results.splice(index, 1);
    },
    [Types.UPDATE_MANDATE_SOURCE](state, mandate) {

        state.currentMandate.source = mandate.source;
    },

    [Types.MANDATE_ADD_DOCUMENT](state, document) {

        state.currentMandate.documents.push(document);

        // Update counts
        state.currentMandateCounts['documents']++;
    },
    [Types.MANDATE_DELETE_DOCUMENT](state, documentId) {

        const index = state.currentMandate.documents.findIndex((a) => a.id === documentId);

        state.currentMandate.documents.splice(index, 1);

        // Update counts
        state.currentMandateCounts['documents']--;
    },
    [Types.MANDATE_EDIT_DOCUMENT](state, document) {

        const index = state.currentMandate.documents.findIndex((a) => a.id === document.id);

        state.currentMandate.documents.splice(index, 1, document);
    },
    [Types.RECEIVE_AVENANTS](state, avenants) {

        state.avenants = avenants;
    },
    [Types.ADD_AVENANT](state, avenant) {

        state.avenants.results.push(avenant);

        state.currentMandateAvenants.results.unshift(avenant);

        // Update counts
        state.currentMandateCounts['avenants']++;
    },
    [Types.EDIT_AVENANT](state, avenant) {

        if (avenant.status === 'completed') {
            state.currentMandate.price.current.value = avenant.price.value;
            state.currentMandate.fees.current.value  = avenant.fees.value;
        }

        const index = state.currentMandateAvenants.results.findIndex((a) => a.id === avenant.id);

        state.currentMandateAvenants.results.splice(index, 1, avenant);
    },
    [Types.SELECT_MANDATE](state, mandate) {

        // TO DELETE
        if (mandate.clients === undefined) {
            mandate.clients = [];
        }

        if (mandate.companies === undefined) {
            mandate.companies = [];
        }

        state.currentMandate = mandate;

    },
    [Types.INIT_MANDATE_COUNTS](state, { mandate, counts }) {

        state.currentMandateCounts = {
            'clients'    : ('clients' in mandate || 'companies' in mandate) ? (mandate.clients.length + mandate.companies.length) : 0,
            'documents'  : 'documents' in mandate ? mandate.documents.length : state.currentMandateCounts.documents,
            'avenants'   : 'avenants' in mandate ? mandate.avenants.length : state.currentMandateCounts.avenants,
            'emandates'  : 'emandates' in mandate ? mandate.emandates.length : state.currentMandateCounts.emandates,
            'commission' : 'commission' in mandate ? mandate.commission.length : state.currentMandateCounts.commission,
            'properties' : 'properties' in mandate ? mandate.properties.length : state.currentMandateCounts.properties,
            'issues'     : counts && counts.issues ? counts.issues : state.currentMandateCounts.issues
        };
    },
    [Types.REMOVE_AVENANT](state, avenantId) {

        state.avenants.results.splice(avenantId, 1);

        const index = state.currentMandateAvenants.results.findIndex((a) => a.id === avenantId);

        state.currentMandateAvenants.results.splice(index, 1);

        // Update counts
        state.currentMandateCounts['avenants']--;
    },
    [Types.ADD_MANDATE_ISSUE](state, issue) {

        // Manually Add
        state.currentMandateIssues.results.unshift(issue);
        state.currentMandateIssues.pagination.total++;

        if (state.currentMandateIssues.pagination.total > state.currentMandateIssues.pagination.perPage) {
            state.currentMandateIssues.results.pop();
        }

        // Update counts
        state.currentMandateCounts['issues']++;
    },
    [Types.EDIT_MANDATE_PROPERTY](state, properties) {

        state.currentMandate.properties = properties;
    },

    // ACL

    [Types.REMOVE_MANDATE_COLLABORATOR](state, collaborator) {

        const index = state.currentMandate.collaborators.findIndex((c) => c.id === collaborator);
        state.currentMandate.collaborators.splice(index, 1);
    },
    [Types.REMOVE_MANDATE_COOWNER](state, coowner) {

        const index = state.currentMandate.coOwners.findIndex((c) => c.id === coowner.id);
        state.currentMandate.coOwners.splice(index, 1);
    },

    // EMANDATES

    [Types.VOID_EMANDATE](state, emandate) {

        const index = state.currentMandate.emandates.findIndex((e) => e === emandate.id);
        state.currentMandate.emandates.splice(index, 1, {
            ...emandate
        });
    },
    [Types.ADD_EMANDATE](state, emandate) {

        state.emandatesTemplate.push(emandate);
    },
    [Types.EDIT_EMANDATE](state, emandate) {

        const index = state.emandatesTemplate.findIndex((a) => a.language === emandate.language);
        state.emandatesTemplate.splice(index, 1, emandate);
    },
    [Types.REMOVE_EMANDATE](state, language) {

        const index = state.emandatesTemplate.findIndex((a) => a.language === language);
        state.emandatesTemplate.splice(index, 1);
    },
    [Types.RECEIVE_EMANDATE](state, emandatesTemplate) {

        state.emandatesTemplate = emandatesTemplate;
    },

    // EAVENANTS

    [Types.VOID_EAVENANT](state, { eavenant, avenantId }) {

        const indexAvenant = state.currentMandateAvenants.results.findIndex((a) => a.id === avenantId);

        const index = state.currentMandateAvenants.results[indexAvenant].eavenants.findIndex((e) => e.id === eavenant.id);
        state.currentMandateAvenants.results[indexAvenant].eavenants.splice(index, 1, {
            ...eavenant
        });

    },
    [Types.ADD_EAVENANT](state, eavenant) {

        state.eavenantsTemplate.push(eavenant);
    },
    [Types.EDIT_EAVENANT](state, eavenant) {

        const index = state.eavenantsTemplate.findIndex((a) => a.language === eavenant.language);
        state.eavenantsTemplate.splice(index, 1, eavenant);
    },
    [Types.REMOVE_EAVENANT](state, language) {

        const index = state.eavenantsTemplate.findIndex((a) => a.language === language);
        state.eavenantsTemplate.splice(index, 1);
    },
    [Types.CREATE_EMANDATE](state, emandate) {

        state.currentMandate.emandates.unshift(emandate); // First position
    },
    [Types.CREATE_EAVENANT](state, { eavenant, avenantId }) {

        const index = state.currentMandateAvenants.results.findIndex((a) => a.id === avenantId);

        if (!('eavenants' in state.currentMandateAvenants.results[index])) {

            state.currentMandateAvenants.results[index].eavenants = [];
        }

        state.currentMandateAvenants.results[index].eavenants.unshift(eavenant); // First position
        state.currentMandateAvenants.results[index].status = 'sent';
    },
    [Types.RECEIVE_EAVENANT](state, eavenantsTemplate) {

        state.eavenantsTemplate = eavenantsTemplate;
    },

    // TYPES
    [Types.RECEIVE_MANDATE_DOCUMENT_TYPES](state, documentTypes) {

        state.mandateDocumentTypes = documentTypes;
    },
    [Types.ADD_MANDATE_DOCUMENT_TYPE](state, documentType) {

        state.mandateDocumentTypes.results.unshift(documentType);

        if (state.mandateDocumentTypes.results.length > state.mandateDocumentTypes.pagination.perPage) {
            state.mandateDocumentTypes.results.pop();
        }
    },
    [Types.EDIT_MANDATE_DOCUMENT_TYPE](state, documentType) {

        const index = state.mandateDocumentTypes.results.findIndex((mandateDocumentType) => mandateDocumentType.id === documentType[0].id);
        state.mandateDocumentTypes.results.splice(index, 1, documentType[0]);
    },
    [Types.REMOVE_MANDATE_DOCUMENT_TYPE](state, id) {

        const index = state.mandateDocumentTypes.results.findIndex((mandateDocumentType) => mandateDocumentType.id === id);
        state.mandateDocumentTypes.results.splice(index, 1);
    },
    [Types.RECEIVE_REGISTRY_MANDATES](state, registry_mandates) {

        state.registry_mandates = registry_mandates;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
