import Commission from '@pixul/hestia-commission'
import Get        from 'lodash.get';
import i18n       from '@/lang'; // Internationalization
import HestiaApi  from '@/api';

export function ruleCheckLink(rule, value, callback, source, { form : { value : resource } }, fieldName) {

    try {

        const regLink = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/;
        const regFloorplannerLink = /^https:\/\/floorplanner.com\b([-a-zA-Z0-9@:%_+.~#?&=]*)/;

        if (!regLink.test(source[fieldName]) && source[fieldName]) {

            return callback(new Error(i18n.t('rules.link_validator')));
        }

        if (resource.type === 'floorplanner' && !regFloorplannerLink.test(source[fieldName])) {

            return callback(new Error(i18n.t('rules.link_floorplanner_validator')));
        }

        return callback();

    } catch (e) {

        throw new Error()
    }
}

export function rulePostcodeField(field, postcode) {

    // remove existing rule
    const index = field.rules.map((el) => el.id).indexOf('postcode_validator');
    if (index !== -1) {
        field.rules.splice(index, 1)
    }

    if (postcode !== null && postcode !== '') {

        field.rules.push({
            id        : 'postcode_validator',
            validator : (rule, value, callback) => {

                // PostCode
                const postCodeValid = postcode.match(/^(?:[0-8]\d|9[0-8])\d{3}$/)

                if (postCodeValid !== null) {

                    return callback();
                }

                return callback(new Error(i18n.t('rules.postcode_validator')));


            }, trigger : 'change'
        })
    }

    return field
}

// New rules

export async function ruleMandateClientsValid(rule, value, callback, source, { form : { context : { raw }, user, value : resource}}) {

    try {

        const isInvalidClient = (client) => {

            const errors = []

            if (client.address && (!client.address.line1 || !client.address.postcode || !client.address.town)) {

                errors.push('address.title')
            }


            if (!client.dateOfBirth) {
                errors.push('client.dateOfBirth')
            }


            if (!client.placeOfBirth || !client.placeOfBirth.country) {

                errors.push('client.placeOfBirth.country')
            }

            if (!client.placeOfBirth || !client.placeOfBirth.postcode) {

                errors.push('client.placeOfBirth.postcode')
            }

            if (!client.placeOfBirth || !client.placeOfBirth.town) {

                errors.push('client.placeOfBirth.town')
            }

            if (errors.length > 0) {

                return {
                    [`${client.firstName} ${client.lastName}`] : errors
                }
            }

            return false;
        }

        const isInvalidCompany = (company) => {

            const errors = []

            if (!company.clients || company.clients.length === 0 ) {

                errors.push('company.clients')
            }

            if (!company.address || !company.address.line1 || !company.address.postcode || !company.address.town) {

                errors.push('address.title')
            }

            if (errors.length > 0) {

                return {
                    [`${company.name}`] : errors
                }
            }

            return false
        }

        const generateText = (ic) => {

            const icKey = Object.keys(ic)[0]

            return `<li>${icKey} : ${ic[icKey].map((f) => i18n.t(f)).join(' / ')}</li>`
        }

        const status = resource.status || raw.status

        if (!['prospection', 'draft'].includes(status)) {

            const clientIds = raw.clients ? raw.clients.map(({id}) => id) : [];
            const companyIds = raw.companies ? raw.companies : [];
            const invalidClients = [];

            if (resource.clientsAndCompanies) {

                for (const client of resource.clientsAndCompanies) {

                    if (client.id.startsWith('CLI') && !clientIds.includes(client.id)) {

                        clientIds.push(client.id)
                    }

                    if (client.id.startsWith('COM') && !companyIds.includes(client.id)) {

                        companyIds.push(client.id)
                    }
                }
            }

            for (const id of clientIds) {

                const client = await HestiaApi.Client.client.get(user.tenantId, id).json();

                const invalid = isInvalidClient(client);

                if (invalid) {

                    invalidClients.push(generateText(invalid));
                }
            }

            for (const id of  companyIds) {

                const company = await HestiaApi.Company.company.get(user.tenantId, id).json();

                const invalid = isInvalidCompany(company);

                if (invalid) {

                    invalidClients.push(generateText(invalid));
                }
            }

            // Return message
            if (invalidClients.length > 0) {

                return callback(new Error(i18n.t('rules.mandate_invalid_client', { invalidClientsText : invalidClients })));
            }
        }

        return callback();
    } catch (e) {

        throw new Error()
    }
}

export async function ruleMandateCommission(rule, value, callback, source, { form : { context : { raw, types }, user, value : resource} }, fieldName) {

    try {

        if (!user ) {

            return callback()
        }

        let typeId = ''
        if (raw.type && raw.type.id) {

            typeId = raw.type.id
        }

        if (resource.typeId) {

            typeId = resource.typeId
        }

        let postcode = ''
        if (raw.address && raw.address.postcode) {

            postcode = raw.address.postcode
        }

        if (resource.address && resource.address.postcode) {

            postcode = resource.address.postcode
        }

        let type = null

        if (resource.typeId) {

            type = types.find(t => t.id === typeId)
        } else {

            type = raw.type
        }

        if (!type) {
            return callback()
        }

        // Get price value used for commission match (price or fees) depending on type.feesPayableBy
        const priceValue = type.feesPayableBy === 'buyer' ? resource.price[fieldName] : resource.hai[fieldName]

        const commission = await HestiaApi.Mandate.commission.find(user.tenantId).json();

        const matchResult = Commission.match(commission, {
            user : {
                groups : user.groups
            },
            typeId  : typeId,
            address : {
                postcode : postcode
            }
        }, priceValue );

        const validate = Commission.validate(matchResult, priceValue, resource.fees[fieldName]);

        if (!validate) {

            const maxFees = Commission.calculate(matchResult, priceValue);

            return callback(new Error(i18n.t('rules.fees.max', { id : i18n.t(`mandate.fees.${fieldName}`), max : maxFees})));
        }

        return callback();

    } catch (e) {

        throw new Error()
    }
}

export async function ruleHaiMin(rule, value, callback, source, { form : { value : resource } }, fieldName) {

    try {

        if (resource.price[fieldName] < 0) {

            const min = resource.fees[fieldName]

            return callback(new Error(i18n.t('rules.number.min', { id : i18n.t(`mandate.hai.${fieldName}`), min : min })));
        }

        return callback();

    } catch (e) {

        throw new Error()
    }
}

export async function ruleMandateDuration(rule, value, callback, source, { form : { context : { raw }, user, value : resource}}) {

    try {

        if (!user) {

            return callback()
        }

        const typeId = resource.typeId || raw.type.id

        const type = await HestiaApi.Mandate.type.get(user.tenantId, typeId).json();

        if (type && type.duration && type.duration < value) {

            return callback(new Error(i18n.t('rules.duration_should_be_lower_than', { durationMax : type.duration})));
        }

        return callback();

    } catch (e) {

        throw new Error()
    }
}

export async function ruleMandateCadastre(rule, value, callback, source, { form : { value : resource } }) {

    try {

        const invalidCadastre = resource.cadastre.filter(c => c.invalid)

        if (invalidCadastre.length > 0) {

            return callback(new Error(i18n.tc('rules.mandate_invalid_cadastre', invalidCadastre.length, { count : invalidCadastre.length } )));
        }

        return callback();

    } catch (e) {

        throw new Error()
    }
}


export async function ruleCantBeRemoved(rule, value, callback, source, { form : { raw, value : resource }}, fieldName, fieldLabel) {

    try {

        if (Get(raw, fieldName) !== '' && Get(raw, fieldName) !== null && Get(raw, fieldName) !== undefined) {

            if (Get(resource, fieldName) !== '' && Get(resource, fieldName) !== null) {

                return callback();
            }

            return callback(new Error(i18n.t('rules.cant_be_removed', { id : fieldLabel })));
        }

        return callback();

    } catch (e) {

        throw new Error()
    }
}


export function rulePhoneField(rule, value, callback, source, { form : { value : resource }}, fieldName) {

    try {

        const value = Get(resource, fieldName)

        if  (value === undefined || value === null || ('number' in value && (value.number === null || value.number === undefined || value.number === '' || value.isValid))) {

            return callback();
        }

        return callback(new Error(i18n.t('rules.phone_validator', { id : fieldName })));

    } catch (e) {

        throw new Error()
    }
}

export function ruleCheckPolygram(rule, value, callback, source, fieldName) {

    try {

        const regPolygram = /^[A-Z]+$/;

        if (!regPolygram.test(source[fieldName]) && source[fieldName]) {

            return callback(new Error(i18n.t('rules.user_polygram')));
        }

        return callback();

    } catch (e) {

        throw new Error()
    }
}
