import * as Types         from '../mutationTypes';
import HestiaApi          from '@/api';
import ObjectManipulation from '@/utils/object';
import { cacheAction }    from 'vuex-cache';

// initial state
const state = {
    users       : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    groups      : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    currentUser : {},
    agents      : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    agents_map  : {
        results    : [],
        pagination : {
            perPage : 0,
            page    : 1,
            total   : 0
        }
    },
    comment     : {}
};

// getters
const getters = {
    users       : state => state.users,
    groups      : state => state.groups,
    currentUser : state => state.currentUser,
    agents      : state => state.agents,
    agents_map  : state => state.agents_map,
    comment     : state => state.comment
};

// actions
const actions = {
    'getUser' : cacheAction(({}, { tenantId, id }) => {

        return HestiaApi.User.user.get(tenantId, id).json();
    }),
    async getUsers({ commit }, { tenantId, query }) {

        const users = await HestiaApi.User.user.list(tenantId, { searchParams : query }).json();

        commit(Types.RECEIVE_USERS, users);
    },
    async addUser({ commit }, { tenantId, user }) {

        const response = await HestiaApi.User.user.create(tenantId, user).json();

        commit(Types.ADD_USER, response);

        return response;
    },
    async editUser({ commit }, { tenantId, userId, user }) {

        const response = await HestiaApi.User.user.update(tenantId, userId, user).json();
        commit(Types.EDIT_USER, response);
    },
    async removeUser({ commit }, { tenantId, userId }) {

        await HestiaApi.User.user.delete(tenantId, userId);
        commit(Types.REMOVE_USER, userId);
    },
    async addPhoneUser({ commit }, { tenantId, userId, phone }) {

        const response = await HestiaApi.User.user.createPhone(tenantId, userId, phone).json();
        commit(Types.ADD_USER_PHONE, response);
    },
    async editPhoneUser({ commit }, { tenantId, userId, phoneId, phone }) {

        const response = await HestiaApi.User.user.updatePhone(tenantId, userId, phoneId, phone).json();
        commit(Types.EDIT_USER_PHONE, response);
    },
    async deletePhoneUser({ commit }, { tenantId, userId, phoneId }) {

        await HestiaApi.User.user.deletePhone(tenantId, userId, phoneId);
        commit(Types.DELETE_USER_PHONE, phoneId);
    },
    async selectUser({ commit }, { tenantId, id }) {

        const response = await HestiaApi.User.user.get(tenantId, id).json();
        commit(Types.SELECT_USER, response);
    },
    async getGroups({ commit }, { tenantId, query }) {

        const response = await HestiaApi.User.group.list(tenantId, { searchParams : query }).json();
        commit(Types.RECEIVE_GROUPS, response);
    },
    async addGroup({ commit }, { tenantId, group }) {

        const response = await HestiaApi.User.group.create(tenantId, group).json();
        commit(Types.ADD_GROUP, response);
        return response;
    },
    async editGroup({ commit }, { tenantId, groupId, group }) {

        const response = await HestiaApi.User.group.update(tenantId, groupId, group).json();
        commit(Types.EDIT_GROUP, response);
    },
    async removeGroup({ commit }, { tenantId, groupId }) {

        await HestiaApi.User.group.delete(tenantId, groupId);
        commit(Types.REMOVE_GROUP, groupId);
    },
    async getAgents({ commit }, { tenantId, query }) {

        const users = await HestiaApi.User.user.list(tenantId, { searchParams : query }).json();
        commit(Types.RECEIVE_AGENTS, users);
    },
    async getAgentsMap({ commit }, { tenantId, query }) {

        const users = await HestiaApi.User.user.list(tenantId, { searchParams : query }).json();
        commit(Types.RECEIVE_AGENTS_MAP, users);
    },

    async getCommentUser({ commit }, { tenantId, userId }) {

        try {
            const response = await HestiaApi.User.user.getComment(tenantId, userId).json();
            commit(Types.RECEIVE_USER_COMMENT, response);
        }
        catch (e) {
            if (e.response && e.response.status === 404) {
                commit(Types.DELETE_USER_COMMENT)
            }

            throw e;
        }
    },

    async addCommentUser({ commit }, { tenantId, userId, comment }) {

        const response = await HestiaApi.User.user.createComment(tenantId, userId, comment).json();
        commit(Types.ADD_USER_COMMENT, response);
    },
    async editCommentUser({ commit }, { tenantId, userId, comment }) {

        const response = await HestiaApi.User.user.updateComment(tenantId, userId, comment).json();
        commit(Types.EDIT_USER_COMMENT, response);
    },
    async deleteCommentUser({ commit }, { tenantId, userId }) {

        await HestiaApi.User.user.deleteComment(tenantId, userId);
        commit(Types.DELETE_USER_COMMENT);
    }
};

// mutations
const mutations = {
    [Types.RECEIVE_USERS](state, users) {

        state.users = users;
    },
    [Types.ADD_USER](state, user) {

        state.users.results.unshift(user);
    },
    [Types.EDIT_USER](state, user) {

        user = {
            ...user,
            phones : state.currentUser.phones
        };

        state.currentUser = user;

        const index = state.users.results.findIndex((us) => us.id === user.id);

        state.users.results.splice(index, 1, user);
        state.users = ObjectManipulation.clone(state.users);

        //user.emails = state.users.results[index].emails;
    },
    [Types.REMOVE_USER](state, userId) {

        const index = state.users.results.findIndex((us) => us.id === userId);
        state.users.results.splice(index, 1);
    },
    [Types.ADD_USER_PHONE](state, phone) {

        state.currentUser.phones.push(phone);

        // Add phone to the user list
        const index = state.users.results.findIndex((user) => user.id === state.currentUser.id);
        if (index !== -1 && index !== undefined) {
            state.users.results[index].phones.push(phone);
        }
    },
    [Types.EDIT_USER_PHONE](state, phone) {

        const index = state.currentUser.phones.findIndex((p) => p.id === phone.id);
        state.currentUser.phones.splice(index, 1, phone);

        // Add phone to the user list
        const indexPhone = state.users.results.findIndex((user) => user.id === state.currentUser.id);
        if (indexPhone !== -1 && indexPhone !== undefined) {
            state.users.results[indexPhone].phones = state.currentUser.phones;
        }

    },
    [Types.DELETE_USER_PHONE](state, phoneId) {

        const index = state.currentUser.phones.findIndex((p) => p.id === phoneId);
        state.currentUser.phones.splice(index, 1);

        // Delete phone in user list
        const indexPhone = state.users.results.findIndex((user) => user.id === state.currentUser.userId);
        if (indexPhone !== -1 && indexPhone !== undefined) {
            state.users.results[indexPhone].phones = state.currentUser.phones;
        }
    },
    [Types.SELECT_USER](state, user) {

        state.currentUser = user;
    },
    [Types.RECEIVE_GROUPS](state, groups) {

        state.groups = groups;
    },
    [Types.ADD_GROUP](state, group) {

        state.groups.results.unshift(group);
    },
    [Types.EDIT_GROUP](state, group) {

        const index = state.groups.results.findIndex((gr) => gr.id === group.id);
        state.groups.results.splice(index, 1, group);
    },
    [Types.REMOVE_GROUP](state, groupId) {

        const index = state.groups.results.findIndex((gr) => gr.id === groupId);
        state.groups.results.splice(index, 1);
    },
    [Types.RECEIVE_AGENTS](state, users) {

        state.agents = users;
    },
    [Types.RECEIVE_AGENTS_MAP](state, users) {

        state.agents = users;
        state.agents_map = users;
    },
    [Types.RECEIVE_USER_COMMENT](state, comment) {

        state.comment = comment;
    },
    [Types.ADD_USER_COMMENT](state, comment) {

        state.comment = comment;
    },
    [Types.EDIT_USER_COMMENT](state, comment) {

        state.comment = comment;
    },
    [Types.DELETE_USER_COMMENT](state) {

        state.comment = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
