import * as Types from '../mutationTypes';

import HestiaApi from '@/api';

// initial state
const state = {
    calendars        : [],
    events           : [],
    visibleCalendars : [],
    currentEvent     : null
};

// getters
const getters = {

    calendars        : state => state.calendars,
    events           : state => state.events,
    visibleCalendars : state => state.visibleCalendars,
    currentEvent     : state => state.currentEvent
};

// actions
const actions = {
    async getCalendars({commit}, {tenantId}) {

        const response = await HestiaApi.Calendar.calendar.list(tenantId).json();
        commit(Types.RECEIVE_CALENDARS, response);
    },
    async addCalendar({commit}, {tenantId, calendar}) {

        const response = await HestiaApi.Calendar.calendar.create(tenantId, calendar).json();
        commit(Types.ADD_CALENDAR, response);
        return response
    },
    async editCalendar({commit}, {tenantId, calendarId, calendar}) {

        const response = await HestiaApi.Calendar.calendar.edit(tenantId, calendarId, calendar).json();
        commit(Types.EDIT_CALENDAR, response);
    },
    async removeCalendar({commit}, {tenantId, calendarId}) {

        await HestiaApi.Calendar.calendar.delete(tenantId, calendarId);
        commit(Types.REMOVE_CALENDAR, calendarId);
    },
    showCalendar({commit}, {calendarId}) {

        commit(Types.SHOW_CALENDAR, calendarId);
    },
    hideCalendar({commit}, {calendarId}) {

        commit(Types.HIDE_CALENDAR, calendarId);
    },
    async selectEvent({commit}, {tenantId, eventId}) {

        const event = await HestiaApi.Calendar.event.get(tenantId, eventId).json();

        commit(Types.SELECT_EVENT, event);
    },
    unselectEvent({commit}) {

        commit(Types.UNSELECT_EVENT);
    },
    // ***************
    // EVENTS
    // ***************
    async getEvents({commit}, {tenantId, query}) {

        const response = await HestiaApi.Calendar.event.listVisible(tenantId, { searchParams : query }).json()
        commit(Types.RECEIVE_EVENTS, response);
    },
    async addEvent({commit}, {tenantId, event}) {

        const response = await HestiaApi.Calendar.event.create(tenantId, event).json();
        commit(Types.ADD_EVENT, response);
        return response
    },
    async editEvent({commit}, {tenantId, id, event}) {

        const response = await HestiaApi.Calendar.event.update(tenantId, id, event).json();
        commit(Types.EDIT_EVENT, response);
    },
    async deleteEvent({commit}, {tenantId, eventId}) {

        await HestiaApi.Calendar.event.delete(tenantId, eventId);
        commit(Types.REMOVE_EVENT, eventId);
    }

};

// mutations
const mutations = {
    [Types.RECEIVE_CALENDARS](state, calendars) {

        state.calendars = calendars
    },
    [Types.ADD_CALENDAR](state, calendar) {

        state.calendars.push(calendar);
    },
    [Types.EDIT_CALENDAR](state, calendar) {

        const index = state.calendars.findIndex((cal) => cal.id === calendar.id);

        state.calendars.splice(index, 1, calendar);
    },
    [Types.REMOVE_CALENDAR](state, calendarId) {

        const index = state.calendars.findIndex((cal) => cal.id === calendarId);
        state.calendars.splice(index, 1);

        // Visible Calendar
        const indexVisible = state.visibleCalendars.findIndex((cal) => cal === calendarId);
        if (indexVisible !== -1) {
            state.visibleCalendars.splice(indexVisible, 1)
        }
    },
    [Types.SHOW_CALENDAR](state, calendarId) {

        state.visibleCalendars.push(calendarId)
    },
    [Types.HIDE_CALENDAR](state, calendarId) {

        const index = state.visibleCalendars.findIndex((cal) => cal === calendarId);
        state.visibleCalendars.splice(index, 1)
    },


    // [Types.RECEIVE_OWN_CALENDARS](state, calendars) {

    //   state.ownCalendars = calendars;
    // },

    [Types.RECEIVE_EVENTS](state, events) {

        state.events = events
    },
    [Types.ADD_EVENT](state, event) {

        state.events.push(event);
    },
    [Types.EDIT_EVENT](state, event) {

        state.currentEvent = event

        const index = state.events.findIndex((cal) => cal.id === event.id);

        state.events.splice(index, 1, event);
    },
    [Types.REMOVE_EVENT](state, eventId) {

        const index = state.events.findIndex((cal) => cal.id === eventId);
        state.events.splice(index, 1);

    },
    [Types.SELECT_EVENT](state, event) {

        state.currentEvent = event

    },
    [Types.UNSELECT_EVENT](state) {

        state.currentEvent = null;

    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
