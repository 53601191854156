import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

// initial state
const state = {
    importers : {
        results : []
    },
    currentImporter : {}
};

// getters
const getters = {
    importers       : state => state.importers,
    currentImporter : state => state.currentImporter
};

// actions
const actions = {

    // PROGRAM
    async getImporters({ commit }, { tenantId, query }) {

        const response = await HestiaApi.Partner.importer.list(tenantId, query).json();
        commit(Types.RECEIVE_IMPORTERS, response);
    },
    async addImporter({ commit }, { tenantId, importer }) {

        const response = await HestiaApi.Partner.importer.create(tenantId, importer).json();

        commit(Types.ADD_IMPORTER, response);
        return response;
    },
    async editImporter({ commit }, { tenantId, id, importer }) {

        const response = await HestiaApi.Partner.importer.update(tenantId, id, importer).json();

        commit(Types.EDIT_IMPORTER, response);
    }
};

// mutations
const mutations = {
    [Types.RECEIVE_IMPORTERS](state, importers) {

        state.importers.results = importers;
    },
    [Types.ADD_IMPORTER](state, importer) {

        state.importers.results.push(importer);
    },
    [Types.EDIT_IMPORTER](state, importer) {

        const index = state.importers.results.findIndex((imp) => imp.id === importer.id);
        state.importers.results.splice(index, 1, importer);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
